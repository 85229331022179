import { createSlice } from "@reduxjs/toolkit";

export const webSocketSlice = createSlice({
  name: "webSocket",
  initialState: {
    connections: {
      "/exchange_data": {
        status: "disconnected",
        message: null,
        tradeMessage: [],
      },
      "/order_status": {
        status: "disconnected",
        message: null,
      },
    },
  },
  reducers: {
    connect: (state, action) => {
      // Dispatched to initiate the WebSocket connection
      const { endpoint } = action.payload;
      state.connections[endpoint].status = "connecting";
    },
    connected: (state, action) => {
      const { endpoint } = action.payload;
      state.connections[endpoint].status = "connected";
    },
    disconnect: (state, action) => {
      // Dispatched to close the WebSocket connection
      const { endpoint } = action.payload;
      state.connections[endpoint].status = "disconnecting";
      state.connections[endpoint].message = null;

      if (state.connections[endpoint].tradeMessage) {
        state.connections[endpoint].message = null;
      }
    },
    disconnected: (state, action) => {
      const { endpoint } = action.payload;
      state.connections[endpoint].status = "disconnected";

      if (state.connections[endpoint].tradeMessage) {
        state.connections[endpoint].message = null;
      }
    },
    messageReceived: (state, action) => {
      const { endpoint, message } = action.payload;
      state.connections[endpoint].message = message;
    },
    tradeMessageReceived: (state, action) => {
      const { endpoint, message } = action.payload;
      state.connections[endpoint].tradeMessage = [
        ...message,
        ...state.connections[endpoint].tradeMessage,
      ].slice(0, 20);
    },
    clearMessageReceived: (state, action) => {
      const { endpoint } = action.payload;
      state.connections[endpoint].message = undefined;

      if (state.connections[endpoint].tradeMessage) {
        state.connections[endpoint].tradeMessage = [];
      }
    },
    clearWebsocketState: (state) => {
      state.connections = {
        // Each key represents a different WebSocket connection
        "/exchange_data": {
          status: "disconnected",
          message: null,
          tradeMessage: [],
        },
        "/order_status": {
          status: "disconnected",
          message: null,
        },
      };
    },
  },
});

export const {
  connect,
  connected,
  disconnect,
  disconnected,
  messageReceived,
  tradeMessageReceived,
  clearWebsocketState,
  clearMessageReceived,
} = webSocketSlice.actions;
