import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, Navigate } from "react-router-dom";
import { useAuth } from "hooks/useAuth";
import SignUpLangSelector from "../SignUpLangSelector";
import RidianLogo from "components/RidianLogo";
import { Locations } from "interfaces/enums";
import { Button, Input } from "components/Shared";
import { useForm } from "react-hook-form";
import { IForgotPasswordForm } from "interfaces";

const ForgotPassword = ({
  onEmailChange,
  email,
  error,
  onSubmit,
}: {
  onEmailChange: any;
  email?: string;
  error: any;
  onSubmit: any;
}) => {
  const { t } = useTranslation();

  const { user } = useAuth();

  const { handleSubmit } = useForm<IForgotPasswordForm>();

  const [isSendingEmail, setIsSendingEmail] = useState(false);

  const handleSubmitResetPassword = async (e: any) => {
    e.preventDefault();
    try {
      setIsSendingEmail(true);
      await onSubmit(email);
    } catch (err: any) {
      console.error("err.message: ", err.message);
    } finally {
      setIsSendingEmail(false);
    }
  };

  return (
    <>
      {user ? (
        <Navigate to={Locations["/"]} replace={true} />
      ) : (
        <div className="p-4 sm:px-8 sm:py-0 flex gap-6 flex-col">
          <div className="justify-end absolute end-10 top-6 hidden sm:flex">
            <SignUpLangSelector theme="light" />
          </div>
          <div className="bg-white h-full w-full">
            <div className="fixed p-8">
              <RidianLogo />
            </div>
            <div className="flex flex-col pt-[8rem] justify-center items-center">
              <div className="flex flex-col gap-6">
                <p className="text-8 font-ridianCubic font-interSemiBold text-left">
                  {t("Reset Password")}
                </p>
                <p className="text-gray-dark300 font-interSemiBold">
                  {t(
                    "Enter your email to receive a link with the steps to reset your password",
                  )}
                </p>
                <form onSubmit={handleSubmit(handleSubmitResetPassword)}>
                  <div className="pt-6">
                    <Input
                      type="email"
                      placeholder={t("Email")}
                      bgColor="white"
                      className="border-b border-gray-dark200 text-5 hover:border-black focus:border-black hover:shadow-[inset_0_-1.3px_0_0_black] focus:shadow-[inset_0_-1.3px_0_0_black] placeholder-gray-dark100"
                      onChange={(e: any) => onEmailChange(e.target.value)}
                    />
                  </div>
                  <div className="pt-6">
                    <Button
                      className="flex justify-center items-center text-4 w-full py-3 gap-2 font-interSemiBold text-white bg-black hover:bg-gray-dark300 transition duration-200 ease-in-out rounded-md"
                      onClick={onSubmit}
                      disabled={error || !email || isSendingEmail}
                    >
                      {t("Confirm")}
                    </Button>
                  </div>
                </form>
                <div className="flex justify-center items-center space-x-1 text-4">
                  <Link to={Locations["/login"]}>
                    <span className="font-interSemiBold">{t("Go back")}</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ForgotPassword;
