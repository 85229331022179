import { closeModal } from "app/slices/modalSlice";

import Modal from "../modal";
import { useTranslation } from "react-i18next";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { Button, Input, Select, errorSnack } from "components/Shared";
import { CreateDestinationBankAccountSnack } from "components/Shared/Snacks";
import { enqueueSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import { DestinationBankAccountClassName, FlagEnum } from "interfaces/enums";
import {
  usePostDestinationAccountUsBankAccountMutation,
  usePostDestinationAccountMutation,
  usePostDestinationAccountWalletMutation,
} from "api/institutional/bitso_destination_accounts";
import { Tooltip } from "react-tooltip";
import { IDestinationBankAccountAll } from "interfaces";
import { MexicanBankAccountForm } from "./components/MexicanBankAccountForm";
import { UsBankAccountForm } from "./components/UsBankAccountForm";
import { WalletAccountForm } from "./components/WalletAccountForm";
import { useGetUserQuery } from "api/institutional/user";

export const AddBankAccountModal = ({
  data,
}: {
  data: { account_type: DestinationBankAccountClassName };
}) => {
  const { t } = useTranslation();

  // APIs
  const [addDestBankAccountFn, { isLoading: isAddingBankAccount }] =
    usePostDestinationAccountMutation();

  const [addDestBankAccountFnInt, { isLoading: isAddingBankAccountInt }] =
    usePostDestinationAccountUsBankAccountMutation();

  const [addDestAccountFnWallet, { isLoading: isAddingAccountWallet }] =
    usePostDestinationAccountWalletMutation();

  const { data: user } = useGetUserQuery({});

  const isCryptoEnabled = user?.flags.includes(
    FlagEnum.ccy_conversion_crypto_enabled,
  );

  const handleClose = () => {
    dispatch(closeModal());
  };

  const methods = useForm<IDestinationBankAccountAll>({
    mode: "all",
    resetOptions: {
      keepIsValid: false,
    },
    defaultValues: {
      class_name: data?.account_type,
    },
  });

  /* WATCHERS */
  const class_name = methods.watch(
    "class_name",
  ) as DestinationBankAccountClassName;

  const dispatch = useDispatch();

  const submitAddBankAccount = async (values: IDestinationBankAccountAll) => {
    try {
      if (values.class_name === DestinationBankAccountClassName.SPID) {
        // Call function to make post request to add destination bank account
        await addDestBankAccountFn({
          email: values.email || null,
          id: values.id,
          recipient_name: values.recipient_name,
          bank_name: values.bank_name,
          clabe_dest: values.clabe_dest,
          rfc_dest: values.rfc_dest,
          address_dest: values.address_dest,
          class_name: values.class_name,
        }).unwrap();
      }

      if (
        values.class_name === DestinationBankAccountClassName.UsDomesticWire
      ) {
        await addDestBankAccountFnInt({
          email: values.email || null,
          id: values.id,
          class_name: values.class_name,
          address_dest: values.address_dest,
          bank_address: values.bank_address,
          bank_name: values.bank_name,
          recipient_name: values.recipient_name,
          routing_dest: values.routing_dest,
          acct_number_dest: values.acct_number_dest,
        }).unwrap();
      }

      if (values.class_name === DestinationBankAccountClassName.CryptoWallet) {
        await addDestAccountFnWallet({
          id: values.id,
          alias: values.alias,
          class_name: values.class_name,
          network: values.network,
          wallet_address: values.wallet_address,
          email: values.email || null,
        }).unwrap();
      }

      // Reset Fields
      methods.reset();

      enqueueSnackbar(<CreateDestinationBankAccountSnack />);

      handleClose();
    } catch (error: any) {
      enqueueSnackbar(
        error || t("An error occurred. Please notifiy Ridian."),
        errorSnack,
      );
    }
  };

  let trasactionTypeOptions = [
    { value: DestinationBankAccountClassName.SPID, label: "SPID" },
    {
      value: DestinationBankAccountClassName.UsDomesticWire,
      label: "US Domestic Wire",
    },
  ];

  // Add wallet as a transfer option only if user can interact with crypto
  if (isCryptoEnabled) {
    trasactionTypeOptions.push({
      value: DestinationBankAccountClassName.CryptoWallet,
      label: "Crypto Wallet Transfer",
    });
  }

  return (
    <FormProvider {...methods}>
      <Modal>
        <form onSubmit={methods.handleSubmit(submitAddBankAccount)}>
          <Modal.Header handleClose={handleClose}>
            <span className="md:text-7 text-6 font-interSemiBold text-black">
              {t("Add New Destination Account")}
            </span>
          </Modal.Header>
          <Modal.Body>
            {/* Transfer Type */}
            <div>
              <p className="mb-3 text-4 font-interSemiBold">
                {t("Transfer Type")}:
              </p>
              <Controller
                name="class_name"
                control={methods.control}
                render={({ field }) => (
                  <Select
                    defaultValue={trasactionTypeOptions.find(
                      (v) => v.value === data.account_type,
                    )}
                    options={trasactionTypeOptions} //[{label: , value: }]
                    onChange={(e: any) => field.onChange(e.value)}
                    placeholder={t("Select an option")}
                  />
                )}
              />
            </div>

            {/* Display Nested Form with Corresponding Attributed Based on Class Name */}
            {
              {
                [DestinationBankAccountClassName.SPID]: (
                  <MexicanBankAccountForm />
                ),
                [DestinationBankAccountClassName.UsDomesticWire]: (
                  <UsBankAccountForm />
                ),
                //TODO
                [DestinationBankAccountClassName.CryptoWallet]: (
                  <WalletAccountForm />
                ),
              }[class_name]
            }

            {/* Email */}
            <div>
              <p className="mb-3 text-4 font-interSemiBold">
                {t("Email")} ({t("Optional")}):
              </p>
              <Input
                type="text"
                placeholder={t("Enter the recipient's email")}
                bgColor="gray-light102"
                className="shadow-sm"
                errors={methods.formState.errors}
                {...methods.register("email", { required: false })}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div>
              {/* <div
              data-tooltip-id="validation-message"
              data-tooltip-content={errors?.}
              data-tooltip-place="top"
            > */}
              <Button
                className="bg-blue hover:bg-blue-dark100 rounded-full text-white text-4 py-2 px-6 items-center"
                type="submit"
                disabled={
                  isAddingBankAccount ||
                  !methods.formState.isValid ||
                  isAddingBankAccountInt ||
                  isAddingAccountWallet
                }
              >
                {isAddingBankAccount ||
                isAddingBankAccountInt ||
                isAddingAccountWallet
                  ? t("Adding Destination Account")
                  : t("Add Destination Account")}
              </Button>
              <Tooltip style={{ maxWidth: "20rem" }} id="validation-message" />
              {/* </div> */}
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </FormProvider>
  );
};

export default AddBankAccountModal;
