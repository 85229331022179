import { ContentCopy } from "@mui/icons-material";

import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

export const truncate = (str, maxLength) => {
  return str.length > maxLength
    ? str.substring(0, Math.ceil((maxLength - 3) / 2)) +
        "..." +
        str.substring(str.length - Math.floor((maxLength - 3) / 2))
    : str;
};

export const Truncator = ({
  children = "",
  maxLength = 0,
  hideCopy = false,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const handleCopy = () => {
    navigator.clipboard.writeText(children);
    enqueueSnackbar(t("Copied to clipboard"));
  };

  return (
    <span className="flex flex-row gap-1 items-center">
      {truncate(children, maxLength)}
      {hideCopy ? null : (
        <ContentCopy
          stroke="1"
          className="p-1 cursor-pointer"
          onClick={handleCopy}
        />
      )}
    </span>
  );
};
export default Truncator;
