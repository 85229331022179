import { useFormContext } from "react-hook-form";
import { Input, Select } from "components/Shared";
import { useTranslation } from "react-i18next";
import { valueLabelMapper } from "utils";
import { getBankOptions } from "utils/getBankOptions";
import { IDestinationBankAccountMexican } from "interfaces";
import { RioSupportedCountry } from "interfaces/enums";

export const MexicanBankAccountForm = () => {
  const { t } = useTranslation();

  const {
    register,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext<IDestinationBankAccountMexican>();

  const bankNameOptions = getBankOptions(RioSupportedCountry.MX);

  return (
    <>
      {/* Recipient Name */}
      <div>
        <p className="mb-3 text-4 font-interSemiBold">
          {t("Recipient's Name")}:
        </p>
        <Input
          type="text"
          placeholder={t("Enter the full name of the recipient")}
          className="shadow-sm "
          bgColor="gray-light102"
          errors={errors}
          {...register("recipient_name", {
            required: {
              message: t("Recipient name must not be empty"),
              value: true,
            },
            onBlur: () =>
              setValue("recipient_name", getValues("recipient_name")?.trim()),
          })}
        />
      </div>
      {/* Bank Name */}
      <div>
        <p className="mb-3 text-4 font-interSemiBold">{t("Bank Name")}:</p>
        <Select
          options={valueLabelMapper(bankNameOptions)} //[{label: , value: }]
          onChange={(e: any) => {
            setValue("bank_name", e.value);
          }}
          placeholder={t("Select an option")}
        />
      </div>
      <div>
        {/* Number */}
        <div>
          <p className="mb-3 text-4 font-interSemiBold">CLABE</p>
          <Input
            type="text"
            placeholder={t("Enter the recipient's CLABE")}
            bgColor="gray-light102"
            className="shadow-sm "
            errors={errors}
            {...register("clabe_dest", {
              required: {
                message: t("CLABE must not be empty"),
                value: true,
              },
              pattern: {
                message: t("CLABE must be 18 digits long."),
                value: /^[0-9]{18}$/,
              },
              onBlur: () =>
                setValue("clabe_dest", getValues("clabe_dest")?.trim()),
            })}
          />
        </div>
      </div>
      {/* RFC */}
      <div>
        <p className="mb-3 text-4 font-interSemiBold">
          {t("Recipient's RFC")}:
        </p>
        <Input
          type="text"
          placeholder={t("Enter the recipient's RFC")}
          bgColor="gray-light102"
          className="shadow-sm "
          errors={errors}
          {...register("rfc_dest", {
            required: { value: true, message: t("RFC must not be empty") },
            pattern: {
              message: t("RFC is incorrect."),
              value:
                /^\b[A-Z&Ñ]{3}[0-9]{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12][0-9]|3[01])[A-Z0-9]{3}\b$/,
            },
          })}
        />
      </div>

      {/* Address */}
      <div>
        <p className="mb-3 text-4 font-interSemiBold">{t("Address")}:</p>
        <Input
          type="text"
          placeholder={t("Enter the recipient's address")}
          bgColor="gray-light102"
          className="shadow-sm "
          errors={errors}
          {...register("address_dest", {
            required: {
              value: true,
              message: t("Recipient's address must not be empty"),
            },
          })}
        />
      </div>
    </>
  );
};
