import { Navigate, Outlet, useLocation, matchPath } from "react-router-dom";
import { FlagEnum, Locations, Roles } from "interfaces/enums";
import { useGetUserQuery } from "api/institutional/user";
import { CenterCircleLoader } from "components/Shared";
import { MenuOptions } from "../NavSidebarIconMenu/MenuOptions";

export const RoutesPermissions = () => {
  const { data: user, isLoading } = useGetUserQuery({});

  const pathname = useLocation().pathname as Locations;

  if (isLoading) {
    return <CenterCircleLoader />;
  }

  const { role, requiresPorfolio, showTo } =
    MenuOptions.find((o) => matchPath(o.route, pathname)) || {};

  if (!user) return <Outlet />;

  if (requiresPorfolio && !user.portfolio?.broker_id)
    return <Navigate to={Locations["/exchanges"]} />;

  if (role && role === Roles.admin && !user.is_admin) {
    return <Navigate to={Locations["/"]} />;
  }

  if (user.flags.some((flag) => showTo?.includes(flag))) {
    return <Outlet />;
  }

  if (user.flags.includes(FlagEnum.institutional))
    return <Navigate to={Locations["/dashboard"]} />;

  if (
    user.flags.includes(FlagEnum.bitso_odl_mm) ||
    user.flags.includes(FlagEnum.bitso_odl_treasury)
  )
    return <Navigate to={Locations["/ccy-conversion/mm"]} />;

  if (user.isBitsoUser)
    return <Navigate to={Locations["/ccy-conversion/place-order"]} />;

  if (user.isRioUser)
    return <Navigate to={Locations["/ccy-conversion/place-order-rio"]} />;

  if (user.isRioBroker || user.isRioGuestBroker)
    return (
      <Navigate to={Locations["/ccy-conversion/place-order-rio-broker"]} />
    );

  if (user.isCalizaUser)
    return <Navigate to={Locations["/ccy-conversion/place-order-caliza"]} />;

  if (!role) return <Outlet />;

  return <Navigate to={Locations["/access-denied"]} />;
};
export default RoutesPermissions;
