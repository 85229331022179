import React from "react";

const SolidBackground = (props) => (
  <div className={`cubes-overlay-container ${props.className}`}>
    <div className="noise"></div>
    {props.element}
  </div>
);

export default SolidBackground;
