// Endpoints for getTradablePairs
import BigNumber from "bignumber.js";
import {
  IGetTradablePairs,
  ITransformedResponse,
  Trade,
  TradeMap,
} from "interfaces";
import { institutionalApi } from "./index";

interface ConnectToExchangeDTO {
  brokerId: string;
  encryptedKey: string;
  encryptedSecret: string;
  subAccount: null;
}
interface IRequestFee {
  user_id: string;
  base_ccy: string;
  quote_ccy: string;
}
interface IRequestTick {
  base_ccy: string;
  quote_ccy: string;
}

interface IResponseTick {
  qty_delta: string;
  price_delta: string;
}

interface ITransformedResponseTick {
  qty_step_size: string;
  price_precision: string;
  qty_step_size_dp: number;
  price_precision_dp: number;
  qty_step_size_ph: string;
  price_precision_ph: string;
}
interface IResponseFee {
  discount_asset: string;
  discount_rate: string;
  fee_maker: string;
  fee_taker: string;
}

export const exchangeApi = institutionalApi.injectEndpoints({
  endpoints: (builder) => ({
    getTradeablePairs: builder.query<ITransformedResponse, IGetTradablePairs>({
      query: (params) => ({
        url: `/exchange_data/tradable_pairs?exchange_name=${params?.exchangeName}`,
        method: "GET",
      }),
      transformResponse: (tradablePairs: Trade[]) => {
        const transformed: TradeMap = tradablePairs.reduce(
          (acc: TradeMap, [currency, trade]) => {
            acc[currency] = [
              ...new Set([...(acc[currency] || []), trade]),
            ].sort();
            return acc;
          },
          {},
        );

        return { transformed, tradablePairs };
      },
      providesTags: ["TradeablePairsQuery"],
    }),
    getTick: builder.query<ITransformedResponseTick, IRequestTick>({
      query: (params) => ({
        url: `/exchange_data/order_rules?${new URLSearchParams({
          base_ccy: params.base_ccy,
          quote_ccy: params.quote_ccy,
        })}`,
        method: "GET",
      }),
      transformResponse: (data: IResponseTick) => {
        return {
          price_precision: data.price_delta,
          qty_step_size: data.qty_delta,
          price_precision_ph: BigNumber(data.price_delta).gte(1)
            ? "0"
            : BigNumber(data.price_delta).toFormat().replace(/\d/g, "0"),
          qty_step_size_ph: BigNumber(data.qty_delta).gte(1)
            ? "0"
            : BigNumber(data.qty_delta).toFormat().replace(/\d/g, "0"),
          qty_step_size_dp: BigNumber(data.qty_delta).dp() || 0,
          price_precision_dp: BigNumber(data.price_delta).dp() || 0,
        };
      },
      providesTags: ["ExchangeInfo"],
    }),
    getFee: builder.query<IResponseFee, IRequestFee>({
      query: (params) => ({
        url: `/exchange_data/fee?${new URLSearchParams({
          user_id: params.user_id,
          base_ccy: params.base_ccy,
          quote_ccy: params.quote_ccy,
        })}`,
        method: "GET",
      }),
      providesTags: ["FeeQuery"],
    }),
    postConnectToExchange: builder.mutation<string, ConnectToExchangeDTO>({
      query: (params) => ({
        url: "/portfolio/connect",
        method: "POST",
        body: {
          broker_id: params.brokerId,
          encrypted_key: params.encryptedKey,
          encrypted_secret: params.encryptedSecret,
          subaccount_name: params.subAccount,
        },
      }),
      invalidatesTags: ["User", "OrderHistoryQuery", "VolumeFilledQuery"],
    }),
    deleteExchange: builder.mutation<string, void>({
      query: () => ({
        url: "/portfolio/connect",
        method: "DELETE",
      }),
      invalidatesTags: ["User", "OrderHistoryQuery", "VolumeFilledQuery"],
    }),
  }),
});

export const {
  useGetTradeablePairsQuery,
  useGetFeeQuery,
  usePostConnectToExchangeMutation,
  useDeleteExchangeMutation,
  useGetTickQuery,
} = exchangeApi;
