import { institutionalApi } from "./index";
import { transformErrorResponse } from "../../utils";

import {
  ICalizaDestinationWallet,
  ICalizaGetOrders,
  ICalizaOrder,
  ICalizaQuoteRequest,
  ICalizaQuoteResponse,
} from "interfaces/caliza";

interface IOrderId {
  order_id: string;
}

export const ccyConversionOrdersCaliza = institutionalApi.injectEndpoints({
  endpoints: (builder) => ({
    createCalizaOrder: builder.mutation<ICalizaOrder, { quote_id: string }>({
      query: (params) => ({
        url: `/caliza/order`,
        method: "POST",
        body: params,
      }),
      invalidatesTags: ["CalizaOrders"],
      transformErrorResponse,
    }),
    getCalizaQuote: builder.mutation<ICalizaQuoteResponse, ICalizaQuoteRequest>(
      {
        query: (params) => ({
          url: `/caliza/quote?${new URLSearchParams(params as any).toString()}`,
          method: "GET",
        }),
        transformErrorResponse,
      },
    ),
    getCalizaOrder: builder.query<ICalizaOrder, IOrderId>({
      query: (params) => ({
        url: `/caliza/order/${params.order_id}`,
        method: "GET",
      }),
      providesTags: (_, __, args) => [
        { type: "CalizaOrder", id: args.order_id },
      ],
      transformErrorResponse,
    }),
    getCalizaOrders: builder.query<Array<ICalizaOrder>, ICalizaGetOrders>({
      query: (params) => ({
        url: `/caliza?${new URLSearchParams(params as any).toString()}`,
        method: "GET",
      }),
      providesTags: ["CalizaOrders"],
      transformErrorResponse,
    }),
    getDestinationWallet: builder.query<ICalizaDestinationWallet, void>({
      query: (params) => ({
        url: `/caliza/destination_wallet`,
        method: "GET",
      }),
      providesTags: ["CalizaDestinationWallet"],
      transformErrorResponse,
    }),
    updateDestinationWallet: builder.mutation<string, ICalizaDestinationWallet>(
      {
        query: (params) => ({
          url: `/caliza/destination_wallet`,
          method: "POST",
          body: params,
        }),
        invalidatesTags: ["CalizaDestinationWallet"],
        transformErrorResponse,
      },
    ),
    simulateTransfer: builder.mutation<string, IOrderId>({
      query: (params) => ({
        url: `/caliza/simulate_transfer`,
        method: "POST",
        body: params,
      }),
      invalidatesTags: ["CalizaOrder", "CalizaOrders"],
      transformErrorResponse,
    }),
  }),
});

export const {
  useGetCalizaQuoteMutation,
  usePrefetch,
  useGetCalizaOrderQuery,
  useGetCalizaOrdersQuery,
  useGetDestinationWalletQuery,
  useSimulateTransferMutation,
  useCreateCalizaOrderMutation,
  useUpdateDestinationWalletMutation,
} = ccyConversionOrdersCaliza;
