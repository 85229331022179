import { useMemo } from "react";

import { getPath } from "../api/customFetchBase";

import useWebSocket from "react-use-websocket";
import { useDispatch, useSelector } from "react-redux";
import {
  ICcyConversionWSInitialSnap,
  ICcyConversionWSOrderUpdate,
} from "../interfaces";
import { WebSocketEvents } from "../interfaces/enums";
import { ccyConversionOrdersRio } from "../api/institutional/rio";
import { useGetUserQuery } from "api/institutional/user";

type MessageType = ICcyConversionWSInitialSnap | ICcyConversionWSOrderUpdate;

const isOrderUpdate = (
  message: MessageType,
): message is ICcyConversionWSOrderUpdate => {
  return message.event === WebSocketEvents.order_update;
};

export const useRioOrderWS = () => {
  const endpoint = "/rio/order_status";

  const { data: user } = useGetUserQuery({});

  const url = useMemo(
    () =>
      user?.isRioBroker || user?.isRioUser
        ? `${getPath("websocket")}/ws${endpoint}`
        : null,
    [user],
  );

  const auth = useSelector((state: any) => state.auth);

  const dispatch = useDispatch();

  const {
    sendMessage,
    readyState,
    lastJsonMessage,
    lastMessage,
    getWebSocket,
    sendJsonMessage,
  } = useWebSocket(url, {
    onClose: () => {},
    retryOnError: true,
    queryParams: {
      authorization: encodeURIComponent(auth?.token),
    },
    shouldReconnect: () => true,
    onError: (err) => console.error(err),
    onMessage: (ev) => {
      const message = JSON.parse(ev.data) as MessageType;
      console.log(message);
      if (isOrderUpdate(message)) {
        const order = message.data;

        const order_id = order.id;

        dispatch(
          ccyConversionOrdersRio.util.invalidateTags([
            { type: "RioOrder", id: order_id },
          ]),
        );

        dispatch(ccyConversionOrdersRio.util.invalidateTags(["RioOrders"]));
      }
    },
  });

  return {
    sendMessage,
    readyState,
    lastJsonMessage,
    lastMessage,
    getWebSocket,
    sendJsonMessage,
  };
};

export default useRioOrderWS;
