import { institutionalApi } from "./index";

export const verifyUserApi = institutionalApi.injectEndpoints({
  endpoints: (builder) => ({
    verifyUser: builder.query({
      query: (params) => ({
        url: `/email_verification?code=${params.code}`,
        headers: {
          authorization: params.token,
        },
      }),
      providesTags: ["User"],
    }),
    resendEmail: builder.mutation({
      query: (params) => ({
        url: "/email_verification",
        method: "POST",
        headers: {
          authorization: params.token,
        },
      }),
    }),
  }),
});

export const { useLazyVerifyUserQuery, useResendEmailMutation } = verifyUserApi;
