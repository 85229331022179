import { useFormContext } from "react-hook-form";

import { useTranslation } from "react-i18next";
import { Input } from "components/Shared";
import { IDestinationBankAccountUS } from "interfaces";

export const UsBankAccountForm = () => {
  const {
    formState: { errors },
    register,
    setValue,
    getValues,
  } = useFormContext<IDestinationBankAccountUS>();
  const { t } = useTranslation();
  return (
    <>
      {/* Recipient Name */}
      <div>
        <p className="mb-3 text-4 font-interSemiBold">
          {t("Recipient's Name")}:
        </p>
        <Input
          type="text"
          placeholder={t("Enter the full name of the recipient")}
          className="shadow-sm "
          bgColor="gray-light102"
          errors={errors}
          {...register("recipient_name", {
            required: {
              message: t("Recipient name must not be empty"),
              value: true,
            },
            onBlur: () =>
              setValue("recipient_name", getValues("recipient_name")?.trim()),
          })}
        />
      </div>
      {/* Bank Name */}
      <div>
        <p className="mb-3 text-4 font-interSemiBold">{t("Bank Name")}:</p>
        <Input
          type="text"
          placeholder={t("Enter bank name")}
          className="shadow-sm "
          bgColor="gray-light102"
          errors={errors}
          {...register("bank_name", {
            required: {
              message: t("Bank name must not be empty"),
              value: true,
            },
            onBlur: () => setValue("bank_name", getValues("bank_name")?.trim()),
          })}
        />
      </div>

      {/* Account dest */}
      <div>
        <p className="mb-3 text-4 font-interSemiBold">{t("Account Number")}:</p>
        <Input
          type="text"
          placeholder={t("Enter the account number")}
          className="shadow-sm "
          bgColor="gray-light102"
          errors={errors}
          {...register("acct_number_dest", {
            required: {
              message: t("Account number must not be empty"),
              value: true,
            },
            pattern: {
              message: t("Routing number must be 11 digits long."),
              value: /^[0-9]{11}$/,
            },
            onBlur: () =>
              setValue(
                "acct_number_dest",
                getValues("acct_number_dest")?.trim(),
              ),
          })}
        />
      </div>

      {/* Routing destination */}
      <div>
        <p className="mb-3 text-4 font-interSemiBold">{t("Routing Number")}:</p>
        <Input
          type="text"
          placeholder={t("Enter the routing number")}
          className="shadow-sm "
          bgColor="gray-light102"
          errors={errors}
          {...register("routing_dest", {
            required: {
              message: t("Routing destination must not be empty"),
              value: true,
            },
            pattern: {
              message: t("Routing number must be 9 digits long."),
              value: /^[0-9]{9}$/,
            },
            onBlur: () =>
              setValue("routing_dest", getValues("routing_dest")?.trim()),
          })}
        />
      </div>

      {/* Bank's Address */}
      <div>
        <p className="mb-3 text-4 font-interSemiBold">{t("Bank's Address")}:</p>
        <Input
          type="text"
          placeholder={t("Enter the bank's address")}
          className="shadow-sm "
          bgColor="gray-light102"
          errors={errors}
          {...register("bank_address", {
            required: {
              message: t("Bank address must not be empty"),
              value: true,
            },
            onBlur: () =>
              setValue("bank_address", getValues("bank_address")?.trim()),
          })}
        />
      </div>

      {/* Address */}
      <div>
        <p className="mb-3 text-4 font-interSemiBold">
          {t("Recipient's Address")}:
        </p>
        <Input
          type="text"
          placeholder={t("Enter the recipient's address")}
          bgColor="gray-light102"
          className="shadow-sm "
          errors={errors}
          {...register("address_dest", {
            required: {
              value: true,
              message: t("Recipient's address must not be empty"),
            },
            onBlur: () =>
              setValue("address_dest", getValues("address_dest")?.trim()),
          })}
        />
      </div>
    </>
  );
};
