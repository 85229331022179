// Endpoints for getTradablePairs
import { IPortfolioPositionsResponse } from "interfaces";
import { institutionalApi } from "./index";

interface IPortfolioRequest {
  portfolioId: string;
}

export const dashboardApi = institutionalApi.injectEndpoints({
  endpoints: (builder) => ({
    getVolumeFilled: builder.query<any, void>({
      query: () => ({
        url: "/dashboard/volume",
        method: "GET",
      }),
      providesTags: ["VolumeFilledQuery"],
    }),
    // prettier-ignore
    getPortfolioPositions: builder.query<IPortfolioPositionsResponse,IPortfolioRequest>({
      query: (params) => ({
        url: `/dashboard/portfolio_positions?portfolio_id=${params.portfolioId}`,
        method: "GET",
      }),
      providesTags: ["PortfolioPositionsQuery"],
    }),
  }),
});

export const { useGetVolumeFilledQuery, useGetPortfolioPositionsQuery } =
  dashboardApi;
