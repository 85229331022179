import { IRioQuoteForm } from "interfaces/rio";
import { closeModal } from "app/slices/modalSlice";
import Modal from "../modal";
import { useTranslation } from "react-i18next";
import {
  Button,
  CurrencyBody,
  Grid,
  LoadingComponent,
} from "components/Shared";
import { Tooltip } from "react-tooltip";
import { useDispatch } from "react-redux";
import { Locations } from "interfaces/enums";
import { useNavigate } from "react-router-dom";
import {
  useCreateRioOrderMutation,
  useGetRioQuouteMutation,
} from "api/institutional/rio";
import { Divider } from "@mui/material";
import { useEffect } from "react";
import Countdown from "react-countdown";

import useQuoteErrorListener from "hooks/useErrorListener";
import BigNumber from "bignumber.js";
import { calculateSpread } from "utils/calculateSpread";

export const ConfirmQuoteBrokerRioModal = ({
  data,
}: {
  data: IRioQuoteForm;
}) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [
    getQuote,
    { data: quote, isLoading: isLoadingGetQuote, error: errorGetQuote },
  ] = useGetRioQuouteMutation();

  // APIs
  const [
    createOrder,
    { isLoading: isLoadingCreateRioOrder, error: errorCreateOrder },
  ] = useCreateRioOrderMutation();

  const handleClose = () => {
    dispatch(closeModal());
  };

  const handleRefreshQuoute = async () => {
    const { sub_account, dest_account, ...payload } = data;
    getQuote({
      ...payload,
      sub_account_id: sub_account?.value,
      quote_target: BigNumber(1)
        .dividedBy(BigNumber(data.quote_target!))
        .toNumber(),
    });
  };

  useQuoteErrorListener({ error: errorGetQuote || errorCreateOrder });

  useEffect(() => {
    handleRefreshQuoute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAcceptOrder = async () => {
    try {
      const res = await createOrder({
        buy_quote_id: quote?.buy_quote_id!,
        sell_quote_id: quote?.sell_quote_id!,
        sub_account_id: data.sub_account?.value,
        dest_bank_account_id: data?.dest_account?.value,
        transfer_method: quote?.transfer_method,
        dest_wire_msg: data?.dest_wire_msg,
      }).unwrap();

      if (res?.id) {
        handleClose();
        navigate(
          Locations["/ccy-conversion/order-rio/:id"].replace(":id", res.id),
        );
      }
    } catch (e) {
      console.error(e);
    }
  };

  const isRefetching = isLoadingGetQuote || isLoadingCreateRioOrder;

  const body = () => {
    if (errorGetQuote || errorCreateOrder) {
      return <Modal.Body>{errorGetQuote || errorCreateOrder}</Modal.Body>;
    }

    return (
      <>
        <Modal.Body>
          <Grid>
            {[
              {
                label: t("Client"),
                component: data.sub_account?.label,
              },
              {
                label: t("Source CCY"),
                component: data.ccy_source,
              },
              {
                label: t("Destination CCY"),
                component: data.ccy_dest,
              },
            ]}
          </Grid>

          <Divider />

          <Grid>
            {[
              {
                label: t("Source Quantity"),
                component: (
                  <LoadingComponent isRefetching={isLoadingGetQuote}>
                    <div>
                      <CurrencyBody symbol={quote?.ccy_source}>
                        {quote?.qty_source || 0}
                      </CurrencyBody>
                      <span> {data.ccy_source}</span>
                    </div>
                  </LoadingComponent>
                ),
              },
              {
                label: t("Destination Quantity"),
                component: (
                  <LoadingComponent isRefetching={isLoadingGetQuote}>
                    <div>
                      <CurrencyBody symbol={quote?.ccy_dest}>
                        {quote?.qty_dest || 0}
                      </CurrencyBody>
                      <span> {data.ccy_dest}</span>
                    </div>
                  </LoadingComponent>
                ),
              },
              {
                label: t("Reference Exchange Rate"),
                component: (
                  <LoadingComponent isRefetching={isLoadingGetQuote}>
                    <div>
                      <CurrencyBody isExchangeRate symbol={data.ccy_source}>
                        {BigNumber(1).dividedBy(
                          BigNumber(quote?.rate_reference!),
                        )}
                      </CurrencyBody>
                      <span>
                        {" "}
                        {data.ccy_dest}/{data.ccy_source}
                      </span>
                    </div>
                  </LoadingComponent>
                ),
              },
              {
                label: t("Client Exchange Rate"),
                component: (
                  <LoadingComponent isRefetching={!data?.quote_target}>
                    <div>
                      <span className="badge-COMPLETE px-1 py-1 rounded-sm">
                        <CurrencyBody isExchangeRate symbol={data.ccy_source}>
                          {data?.quote_target || 0}
                        </CurrencyBody>
                      </span>
                      <span>
                        {" "}
                        {data.ccy_dest}/{data.ccy_source}
                      </span>
                    </div>
                  </LoadingComponent>
                ),
              },
              {
                label: t("Spread"),
                component: (
                  <LoadingComponent isRefetching={isLoadingGetQuote}>
                    <div>
                      <span className="">
                        <CurrencyBody symbol={data.ccy_source}>
                          {calculateSpread(
                            BigNumber(1).dividedBy(BigNumber(quote?.rate!)),
                            quote?.rate_reference!,
                            "cents",
                          ).toPrecision(5)}
                        </CurrencyBody>
                      </span>
                      <span> ￠</span>
                    </div>
                  </LoadingComponent>
                ),
              },
            ]}
          </Grid>
          <LoadingComponent
            isRefetching={isLoadingGetQuote}
            fetchingLabel={
              <span className="text-4">{t("Refreshing Quote")}...</span>
            }
          >
            <div className="flex text-4 gap-1">
              <span className="font-semibold">{t("Quote Refreshes In")} </span>
              <Countdown
                date={new Date().setSeconds(new Date().getSeconds() + 14)}
                intervalDelay={0}
                precision={2}
                renderer={(props) => (
                  <span className="text-4">
                    {props.formatted.minutes}:{props.formatted.seconds}
                  </span>
                )}
                onComplete={handleRefreshQuoute}
              />
            </div>
          </LoadingComponent>
        </Modal.Body>
        <Modal.Footer>
          <div className="flex gap-4">
            <div>
              <Button
                className="bg-transparent text-gray-dark500 hover:bg-gray hover:text-white border border-gray rounded-full text-4 py-2 px-8 justify-center items-center"
                onClick={handleClose}
                disabled={isRefetching}
              >
                {t("Cancel")}
              </Button>
            </div>
            <div>
              <Button
                className="bg-blue hover:bg-blue-dark100 rounded-full text-white text-4 py-2 px-8 items-center"
                type="submit"
                onClick={handleAcceptOrder}
                disabled={isRefetching}
              >
                {isLoadingCreateRioOrder ? t("Confirming Quote") : t("Confirm")}
              </Button>
              <Tooltip style={{ maxWidth: "20rem" }} id="validation-message" />
            </div>
          </div>
        </Modal.Footer>
      </>
    );
  };

  return (
    <Modal>
      <Modal.Header handleClose={handleClose}>
        <span className="flex items-center md:text-7 text-6 font-interSemiBold text-black">
          {t("Confirm Quote")}
        </span>
      </Modal.Header>
      {body()}
    </Modal>
  );
};

export default ConfirmQuoteBrokerRioModal;
