import { useDispatch, useSelector } from "react-redux";
import { getAuthWebSocketQuery } from "../api/customFetchBase";
import useWebSocket from "react-use-websocket";

import { useSnackbar } from "notistack";
import {
  ICcyConversionOrder,
  ICcyConversionWSInitialSnap,
  ICcyConversionWSOrderUpdate,
} from "../interfaces";
import { useTranslation } from "react-i18next";
import { ccyConversionOrders } from "../api/institutional/bitso_odl";
import {
  FlagEnum,
  Locations,
  CcyConversionOrderStatus,
  WebSocketEvents,
} from "../interfaces/enums";
import { useGetUserQuery } from "../api/institutional/user";
import { createSearchParams, useNavigate } from "react-router-dom";
import {
  UpdatedOrderSnack,
  AcceptedQuoteMMSnack,
  CancelledOrderByMMSnack,
  CancelledOrderSnack,
  GeneratedQuoteClientSnack,
  GeneratedQuoteMMSnack,
  RequestedQuoteSnack,
  CompletedCcyConversionOrderSnack,
  CreateOrderSnack,
} from "../components/Shared/Snacks";
import { triggerSound } from "../utils/triggerSound";

type MessageType = ICcyConversionWSInitialSnap | ICcyConversionWSOrderUpdate;

const isInitialSnap = (
  message: MessageType,
): message is ICcyConversionWSInitialSnap => {
  return message.event === WebSocketEvents.initial_snapshot;
};

const isOrderUpdate = (
  message: MessageType,
): message is ICcyConversionWSOrderUpdate => {
  return message.event === WebSocketEvents.order_update;
};

export const useCcyConversionOrders = () => {
  const apiId = "websocket";

  const endpoint = "/ccy_conversion/order_status";

  const { enqueueSnackbar } = useSnackbar();

  const auth = useSelector((state: any) => state.auth);

  const global = useSelector((state: any) => state.global);

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const navigate = useNavigate();

  const { data: user } = useGetUserQuery({});

  const isMMUser = user?.flags.includes(FlagEnum.bitso_odl_mm);

  const isClientUser = user?.flags.includes(FlagEnum.bitso_odl_client);

  const notify = (order: ICcyConversionOrder, action: any) => {
    switch (order.status) {
      case CcyConversionOrderStatus.QUOTE_REQUESTED:
        if (isMMUser) {
          enqueueSnackbar(<RequestedQuoteSnack />, {
            action,
            autoHideDuration: 5000,
          });
          triggerSound();
        }
        if (isClientUser) {
          enqueueSnackbar(<CreateOrderSnack />, {
            action,
            autoHideDuration: 5000,
          });
        }
        break;
      case CcyConversionOrderStatus.QUOTE_GENERATED:
        if (isClientUser) {
          enqueueSnackbar(<GeneratedQuoteClientSnack />, {
            action,
            autoHideDuration: 5000,
          });
          triggerSound();
        }
        if (isMMUser) {
          enqueueSnackbar(<GeneratedQuoteMMSnack />, {
            autoHideDuration: 5000,
          });
        }
        break;
      case CcyConversionOrderStatus.QUOTE_ACCEPTED:
        if (isMMUser) {
          enqueueSnackbar(<AcceptedQuoteMMSnack />, {
            action,
            autoHideDuration: 5000,
          });
          triggerSound();
        }
        break;
      case CcyConversionOrderStatus.CANCELLED_BY_MM:
        if (isClientUser) {
          enqueueSnackbar(<CancelledOrderByMMSnack />, {
            action,
            autoHideDuration: 5000,
          });
          triggerSound();
        }
        if (isMMUser) {
          enqueueSnackbar(<CancelledOrderSnack />, { autoHideDuration: 5000 });
        }
        break;
      case CcyConversionOrderStatus.SRC_CCY_DEPOSIT_RECIEVED:
        if (isMMUser) {
          enqueueSnackbar(<UpdatedOrderSnack />, { autoHideDuration: 5000 });
        }
        break;
      case CcyConversionOrderStatus.DEST_CCY_TRANSFER_PENDING:
        if (isMMUser) {
          enqueueSnackbar(<UpdatedOrderSnack />, { autoHideDuration: 5000 });
        }
        break;
      case CcyConversionOrderStatus.COMPLETE:
        if (isMMUser) {
          enqueueSnackbar(<UpdatedOrderSnack />, { autoHideDuration: 5000 });
        }
        if (isClientUser) {
          enqueueSnackbar(<CompletedCcyConversionOrderSnack />, {
            action,
            autoHideDuration: 5000,
          });
          triggerSound();
        }
        break;
    }
  };

  const websocket = useWebSocket(
    isClientUser ? getAuthWebSocketQuery(apiId, endpoint, auth) || "" : null,
    {
      onClose: () => {},
      queryParams: {
        authorization: encodeURIComponent(auth?.token),
      },
      shouldReconnect: (closeEvent) => true,
      retryOnError: true,
      onOpen: () => {},
      onMessage: (ev) => {
        const message = JSON.parse(ev.data) as MessageType;
        if (isOrderUpdate(message)) {
          const order = message.data;

          const order_id = order.id;

          // In the API of ccyConversionOrders
          // Modify query data of endpoint getCcyConversionOrder at order_id in cache
          // Draft is an immutable object that contains order values in cache
          // Object.assings combines old order values (draft) with new order values
          dispatch<any>(
            ccyConversionOrders.util.updateQueryData(
              "getCcyConversionOrder",
              { order_id },
              (draft) => {
                Object.assign(draft, order);
              },
            ),
          );

          dispatch<any>(
            ccyConversionOrders.util.updateQueryData(
              "getCcyConversionOrders",
              global.ccyConversionOrdersQuery,
              (draft): any => {
                const index = draft.findIndex((o) => o.id === order_id);
                if (index > -1) {
                  draft[index] = Object.assign(draft[index], order);
                } else {
                  draft.push(order);
                  dispatch(
                    ccyConversionOrders.util.invalidateTags([
                      "CcyConversionOrders",
                    ]),
                  );
                }
              },
            ),
          );

          const action = () => (
            <div className="flex justify-end ml-2">
              <button
                className="bg-white text-black rounded-full py-2 px-4"
                onClick={() => {
                  isClientUser
                    ? navigate(
                        Locations["/ccy-conversion/order/:id"].replace(
                          ":id",
                          order_id,
                        ),
                      )
                    : navigate({
                        pathname: Locations["/ccy-conversion/mm"],
                        search: `?${createSearchParams({
                          orderId: order_id,
                        })}`,
                      });
                }}
              >
                {t("View order")}
              </button>
            </div>
          );

          notify(order, action);
        } else if (isInitialSnap(message)) {
        }
      },
    },
  );

  return websocket;
};

export default useCcyConversionOrders;
