import React, { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";

export const MobileLangSelector = () => {
  const { i18n } = useTranslation();

  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    i18n.changeLanguage(e.currentTarget.value);
  };
  return (
    <select
      className="form p-2 bg-white w-full block underline"
      onChange={handleChange}
      defaultValue={i18n.language}
    >
      <option value="es">ES</option>
      <option value="en">EN</option>
    </select>
  );
};

export const LangSelector = () => {
  const { i18n } = useTranslation();

  const handleChange = (lang: any) => {
    i18n.changeLanguage(lang);
  };

  return (
    <div className="flex items-center">
      <div className="flex bg-gray-dark300 py-1 px-2 rounded-md">
        <p
          onClick={() => handleChange("en")}
          className={`flex items-center cursor-pointer md:text-3 lg:text-3 rounded-md ${
            i18n.language === "en"
              ? "bg-white p-1 text-black"
              : "text-white p-1"
          }`}
        >
          EN
        </p>
        <p
          onClick={() => handleChange("es")}
          className={`flex items-center cursor-pointer md:text-3 ml-1 lg:text-3 rounded-md ${
            i18n.language === "es"
              ? "bg-white p-1 text-black"
              : "text-white p-1"
          }`}
        >
          ES
        </p>
      </div>
    </div>
  );
};

export default LangSelector;
