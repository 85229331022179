import React from "react";
// import { Backdrop } from "@mui/material";

import { closeModal } from "app/slices/modalSlice";

import Modal from "../modal";
import { useTranslation } from "react-i18next";
import { Button } from "components/Shared";
// import { enqueueSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import { IDestinationBankAccountAll } from "interfaces";
import {
  DestinationBankAccountClassName,
  TransactionTypeOptions,
} from "interfaces/enums";
import Truncator from "components/Shared/truncator";
import { emptyValue } from "utils";

const Label = ({ label, value }: { label: string; value: any }) => {
  return (
    <div className="grid grid-cols-2">
      <p className="text-5 font-interSemiBold">{label}</p>
      <Truncator maxLength={24}>{value}</Truncator>
    </div>
  );
};

export const ViewBankAccountModal = ({
  data,
}: {
  data: IDestinationBankAccountAll;
}) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(closeModal());
  };

  const destinationAccount = () => {
    if (data.class_name === DestinationBankAccountClassName.SPID) {
      return (
        <>
          <Label label={t("Recipient's Name")} value={data?.recipient_name} />
          <Label
            label={t("Transfer Type")}
            value={
              data?.class_name
                ? TransactionTypeOptions[data?.class_name]
                : emptyValue
            }
          />
          <Label label={t("Bank Name")} value={data?.bank_name} />
          <Label label={"CLABE"} value={data?.clabe_dest} />
          <Label label={t("Recipient's RFC")} value={data?.rfc_dest} />
          <Label
            label={t("Recipient's Address")}
            value={data?.address_dest || emptyValue}
          />
        </>
      );
    } else if (
      data.class_name === DestinationBankAccountClassName.UsDomesticWire
    ) {
      return (
        <>
          <Label label={t("Recipient's Name")} value={data?.recipient_name} />
          <Label
            label={t("Transfer Type")}
            value={
              data?.class_name
                ? TransactionTypeOptions[data?.class_name]
                : emptyValue
            }
          />
          <Label label={t("Bank Name")} value={data?.bank_name} />
          <Label label={t("Account Number")} value={data?.acct_number_dest} />
          <Label label={t("Routing Number")} value={data?.routing_dest} />
          <Label
            label={t("Recipient's Address")}
            value={data?.address_dest || emptyValue}
          />
          <Label label={t("Bank's Address")} value={data?.bank_address} />
        </>
      );
    } else if (
      data.class_name === DestinationBankAccountClassName.CryptoWallet
    ) {
      return (
        <>
          <Label label={t("Alias")} value={data?.alias} />
          <Label
            label={t("Transfer Type")}
            value={
              data?.class_name
                ? TransactionTypeOptions[data?.class_name]
                : emptyValue
            }
          />
          <Label label={t("Network")} value={data?.network} />
          <Label label={t("Wallet Address")} value={data?.wallet_address} />
        </>
      );
    }
  };

  return (
    <Modal>
      <Modal.Header handleClose={handleClose}>
        <span className="md:text-7 text-6 font-interSemiBold text-black">
          {t("Destination Account Details")}
        </span>
      </Modal.Header>
      <Modal.Body>
        {destinationAccount()}
        <Label label={t("Email")} value={data?.email || emptyValue} />
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="bg-blue hover:bg-blue-dark100 rounded-full text-white text-4 py-2 px-6 items-center"
          onClick={handleClose}
        >
          {t("Close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ViewBankAccountModal;
