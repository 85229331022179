/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import {
  ExchangeOptions,
  OrderTypeOptions,
  RioSupportedDOCountry,
  SpreadType,
} from "interfaces/enums";

export type ExchangeType = {
  [key in ExchangeOptions]: {
    base_ccy: string;
    quote_ccy: string;
    orderType: OrderTypeOptions;
  };
};

export interface MMType {
  lastOrderOriginClabe?: string;
}
interface SliceState {
  otc: ExchangeType;
  mm: MMType;
  spreadType: SpreadType;
  rio_country_dest: RioSupportedDOCountry;
}

/**
 * Initial state for each exchange
 */
const initialState: SliceState = {
  otc: {
    [ExchangeOptions.Bitso]: {
      base_ccy: "USDT",
      quote_ccy: "MXN",
      orderType: OrderTypeOptions.active_trigger,
    },
    [ExchangeOptions.Binance]: {
      base_ccy: "BTC",
      quote_ccy: "USDT",
      orderType: OrderTypeOptions.active_trigger,
    },
    [ExchangeOptions.CryptoDotCom]: {
      base_ccy: "",
      quote_ccy: "",

      orderType: OrderTypeOptions.active_trigger,
    },
    [ExchangeOptions.Gemini]: {
      base_ccy: "",
      quote_ccy: "",

      orderType: OrderTypeOptions.active_trigger,
    },
    [ExchangeOptions.Huobi]: {
      base_ccy: "",
      quote_ccy: "",

      orderType: OrderTypeOptions.active_trigger,
    },
    [ExchangeOptions.Kraken]: {
      base_ccy: "",
      quote_ccy: "",

      orderType: OrderTypeOptions.active_trigger,
    },
    [ExchangeOptions.OKX]: {
      base_ccy: "",
      quote_ccy: "",

      orderType: OrderTypeOptions.active_trigger,
    },
  },
  mm: {
    lastOrderOriginClabe: "",
  },
  spreadType: "cents",
  rio_country_dest: RioSupportedDOCountry.MX,
};

export const presetsSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setRioCountryDest: (state, action: { payload: RioSupportedDOCountry }) => {
      state.rio_country_dest = action.payload;
    },
    setSpreadType: (state, action: { payload: SpreadType }) => {
      state.spreadType = action.payload;
    },
    setOrderType: (
      state,
      action: {
        payload: { orderType: OrderTypeOptions; exchange: ExchangeOptions };
      },
    ) => {
      state.otc[action.payload.exchange].orderType = action.payload.orderType;
    },
    setBaseCCY: (
      state,
      action: { payload: { base_ccy: string; exchange: ExchangeOptions } },
    ) => {
      state.otc[action.payload.exchange].base_ccy = action.payload.base_ccy;
    },
    setQuoteCCY: (
      state,
      action: { payload: { quote_ccy: string; exchange: ExchangeOptions } },
    ) => {
      state.otc[action.payload.exchange].quote_ccy = action.payload.quote_ccy;
    },
    setLastOrderOriginClabe: (state, action: { payload: string }) => {
      state.mm.lastOrderOriginClabe = action.payload;
    },
    reset: () => initialState,
  },
});

export const {
  setBaseCCY,
  setQuoteCCY,
  reset,
  setSpreadType,
  setOrderType,
  setRioCountryDest,
  setLastOrderOriginClabe,
} = presetsSlice.actions;

export type PresetsState = ReturnType<typeof presetsSlice.reducer>;
