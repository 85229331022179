import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { IOnBoarding, IOnboardingDraft } from "interfaces";
import { useTranslation } from "react-i18next";
import { Completed } from "./Completed";

import { DynamicFormUnregulated } from "./DynamicFormUnregulated";
import { useDispatch } from "react-redux";
import { addDocument } from "app/slices/globalSlice";

import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { useUpdateOnboardingUnRegulatedMutation } from "api/institutional/onboarding";
import { useUploader } from "hooks/useUpload";
import classNames from "classnames";

import { enqueueSnackbar } from "notistack";
import { errorSnack } from "components/Shared";
import { useEffect, useRef } from "react";
import { IDTypes } from "interfaces/enums";
import { ParticipantId } from "./ParticipantId";
import { useMergeFiles } from "./useMergeFiles";
import { UploadOrShow } from "./UploadOrShow";

const LegalRep = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext<IOnboardingDraft>();
  const { t } = useTranslation();
  return (
    <input
      placeholder={t("Nombre completo")}
      {...register(`legal_rep_name`, {
        required: true,
      })}
      className={classNames(
        "bg-[#F9FAFB] w-full p-2 rounded-md text-3 ",
        errors?.legal_rep_name && "error",
      )}
    />
  );
};

export const NonRegulated = ({ onboarding }: { onboarding: IOnBoarding }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const methods = useForm<IOnboardingDraft>({
    defaultValues: {
      legal_rep_name: "",
      id_type: IDTypes.INE,
    },
    mode: "onBlur",
  });

  const doDrop = async (files: [File], category: string) => {
    const filename = await upload(`/kyb/document/${onboarding.id}`, files);
    dispatch(addDocument({ filename, category }));
  };

  const { upload, error: uploadError, isLoading } = useUploader();

  const [mutate] = useUpdateOnboardingUnRegulatedMutation();

  const legal_rep_name = methods.watch("legal_rep_name");

  const { getFiles } = useMergeFiles();

  const handleSend = async () => {
    const { corporate_shareholders, legal_rep_name } = methods.getValues();

    mutate({
      id: onboarding.id,
      company_name: onboarding.company_name.trim(),
      email: onboarding.email.trim(),
      company_category: onboarding.company_category.trim(),
      legal_rep_name: legal_rep_name?.trim() || "",
      corporate_shareholders,
      files: getFiles(corporate_shareholders),
    });
  };

  useEffect(() => {
    if (uploadError) {
      enqueueSnackbar(t("Error uploading KYB"), errorSnack);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadError]);

  const actaconstComplete = !!onboarding?.acta_constitutiva?.length;

  const constanciaFull =
    !!onboarding?.constancias_situacion_fiscal_sociedad?.length;

  const idcompleted = () => {
    if (
      onboarding.id_type === IDTypes.passport &&
      onboarding?.document_id?.length
    ) {
      return true;
    }
    if (
      onboarding.id_type === IDTypes.INE &&
      onboarding?.document_ine_anverso?.length &&
      onboarding?.document_ine_reverso?.length
    ) {
      return true;
    }
    return false;
  };

  const accordionRef = useRef<HTMLDivElement>(null);

  return (
    <FormProvider {...methods}>
      <div>
        <span className="text-4 pb-6 text-[#767373]">
          {t(
            "Completa los campos presentados a continuación. La revisión de la información puede tomar hasta 48 horas",
          )}
          .
        </span>
      </div>

      <div>
        <Accordion defaultExpanded>
          <AccordionSummary id="panel0-header" expandIcon={<ExpandMoreIcon />}>
            <div className="flex gap-2 items-center">
              <Completed completed={!!legal_rep_name} />
              <span className="font-semibold text-3 self-center">
                {t("Representante Legal")}
              </span>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="px-8 py-0 flex flex-col gap-4">
              <span className="text-3 text-[#737373]">
                {t("¿Quién está actuando como Representante Legal?")}
              </span>
              <LegalRep />
            </div>
          </AccordionDetails>
        </Accordion>

        <ParticipantId
          onboarding={onboarding}
          handleIsComplete={() => {
            if (accordionRef.current?.ariaExpanded === "false")
              accordionRef.current?.click();
          }}
        />

        <Accordion ref={accordionRef}>
          <AccordionSummary id="panel1-header" expandIcon={<ExpandMoreIcon />}>
            <div className="flex gap-2 items-center">
              <Completed completed={actaconstComplete} />
              <span className="font-semibold text-3 self-center">
                {t(
                  "Acta Constitutiva (y documentos de movimientos de acciones, en su caso)",
                )}
              </span>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="px-8 py-0 flex flex-col gap-4">
              <span className="text-3 text-[#737373]">
                {t("Agrega una copia simple como imagen o PDF")}
              </span>
              <UploadOrShow
                multiUpload={true}
                category={"acta_constitutiva"}
                doDrop={doDrop}
                files={onboarding?.acta_constitutiva}
                label={
                  onboarding?.acta_constitutiva?.length
                    ? t("Otros documentos")
                    : t("Acta Constitutiva")
                }
              />
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary id="panel1-header" expandIcon={<ExpandMoreIcon />}>
            <div className="flex gap-2 items-center">
              <Completed completed={constanciaFull} />
              <span className="font-semibold text-3 self-center">
                {t(
                  "Constancia de Situación Fiscal de la Sociedad (no mayor a 3 meses)",
                )}
              </span>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="px-8 py-0 flex flex-col gap-4">
              <span className="text-3 text-[#737373]">
                {t("Agrega una copia simple como imagen o PDF")}
              </span>

              <UploadOrShow
                multiUpload={false}
                category={"constancias_situacion_fiscal_sociedad"}
                doDrop={doDrop}
                files={onboarding?.constancias_situacion_fiscal_sociedad}
                label={t("Cargar Constancia de Situación Fiscal")}
              />
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary id="panel1-header" expandIcon={<ExpandMoreIcon />}>
            <div className="flex gap-2 items-center">
              <Completed />
              <span className="font-semibold text-3 self-center">
                {t("Datos de los accionistas (con 25% participación o más)")}
              </span>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="px-8 py-0 flex flex-col gap-4">
              <DynamicFormUnregulated onboardingId={onboarding.id} />
            </div>
          </AccordionDetails>
        </Accordion>
      </div>

      <Button
        variant="contained"
        disabled={
          !methods.formState.isValid ||
          !legal_rep_name ||
          !actaconstComplete ||
          // !societyFull ||
          isLoading ||
          !idcompleted() ||
          !constanciaFull
        }
        onClick={handleSend}
      >
        {t("Enviar")}
      </Button>
    </FormProvider>
  );
};
