import { useTranslation } from "react-i18next";
import { Add } from "@mui/icons-material";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import classNames from "classnames";
import { Button, Divider, FormControl, TextField } from "@mui/material";
import { FileStructure, IOnboardingDraft } from "interfaces";
import PhoneInputWithCountrySelect from "react-phone-number-input";
import flags from "react-phone-number-input/flags";
import { UploadByType } from "./UploadByType";
import { IDTypes } from "interfaces/enums";
import { useUploader } from "hooks/useUpload";
import { UploadOrShow } from "./UploadOrShow";
import { useTrimmer } from "utils";

export function DynamicFormUnregulated({
  onboardingId,
}: {
  onboardingId: string;
}) {
  const { t } = useTranslation();

  const {
    register,
    control,
    getValues,
    formState: { errors },
  } = useFormContext<IOnboardingDraft>();

  const handleRemove = (index: number) => {
    remove(index);
  };
  const { fields, append, remove, update } = useFieldArray({
    name: "corporate_shareholders",
    control,
  });
  const { upload, isLoading: isUploading } = useUploader();

  const { onBlur } = useTrimmer();

  return (
    <div className="flex flex-col gap-4">
      {fields.map((field, index) => {
        const doDrop = async (files: [File], category: string) => {
          const filename = await upload(
            `/kyb/document/${onboardingId}`,
            files,
            category,
          );
          const cp = getValues(`corporate_shareholders`)!;

          const newFile: FileStructure = {
            filename,
            category,
          };

          let subcategory = category;
          let idx = index;

          if (category.split(".").length > 1) {
            const [, ixx, sub] = category.split(".");
            subcategory = sub;
            idx = Number(ixx);
          }

          const obj = {
            ...cp[idx],
            [subcategory]: [newFile],
          };

          update(idx, obj);
        };

        return (
          <div key={field.id} className="flex flex-col gap-4">
            <div className="flex w-full justify-between items-center">
              <span className="text-3 font-semibold">
                {t("Accionista")} {index + 1}
              </span>
              <Button
                size="small"
                disabled={isUploading}
                onClick={() => handleRemove(index)}
              >
                {t("Eliminar")}
              </Button>
            </div>
            <div className="flex flex-col gap-4 text-3 w-full">
              <FormControl fullWidth>
                <TextField
                  label={t("Nombre completo")}
                  {...register(
                    `corporate_shareholders.${index}.name` as const,
                    {
                      required: true,
                      onBlur,
                    },
                  )}
                  className={classNames(
                    errors?.corporate_shareholders?.[index]?.name && "error",
                  )}
                />
              </FormControl>
              <FormControl fullWidth>
                <TextField
                  label={t("Email")}
                  type="email"
                  {...register(
                    `corporate_shareholders.${index}.email` as const,
                    {
                      required: true,
                      onBlur,
                    },
                  )}
                  className={classNames(
                    errors?.corporate_shareholders?.[index]?.email && "error",
                  )}
                />
              </FormControl>
              <Controller
                name={`corporate_shareholders.${index}.phone`}
                control={control}
                rules={{ required: true }}
                render={({ field }) => {
                  return (
                    <FormControl fullWidth>
                      <PhoneInputWithCountrySelect
                        flags={flags}
                        international
                        limitMaxLength={true}
                        defaultCountry={"MX"}
                        label="Enter phone number"
                        {...field}
                        className={classNames(
                          "h-[56px] border rounded-md px-4 text-4 border-[#ccc]",
                          errors?.corporate_shareholders?.[index]?.phone &&
                            "error",
                        )}
                      />
                    </FormControl>
                  );
                }}
              />
            </div>
            <div className="flex flex-col gap-4">
              <UploadByType
                isUploading={isUploading}
                doDrop={doDrop}
                handleDelete={(_filename: string, category: string) => {
                  update(index, {
                    ...field,
                    [category]: [],
                  });
                }}
                document_ine_anverso={field.document_ine_anverso}
                document_ine_reverso={field.document_ine_reverso}
                passport={field.document_id}
                idType={field.id_type}
                handleSendType={(_id_type) => {
                  update(index, {
                    ...getValues(`corporate_shareholders.${index}`),
                    id_type: _id_type,
                  });
                }}
              />
            </div>
            <div className="flex gap-4">
              <UploadOrShow
                isUploading={isUploading}
                multiUpload={false}
                category={`corporate_shareholders.${index}.cif`}
                doDrop={doDrop}
                files={field?.cif}
                handleDelete={() => {
                  update(index, {
                    ...field,
                    cif: [],
                  });
                }}
                label={t("Constancia de Situación Fiscal")}
              />
            </div>
            <Divider />
          </div>
        );
      })}
      <Button
        type="button"
        variant="outlined"
        size="small"
        onClick={() =>
          append({
            name: "",
            email: "",
            phone: "",
            cif: [],
            document_id: [],
            id_type: IDTypes.INE,
          })
        }
        startIcon={<Add></Add>}
      >
        {t("Agregar accionista")}
      </Button>
    </div>
  );
}
