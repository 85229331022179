import BigNumber from "bignumber.js";
import { symbolPrecisions } from "utils/numberUtils";
import { IOrder } from "interfaces";
import { useTranslation } from "react-i18next";
import { ReactComponent as NeutralXIcon } from "assets/svg/neutral-x-icon.svg";
import { ReactComponent as TargetIcon } from "assets/svg/target-success-icon.svg";
import { ReactComponent as AlarmIcon } from "assets/svg/alarm-icon.svg";

export const CancelOrderSnack = ({ order }: { order: IOrder }) => {
  const { t } = useTranslation();
  return (
    <div className="flex gap-2 items-center justify-center">
      <NeutralXIcon height={35} width={35} />
      {t(
        "We have cancelled your {{side}} order of {{qty}} {{base_ccy}} for {{price}} {{quote_ccy}}.",
        {
          side: order.side,
          qty: BigNumber(order.qty).toFormat(
            symbolPrecisions(order.base_ccy),
            BigNumber.ROUND_FLOOR,
          ),
          base_ccy: order.base_ccy,
          quote_ccy: order.quote_ccy,
          price: BigNumber(order.price).toFormat(
            symbolPrecisions(order.quote_ccy),
            BigNumber.ROUND_FLOOR,
          ),
        },
      )}
    </div>
  );
};

export const CompletedOrderSnack = ({ order }: { order: IOrder }) => {
  const { t } = useTranslation();
  return (
    <div className="flex gap-2 items-center justify-center">
      <TargetIcon height={35} width={35} />
      {t(
        "Your order of {{qty}} {{base_ccy}} at {{price}} {{quote_ccy}} has been completed.",
        {
          qty: BigNumber(order.qty).toFormat(
            symbolPrecisions(order.base_ccy),
            BigNumber.ROUND_FLOOR,
          ),
          quote_ccy: order.quote_ccy,
          base_ccy: order.base_ccy,
          price: BigNumber(order.price).toFormat(
            symbolPrecisions(order.quote_ccy),
            BigNumber.ROUND_FLOOR,
          ),
        },
      )}
    </div>
  );
};

export const FatalErrorOrderSnack = ({ order }: { order: IOrder }) => {
  const { t } = useTranslation();
  return (
    <div className="flex gap-2 items-center justify-center">
      <AlarmIcon height={35} width={35} />
      {t(
        "Your order of {{qty}} {{base_ccy}} at {{price}} {{quote_ccy}} had a fatal error.",
        {
          qty: BigNumber(order.qty).toFormat(
            symbolPrecisions(order.base_ccy),
            BigNumber.ROUND_FLOOR,
          ),
          quote_ccy: order.quote_ccy,
          base_ccy: order.base_ccy,
          price: BigNumber(order.price).toFormat(
            symbolPrecisions(order.quote_ccy),
            BigNumber.ROUND_FLOOR,
          ),
        },
      )}
    </div>
  );
};

export const InsufficientFundsOrderSnack = ({ order }: { order: IOrder }) => {
  const { t } = useTranslation();
  return (
    <div className="flex gap-2 items-center justify-center">
      <AlarmIcon height={35} width={35} />
      {t(
        "You don't have available funds to complete the order of {{qty}} {{base_ccy}} at {{price}} {{quote_ccy}}.",
        {
          qty: BigNumber(order.qty).toFormat(
            symbolPrecisions(order.base_ccy),
            BigNumber.ROUND_FLOOR,
          ),
          quote_ccy: order.quote_ccy,
          base_ccy: order.base_ccy,
          price: BigNumber(order.price).toFormat(
            symbolPrecisions(order.quote_ccy),
            BigNumber.ROUND_FLOOR,
          ),
        },
      )}
    </div>
  );
};

export const EditedOrderSnack = ({
  order,
  newPrice,
}: {
  order: IOrder;
  newPrice: BigNumber;
}) => {
  const { t } = useTranslation();
  return (
    <div className="flex gap-2 items-center justify-center">
      <TargetIcon height={35} width={35} />
      {t(
        "Your {{side}} order of {{qty}} {{base_ccy}} at {{price}} {{quote_ccy}} has been modified to {{newPrice}} {{quote_ccy}}.",
        {
          qty: BigNumber(order.qty).toFormat(
            symbolPrecisions(order.base_ccy),
            BigNumber.ROUND_FLOOR,
          ),
          side: order.side,
          quote_ccy: order.quote_ccy,
          base_ccy: order.base_ccy,
          price: BigNumber(order.price).toFormat(
            symbolPrecisions(order.quote_ccy),
            BigNumber.ROUND_FLOOR,
          ),
          newPrice: BigNumber(newPrice).toFormat(
            symbolPrecisions(order.quote_ccy),
            BigNumber.ROUND_FLOOR,
          ),
        },
      )}
    </div>
  );
};

export const CreateOrderSnack = () => {
  const { t } = useTranslation();
  return (
    <div className="flex gap-2 items-center justify-center">
      <TargetIcon height={35} width={35} />
      {t("Created new order successfully, requesting quote now.")}
    </div>
  );
};

export const CancelledOrderByMMSnack = () => {
  const { t } = useTranslation();
  return (
    <div className="flex gap-2 items-center justify-center">
      <AlarmIcon height={35} width={35} />
      {t(
        "Order cancelled by market maker, you can continue by generating a new one.",
      )}
    </div>
  );
};

export const CancelledOrderSnack = () => {
  const { t } = useTranslation();
  return (
    <div className="flex gap-2 items-center justify-center">
      <NeutralXIcon height={35} width={35} />
      {t("Successfully cancelled order.")}
    </div>
  );
};

export const QuoteExpiredSnack = () => {
  const { t } = useTranslation();
  return (
    <div className="flex gap-2 items-center justify-center">
      <AlarmIcon height={35} width={35} />
      {t("Quote expired, you can continue by requesting a new quote.")}
    </div>
  );
};

export const RequestedQuoteSnack = () => {
  const { t } = useTranslation();
  return (
    <div className="flex gap-2 items-center justify-center">
      <TargetIcon height={35} width={35} />
      {t("New request for quote.")}
    </div>
  );
};

export const GeneratedQuoteClientSnack = () => {
  const { t } = useTranslation();
  return (
    <div className="flex gap-2 items-center justify-center">
      <TargetIcon height={35} width={35} />
      {t("A quote has been provided for your order.")}
    </div>
  );
};

export const GeneratedQuoteMMSnack = () => {
  const { t } = useTranslation();
  return (
    <div className="flex gap-2 items-center justify-center">
      <TargetIcon height={35} width={35} />
      {t("Successfully provided quote.")}
    </div>
  );
};

export const CurrencyConversionCompletedSnack = () => {
  const { t } = useTranslation();
  return (
    <div className="flex gap-2 items-center justify-center">
      <TargetIcon height={35} width={35} />
      {t("New order, please fulfill the required transfers.")}
    </div>
  );
};

export const CreateDestinationBankAccountSnack = () => {
  const { t } = useTranslation();
  return (
    <div className="flex gap-2 items-center justify-center">
      <TargetIcon height={35} width={35} />
      {t("Created new destination bank account successfully.")}
    </div>
  );
};

export const AcceptedQuoteClientSnack = () => {
  const { t } = useTranslation();
  return (
    <div className="flex gap-2 items-center justify-center">
      <TargetIcon height={35} width={35} />
      {t("Confirmed order successfully.")}
    </div>
  );
};

export const AcceptedQuoteMMSnack = () => {
  const { t } = useTranslation();
  return (
    <div className="flex gap-2 items-center justify-center">
      <TargetIcon height={35} width={35} />
      {t(
        "Client has accepted the quote, please proceed and fulfill the order.",
      )}
    </div>
  );
};

export const UpdatedOrderSnack = () => {
  const { t } = useTranslation();
  return (
    <div className="flex gap-2 items-center justify-center">
      <TargetIcon height={35} width={35} />
      {t("Successfully updated the status of the order.")}
    </div>
  );
};

export const ConfirmedTransferSnack = () => {
  const { t } = useTranslation();
  return (
    <div className="flex gap-2 items-center justify-center">
      <TargetIcon height={35} width={35} />
      {t("Transfer successfully confirmed.")}
    </div>
  );
};

export const CompletedCcyConversionOrderSnack = () => {
  const { t } = useTranslation();
  return (
    <div className="flex gap-2 items-center justify-center">
      <TargetIcon height={35} width={35} />
      {t("Your order has been completed.")}
    </div>
  );
};
