import ContentLoader from "react-content-loader";

export const BankLoader = () => {
  return (
    <>
      <ContentLoader
        width={"100%"}
        height={"100%"}
        style={{ overflow: "hidden" }}
        speed={4}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
      >
        <rect x="0" y="0" rx="0" ry="0" width="100%" height="100%" />
      </ContentLoader>
    </>
  );
};
