import React from "react";
import { closeModal } from "app/slices/modalSlice";

import Modal from "../modal";
import { useTranslation } from "react-i18next";
import { Button, errorSnack } from "components/Shared";
import { useDispatch } from "react-redux";
import { IBitsoOrder } from "interfaces";
import { useNavigate } from "react-router-dom";
import {
  ExchangeOptions,
  Locations,
  CcyConversionOrderStatus,
} from "interfaces/enums";
import { useCreateCcyConversionOrderMutation } from "api/institutional/bitso_odl";
import { useSnackbar } from "notistack";

export const RequestQuoteModal = ({ data: order }: { data: IBitsoOrder }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const [mutation, { isLoading }] = useCreateCcyConversionOrderMutation();

  const handleClose = () => {
    dispatch(closeModal());
  };

  const goToOrder = (tradeId: string): void => {
    const tradePath = "/ccy-conversion/order/" + tradeId;
    navigate(tradePath);
  };

  const goToOrders = () => {
    handleClose();
    navigate(Locations["/ccy-conversion/orders"]);
  };

  const handleRequestAgain = async () => {
    try {
      const data = (await mutation({
        broker_id: ExchangeOptions.Bitso, // TODO
        ccy_dest: order.ccy_dest,
        ccy_source: order.ccy_source,
        origin_clabe: order.origin_clabe!,
        transfer_qtys: order.transfers.reduce(
          (acc, { destination_account: { id }, qty }) => ({
            ...acc,
            [id as any]: qty,
          }),
          {},
        ),
      })) as { data: string };

      if (data?.data) {
        goToOrder(data?.data);
        handleClose();
      } else {
        throw new Error();
      }
    } catch (e: any) {
      enqueueSnackbar(
        t(e?.data?.detail, "An error occurred. Please notify Ridian."),
        errorSnack,
      );
    }
  };

  const messageSwitch = () => {
    switch (order.status) {
      case CcyConversionOrderStatus.QUOTE_EXPIRED:
        return <p>{t("Unfortunately your quote expired")}.</p>;
      case CcyConversionOrderStatus.CANCELLED_BY_MM:
        return (
          <p>
            {t(
              "Unfortunately your quote got cancelled by Ridian's liquidity provider. This can happen for several reasons, such as the provided quote not longer being available",
            )}
          </p>
        );
      case CcyConversionOrderStatus.CANCELLED_BY_CLIENT:
        return <p>{t("You have cancelled the order.")}</p>;
      default:
        return null;
    }
  };

  return (
    <Modal>
      <Modal.Header handleClose={goToOrders}>
        <span className="md:text-7 text-6 font-interSemiBold text-black">
          {t("Request New Quote")}
        </span>
      </Modal.Header>
      <Modal.Body>
        <div>
          {/* Text explaining what happened */}
          {messageSwitch()}
          <p className="pt-4">
            {t(
              "You can continue and generate a new quote with the same parameters",
            )}
            .
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="bg-blue hover:bg-blue-dark100 rounded-full text-white text-4 py-2 px-6 items-center"
          onClick={handleRequestAgain}
          disabled={isLoading}
        >
          {t("Request Quote Again")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RequestQuoteModal;
