import { Description, Close } from "@mui/icons-material";
import { removeFile } from "app/slices/globalSlice";
import { IOnBoarding } from "interfaces";

import { useFormContext } from "react-hook-form";
import { useDispatch } from "react-redux";

export const FileItem = ({
  filename,
  category,
  handleDelete,
}: {
  filename: string;
  category: string;
  handleDelete?: (id: string, category: string) => void;
}) => {
  const { getValues, setValue } = useFormContext<IOnBoarding>();
  const dispatch = useDispatch();

  const handleOnDelete = () => {
    handleDelete?.(filename, category);

    const splitted = category.split(".");

    if (splitted.length > 1) {
      const [type, i, subcategory] = category.split(".") as [
        a: "corporate_shareholders",
        b: number,
        c: string,
      ];

      const shareholder = getValues(`${type}.${i}`);

      setValue(`${type}.${i}`, {
        ...shareholder,
        [subcategory]: [],
      });
    } else {
      dispatch(removeFile({ category, filename }));
    }
  };

  return (
    <section className="container p-2 px-5 text-center flex items-center rounded-md border-[#d4d4d4] border w-full relative border-solid shadow-md">
      <div className="flex gap-2 items-center py-8">
        <Description className="text-[#9A9999]" fontSize={"small"} />
        <span className="text-2 font-semibold">{filename}</span>
      </div>
      <button
        className="absolute rounded-full  text-xs font-medium content-[''] leading-none grid place-items-center top-2 end-2 cursor-pointer border-[#d4d4d4]"
        onClick={handleOnDelete}
      >
        <Close style={{ height: "24px", width: "24px", color: "#d4d4d4" }} />
      </button>
    </section>
  );
};
