import { closeModal } from "app/slices/modalSlice";

import Modal from "../modal";
import { useTranslation } from "react-i18next";
import { successSnack } from "components/Shared";
import { useDispatch } from "react-redux";
import { IOnboardingDraft } from "interfaces";

import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { useCreateOnboardingMutation } from "api/institutional/onboarding";
import { Button, TextField } from "@mui/material";
import { Send } from "@mui/icons-material";
import useErrorListener from "hooks/useErrorListener";

export const SendKYBModal = (props: { data: any }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const handleClose = () => {
    dispatch(closeModal());
  };

  const {
    handleSubmit,
    register,
    formState: { isValid },
  } = useForm<IOnboardingDraft>();

  const [mutate, { error }] = useCreateOnboardingMutation();

  useErrorListener({ error });

  const doSubmit = (values: IOnboardingDraft) => {
    mutate({ email: values.email?.trim() });
    enqueueSnackbar(t("New KYB sent"), successSnack);
    handleClose();
  };

  return (
    <form onSubmit={handleSubmit(doSubmit)}>
      <Modal>
        <Modal.Header handleClose={handleClose}>
          <span className="md:text-7 text-6 font-interSemiBold text-black">
            {t("Send new KYB")}
          </span>
        </Modal.Header>
        <Modal.Body>
          <span className="text-3 font-semibold text-[#767373]">
            {t("Add the email of the user that will fill the KYB")}
          </span>
          <TextField
            className=""
            size="small"
            placeholder="Email"
            {...register("email", { required: true })}
          />

          <Button
            variant="contained"
            className="w-full"
            type="submit"
            disabled={!isValid}
            endIcon={<Send />}
          >
            {t("Send")}
          </Button>
        </Modal.Body>
      </Modal>
    </form>
  );
};

export default SendKYBModal;
