import BigNumber from "bignumber.js";

export const calculateSpread = (
  numA: number | BigNumber,
  numB: number | BigNumber,
  format: "cents" | "percentage" | "bps",
): BigNumber => {
  switch (format) {
    case "cents":
      return BigNumber(numA)
        .minus(BigNumber(1).dividedBy(BigNumber(numB)))
        .multipliedBy(100);

    case "bps":
      return BigNumber(numA)
        .dividedBy(BigNumber(1).dividedBy(BigNumber(numB)))
        .minus(1)
        .multipliedBy(100000);

    case "percentage":
      return BigNumber(numA)
        .dividedBy(BigNumber(1).dividedBy(BigNumber(numB)))
        .minus(1)
        .multipliedBy(100);

    default:
      return BigNumber(0);
  }
};
