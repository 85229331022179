import React from "react";
import { useTranslation } from "react-i18next";
import { Link, Navigate } from "react-router-dom";
import { useAuth } from "hooks/useAuth";
import SignUpLangSelector from "../SignUpLangSelector";
import RidianLogo from "components/RidianLogo";
import { Locations } from "interfaces/enums";
import { Button } from "components/Shared";

const EmailSent = ({ email, onSubmit }: { email?: string; onSubmit: any }) => {
  const { t } = useTranslation();

  const { user } = useAuth();

  return (
    <>
      {user ? (
        <Navigate to={Locations["/"]} replace={true} />
      ) : (
        <div className="p-4 sm:px-8 sm:py-0 flex gap-6 flex-col">
          <div className="justify-end absolute end-10 top-6 hidden sm:flex">
            <SignUpLangSelector theme="light" />
          </div>
          <div className="bg-white h-full w-full">
            <div className="fixed p-8">
              <RidianLogo />
            </div>
            <div className="flex flex-col pt-[8rem] justify-center items-center w-full">
              <div className="flex flex-col gap-6">
                <p className="text-8 font-ridianCubic font-interSemiBold text-left">
                  {t("Check your Email")}
                </p>
                <p className="text-gray-dark300 font-interSemiBold">
                  {t("A link to reset your password was sent to {{email}}", {
                    email: email,
                  })}
                </p>
                <div>
                  <div className="pt-6">
                    <Button
                      className="flex justify-center items-center text-4 w-full py-3 gap-2 font-interSemiBold text-white bg-black hover:bg-gray-dark300 transition duration-200 ease-in-out rounded-md"
                      onClick={onSubmit}
                    >
                      {t("Resend Email")}
                    </Button>
                  </div>
                  <div className="flex justify-center items-center space-x-1 text-4 pt-6">
                    <Link to={Locations["/login"]}>
                      <span className="font-interSemiBold">{t("Go back")}</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EmailSent;
