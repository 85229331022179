import React, { useEffect, useState } from "react";

import EmailSent from "components/EmailSent";
import ForgotPassword from "components/ForgotPassword";

import { isValidEmail } from "utils/validators";
import { useAuth } from "hooks/useAuth";

const ResetPassword = () => {
  const [email, setEmail] = useState();
  const [emailSent, setEmailSent] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const { resetPassword } = useAuth();

  useEffect(() => {
    setInvalidEmail(!isValidEmail(email));
  }, [email]);

  const handleSubmit = async () => {
    try {
      await resetPassword(email);
      setEmailSent(true);
    } catch (e) {
      setEmailSent(true);
    }
  };

  return emailSent ? (
    <EmailSent email={email} onSubmit={handleSubmit} />
  ) : (
    <ForgotPassword
      onEmailChange={setEmail}
      error={invalidEmail}
      email={email}
      onSubmit={handleSubmit}
    />
  );
};

export default ResetPassword;
