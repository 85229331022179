import React from "react";
import { useDispatch } from "react-redux";
import { Backdrop, CircularProgress } from "@mui/material";

import { closeModal } from "app/slices/modalSlice";
import Modal from "../modal";
import { useDeleteExchangeMutation } from "api/institutional/exchange";
import { useTranslation } from "react-i18next";
import { Button, Input } from "components/Shared";
import { useForm } from "react-hook-form";
import { IRidianExchange } from "interfaces";

export const DisconnectExchangeModal = ({
  data,
}: {
  data: IRidianExchange;
}) => {
  const {
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm({ mode: "all" });

  const [deleteExchangeFn, { isLoading, error }] = useDeleteExchangeMutation();

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(closeModal());
  };

  const doSubmit = async () => {
    // TODO: Currently, only one exchange is supported so all exchanges get deleted
    //       In the future, we need to specify what exchange to delete.
    try {
      // Set loading to true before the request
      // Edit exchanges slice with connected echanges
      const resp = await deleteExchangeFn().unwrap();

      if (!resp) {
        throw new Error();
      }
    } catch {
    } finally {
      // Set loading to false after the request is complete
      handleClose();
    }
  };

  const errorSwitch = (
    <div className="text-red text-3">
      {(() => {
        switch (error) {
          case "missingConfirmation":
            return <p>{t('Please type "REMOVE" to continue')}.</p>;
          case "apiError":
            return (
              <p>
                {t(
                  "There was a network error. Please try again later or contact Ridian for support",
                )}
                .
              </p>
            );
          default:
            return (
              <p>
                {t(
                  "An error occured, please try again later or contact Ridian for support",
                )}
                .
              </p>
            );
        }
      })()}
    </div>
  );

  if (isLoading) {
    return (
      <Backdrop open={isLoading}>
        <CircularProgress style={{ color: "black" }} />
      </Backdrop>
    );
  }

  return (
    <Modal>
      <form onSubmit={handleSubmit(doSubmit)}>
        <Modal.Header handleClose={handleClose}>
          <span className="md:text-7 text-6 font-interSemiBold text-black">
            {t("Remove Connection to {{exchange}}", { exchange: data.name })}
          </span>
        </Modal.Header>
        <Modal.Body>
          <div>
            <p className="mb-3 text-4 font-unica77LL">
              {t('Please confirm by typing "REMOVE"')}
            </p>
            <Input
              type="text"
              placeholder={t("Type REMOVE")}
              className="shadow-sm border"
              {...register("confirmation", {
                required: true,
                validate: (value) => {
                  return value === "REMOVE";
                },
              })}
            />
          </div>
          {error && errorSwitch}
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="bg-red hover:bg-red-dark200 text-white rounded-full px-4 py-2"
            type="submit"
            disabled={isLoading || !isValid}
          >
            {t("Disconnect Exchange")}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
