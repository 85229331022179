import { Outlet } from "react-router";
import useCcyConversionOrders from "../hooks/useCcyConversionOrders";
import useRioOrderWS from "../hooks/useRioOrderWS";
import useCalizaOrderWS from "hooks/useCalizaOrderWS";

export const CcyConversionProvider = () => {
  useCcyConversionOrders();
  useRioOrderWS();
  useCalizaOrderWS();
  return (
    <>
      <Outlet></Outlet>
    </>
  );
};
