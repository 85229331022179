import ContentLoader from "react-content-loader";

export const TextLoader = (props: any) => (
  <ContentLoader
    speed={2}
    width={32}
    height={12}
    viewBox="0 0 32 8"
    backgroundColor="#ccc"
    foregroundColor="#ecebeb"
    {...(props || {})}
  >
    <rect x="0" y="0" width={"32"} height={"12"}></rect>
  </ContentLoader>
);
