import { institutionalApi } from "./index";
import {
  IAmendOrder,
  ICreateOrderRequest,
  IGetOrderRequest,
  IOrder,
} from "interfaces";

export const ordersApi = institutionalApi.injectEndpoints({
  endpoints: (builder) => ({
    getOrderHistory: builder.query<Array<IOrder>, IGetOrderRequest>({
      query: (params) => ({
        url: `/algo_trading/?${new URLSearchParams(params as any)}`,
        method: "GET",
      }),
      providesTags: ["OrderHistoryQuery"],
    }),
    getOrderDetails: builder.query<IOrder, { orderId: string }>({
      query: (params) => ({
        url: `/algo_trading/?order_id=${params.orderId}`,
        method: "GET",
      }),
      providesTags: ["OrderDetailsQuery"],
    }),
    postOrder: builder.mutation<string, ICreateOrderRequest>({
      query: ({ orderType, ...body }) => ({
        url: `/algo_trading/${orderType}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["OrderHistoryQuery"],
    }),
    deleteOrder: builder.mutation<string, { orderId: string }>({
      query: (params) => ({
        url: `/algo_trading/?order_id=${params.orderId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["OrderHistoryQuery", "OrderDetailsQuery"],
    }),
    editOrder: builder.mutation<IOrder, IAmendOrder>({
      query: (body) => ({
        url: `/algo_trading/amend`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["OrderHistoryQuery", "OrderDetailsQuery"],
    }),
  }),
});

export const {
  usePostOrderMutation,
  useGetOrderHistoryQuery,
  useLazyGetOrderHistoryQuery,
  useGetOrderDetailsQuery,
  useDeleteOrderMutation,
  useEditOrderMutation,
} = ordersApi;
