import React, { useEffect } from "react";
import { Link, Navigate, useLocation } from "react-router-dom";

import { useAuth } from "hooks/useAuth";
import RidianLogo from "components/RidianLogo";
import { AuthenticateWithGoogleButton } from "components/AuthenticateWithGoogleButton";
import { useTranslation } from "react-i18next";
import { Button, Input, errorSnack } from "components/Shared";

import { useSnackbar } from "notistack";

import { Locations } from "interfaces/enums";
import SignUpLangSelector from "components/SignUpLangSelector";
import { ISignInForm } from "interfaces";
import { useForm } from "react-hook-form";

const Login = () => {
  const { user, firebaseUser, newSignInGoogle, logout, newSignInPassword } =
    useAuth();
  const { search } = useLocation();

  // const [showPassword, setShowPassword] = useState(false)

  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslation();

  const { register, handleSubmit } = useForm<ISignInForm>();

  useEffect(() => {
    const urlParams = new URLSearchParams(search);
    const tokenExpired = urlParams.get("tokenExpired");
    if (tokenExpired) {
      enqueueSnackbar(t("Token Expired. Please login again."), errorSnack);
    }
    logout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitSignIn = async (values: ISignInForm) => {
    try {
      await newSignInPassword(values.email, values.password);
    } catch (err) {
      console.error(err);
    }
  };

  if (firebaseUser && !firebaseUser.emailVerified) {
    return (
      <Navigate
        to={Locations["/verify-email"]}
        state={{ email: firebaseUser.email }}
      />
    );
  }

  return (
    <>
      {user ? (
        <Navigate to={Locations["/"]} replace={true} />
      ) : (
        <div className="p-4 sm:px-8 sm:py-0 flex gap-6 flex-col">
          <div className="justify-end absolute end-10 top-6 hidden sm:flex">
            <SignUpLangSelector theme="light" />
          </div>
          <div className="bg-white h-full w-full">
            <div className="fixed p-8">
              <RidianLogo />
            </div>
            <div className="flex flex-col pt-[8rem] justify-center items-center w-full">
              <div className="flex flex-col gap-6">
                <p className="text-8 font-ridianCubic font-interSemiBold text-left">
                  {t("Sign in")}
                </p>
                <p className="text-gray-dark300 font-interSemiBold">
                  Ridian Institutional
                </p>
                {/* Sign in Form */}
                <form onSubmit={handleSubmit(submitSignIn)}>
                  <div className="">
                    <Input
                      type="email"
                      placeholder={t("Email")}
                      bgColor="white"
                      className="border-b border-gray-dark200 text-5 hover:border-black focus:border-black hover:shadow-[inset_0_-1.3px_0_0_black] focus:shadow-[inset_0_-1.3px_0_0_black] placeholder-gray-dark100"
                      {...register("email", { required: true })}
                    />
                  </div>
                  <div className="pt-6">
                    <Input
                      type="password"
                      placeholder={t("Password")}
                      bgColor="white"
                      className="border-b border-gray-dark200 text-5 hover:border-black focus:border-black hover:shadow-[inset_0_-1.3px_0_0_black] focus:shadow-[inset_0_-1.3px_0_0_black] placeholder-gray-dark100"
                      {...register("password", { required: true })}
                    />
                  </div>
                  {/* TODO: Add Forgot password link */}
                  <div className="pt-6">
                    <Link to={Locations["/reset-password"]}>
                      <span className="flex justify-end underline text-black font-interSemiBold cursor-pointer">
                        {t("Forgot password?")}
                      </span>
                    </Link>
                  </div>
                  <div className="pt-6">
                    <Button
                      className="flex justify-center items-center text-4 w-full py-3 gap-2 font-interSemiBold text-white bg-black hover:bg-gray-dark300 transition duration-200 ease-in-out rounded-md"
                      type="submit"
                      // TODO: Add disable
                    >
                      {t("Sign In")}
                    </Button>
                  </div>
                </form>

                <AuthenticateWithGoogleButton
                  buttonText={t("Sign in with Google")}
                  onClick={newSignInGoogle}
                />

                <div className="flex justify-center items-center space-x-1 text-4">
                  <p className="font-normal">{t("Don't have an account?")}</p>
                  <Link to={Locations["/sign-up"]}>
                    <span className="font-interSemiBold">{t("Sign up")}</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Login;
