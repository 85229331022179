import { Fragment, ReactNode, forwardRef } from "react";
import classNames from "classnames";
import { OptionsObject } from "notistack";
import SelectOriginal from "react-select";
import { StateManagerProps } from "react-select/dist/declarations/src/useStateManager";
import BigNumber from "bignumber.js";
import {
  exchangeRateSymbolPrecisions,
  symbolPrecisions,
} from "utils/numberUtils";
import {
  DestinationBankAccountClassName,
  LiquidityProviders,
  Locations,
  RioSupportedDOCountry,
  Titles,
} from "interfaces/enums";
import { matchPath, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  CircularProgress as CircularProgressM,
  CircularProgressProps,
  InputBase,
  MenuItem,
  StepConnector,
  stepConnectorClasses,
  styled,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { useGetUserQuery } from "api/institutional/user";
import { TextLoader } from "pages/placeOrder/loaders/TextLoader";

import { Text } from "@react-pdf/renderer";
import {
  IBitsoOrder,
  ICcyConversionOrder,
  ICurrencyLabel,
  IDestinationAccountWallet,
  IDestinationBankAccount,
  IDestinationBankAccountMexican,
  IDestinationBankAccountUS,
} from "interfaces";
import {
  IRioCreateBankAccNonUs,
  IRioCreateBankAccUs,
  IRioOrder,
} from "interfaces/rio";
import { ICalizaOrder } from "interfaces/caliza";
import { Tooltip } from "react-tooltip";

import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { Check, ChevronRight } from "@mui/icons-material";

import SelectBootstrap, { SelectChangeEvent } from "@mui/material/Select";
import { emptyValue } from "utils";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {},
  "& .MuiInputBase-input": {
    borderRadius: "0 4px 4px 0px",
    position: "relative",
    backgroundColor: "#232323",
    border: "none",
    fontSize: 14,
    padding: "8px 26px 6px 12px",
    color: "white",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    fontFamily: [
      "interSemiBold",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));

interface KeyValue {
  value: string;
  label: string;
  className?: string;
}

export const CustomSelect = ({
  defaultValue,
  options,
  onChange,
  value,
}: {
  options: Array<KeyValue>;
  value: KeyValue;
  onChange: (s: KeyValue) => void;
  defaultValue: KeyValue;
}) => {
  return (
    <div className="flex w-full justify-between bg-gray-light400 rounded-full">
      {options.map((option, key: number) => (
        <div
          key={key}
          onClick={() => onChange(option)}
          className={`flex w-full text-4 text-black font-interSemiBold justify-center items-center rounded-full cursor-pointer
            ${option.value === (value?.value || value) ? option.className : ""}
          `}
        >
          <span>{option.label}</span>
        </div>
      ))}
    </div>
  );
};

export const Select = (allprops: StateManagerProps) => (
  <SelectGray {...allprops} />
);

export const selecttheme = (theme: any) => ({
  ...theme,
  borderRadius: 0,
  colors: {
    ...theme.colors,
    primary25: "#f9f9f9",
    primary: "#505050",
  },
});

export const selectstyles = {
  control: (props: any) => ({
    ...props,
    border: "none",
    borderRadius: 4,
    cursor: "pointer",
    boxShadow: "none",
    backgroundColor: "#F3F3F5",
    ":hover": {
      border: "1px solid #989898",
    },
    fontWeight: 400,
  }),
  placeholder: (props: any) => ({
    ...props,
    color: "#9c9db0",
  }),
  indicatorSeparator: (props: any) => ({}),
  dropdownIndicator: (props: any) => ({
    ...props,
    color: "505050",
    stroke: "1px",
  }),
  option: (props: any) => ({ ...props, cursor: "pointer" }),
};

export const SelectGray = (allprops: StateManagerProps) => (
  <SelectOriginal theme={selecttheme} styles={selectstyles} {...allprops} />
);

export const FormGroup = ({
  children,
  className = "flex-col",
  hidden = false,
}: {
  children: ReactNode;
  className?: string;
  hidden?: boolean;
}) => (
  <div className={classNames("flex gap-2", hidden && "hidden", className)}>
    {children}
  </div>
);

export const successSnack = {
  autoHideDuration: 2000,
  variant: "success",
  anchorOrigin: {
    vertical: "top",
    horizontal: "center",
  },
} as OptionsObject<"success">;

export const errorSnack = {
  autoHideDuration: 2000,
  variant: "error",
  anchorOrigin: {
    vertical: "top",
    horizontal: "center",
  },
} as OptionsObject<"error">;

export const Input = forwardRef<HTMLInputElement, any>((props, ref: any) => {
  return (
    <>
      <input
        {...props}
        autoComplete="off"
        type={props.type || "text"}
        onKeyDown={(event: any) => {
          const ctrlDown = event.ctrlKey || event.metaKey;

          if (ctrlDown || event.altKey) return true;

          const integer = [
            "ArrowLeft",
            "ArrowRight",
            "Tab",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "0",
            "Backspace",
          ];
          const number = ["."];
          if (
            props.type === "number" &&
            ![...integer, ...number].includes(event.key)
          ) {
            event.preventDefault();
          }

          if (props.type === "integer" && !integer.includes(event.key)) {
            event.preventDefault();
          }
        }}
        ref={ref}
        className={classNames(
          "rounded-sm bg-gray-light100 p-2 px-4 w-full text-4 text-black disabled:text-black",
          props.className,
          props.errors?.[props.name],
        )}
      />
      {props.errors?.[props.name] && (
        <small className="text-black">
          {props.errors?.[props.name].message}
        </small>
      )}
    </>
  );
});

export function DialogSelect({
  value,
  options,
  handleChange,
  disabled,
}: {
  handleChange: (a: SelectChangeEvent<any>) => void;
  value: any;
  disabled?: boolean;
  options: Array<{ label: string; value: string }>;
}) {
  return (
    <SelectBootstrap
      labelId="customized-select-label"
      id="customized-select"
      value={value}
      disabled={disabled}
      onChange={handleChange}
      input={<BootstrapInput />}
      IconComponent={(props) => (
        <ChevronRight
          {...props}
          style={{
            transform: "rotate(90deg)",
            color: "white",
            height: 24,
            width: 24,
          }}
        />
      )}
    >
      {options.map(({ value, label }) => (
        <MenuItem value={value} key={value}>
          {label}
        </MenuItem>
      ))}
    </SelectBootstrap>
  );
}

export const UserName = () => {
  const { data: user, isFetching: isFetchingUser } = useGetUserQuery({});
  return (
    <div className="hidden sm:flex items-center gap-4">
      {isFetchingUser ? (
        <TextLoader />
      ) : (
        <p>
          {user?.name} {user?.last_name}
        </p>
      )}
      <div
        className="w-12 h-12 bg-black rounded-full flex justify-center items-center"
        color="black"
      >
        <span className="text-white">{user?.name.substring(0, 1)}</span>
      </div>
    </div>
  );
};

export const Container = ({
  children,
  title,
  subtitle,
  className,
  withBg,
}: {
  children: ReactNode;
  title?: string;
  subtitle?: string;
  className?: string;
  withBg?: boolean;
}) => {
  return (
    <div className="h-full flex flex-col overflow-auto">
      <div className="flex flex-row-reverse p-4 px-6">
        <UserName />
      </div>
      <div
        className={classNames(
          "p-4 sm:px-8 sm:py-0 flex gap-6 flex-col flex-1",
          className,
        )}
      >
        <div className="hidden sm:flex text-9 font-interSemiBold sm:pb-2 flex-col">
          {title ? title : <GetTitle />}
          {subtitle && (
            <span className={"block text-7 text-gray-dark500"}>{subtitle}</span>
          )}
        </div>
        <div
          className={classNames(
            "rounded-lg flex flex-col gap-4 flex-1",
            withBg ? "bg-white p-4 sm:p-8" : "bg-transparent",
          )}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export const Row = ({
  children,
  className,
  hidden,
}: {
  children: ReactNode;
  className?: string;
  hidden?: boolean;
}) => (
  <div
    className={classNames("gap-2 px-7 text-4", hidden && "hidden", className)}
  >
    {children}
  </div>
);

export const Button = forwardRef<HTMLButtonElement, any>((props, ref) => {
  return (
    <button
      type="button"
      {...props}
      ref={ref}
      className={classNames(
        "bg-[#dcd2fa] py-2 px-4 flex justify-center items-center disabled:border-none disabled:bg-gray-light200 disabled:text-gray-light100",
        props.className,
      )}
    >
      {props.children}
    </button>
  );
});

export const Switch = forwardRef<HTMLInputElement, any>(
  ({ className, ...props }, ref) => (
    <label className="relative inline-flex items-center cursor-pointer">
      <input type="checkbox" className="sr-only peer" {...props} ref={ref} />
      <div
        className={`w-11 h-6 bg-gray-light100 rounded-full peer peer-focus:ring-1 peer-focus:ring-gray-light100  peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] peer-checked:after:bg-[#3856A8] after:bg-gray-light200 after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-gray-light100 after:border-none ${
          className || ""
        }`}
      ></div>
    </label>
  ),
);

export const Tabs = ({
  options,
  setTab,
  tab,
  className,
}: {
  options: any;
  setTab: (index: number) => void;
  tab: number;
  className?: string;
}) =>
  options.map((option: any, index: number) => (
    <button
      key={index}
      onClick={() => setTab(index)}
      className={classNames(
        "items-center",
        tab === index
          ? "border-b border-black text-black "
          : "text-gray-dark200",
        "pb-0 sm:pb-1 font-interSemiBold py-[2px] text-6 bg-transparent",
        className,
      )}
    >
      {option}
    </button>
  ));

export const CurrencyBody = (props: ICurrencyLabel) => {
  return (
    <>
      {props.ignoreSymbolPrecision
        ? BigNumber(props.children).toFormat()
        : BigNumber(props.children).toFormat(
            props.precision === undefined
              ? props.isExchangeRate
                ? exchangeRateSymbolPrecisions(props.symbol)
                : symbolPrecisions(props.symbol)
              : props.precision,
            BigNumber.ROUND_HALF_UP,
          )}
      {props.showSymbol ? ` ${props.symbol}` : ""}
    </>
  );
};

export const CurrencyLabel = ({
  children = BigNumber(0),
  ...rest
}: ICurrencyLabel) => {
  if (children === 0) return <>--</>;

  if (BigNumber(children).isNaN()) return null;

  return (
    <label
      title={BigNumber(children).toFormat()}
      style={{ cursor: "context-menu" }}
    >
      <CurrencyBody {...rest}>{children}</CurrencyBody>
    </label>
  );
};

export const PDFCurrencyLabel = ({
  children = BigNumber(0),
  ...rest
}: ICurrencyLabel) => {
  if (children === 0) return <>--</>;

  if (BigNumber(children).isNaN()) return null;

  return (
    <Text>
      <CurrencyBody {...rest}>{children}</CurrencyBody>
    </Text>
  );
};

export const GetTitle = () => {
  const pathname = useLocation().pathname as Locations;
  const { t } = useTranslation();

  const title = Object.keys(Titles).find((t) =>
    matchPath(t, pathname),
  ) as Locations;

  return <>{t(Titles[title])}</>;
};

export const CircularProgress = (props: CircularProgressProps = {}) => {
  return <CircularProgressM {...props}></CircularProgressM>;
};

export const PlaceOrderGrid = ({ children }: { children: any }) => (
  <div className={`grid gap-8 font-unica77LL col-span-12 sm:col-span-11`}>
    {children}
  </div>
);

export const PlaceOrderRow = ({
  children,
  cols = 12,
}: {
  children: any;
  cols?: number;
}) => (
  <div className="flex flex-col gap-8 w-full py-2">
    <div className={`grid grid-cols-1 sm:grid-cols-${cols} gap-8`}>
      {children}
    </div>
  </div>
);

export const PlaceOrderColumn = ({
  children,
  title,
  tooltipContent,
  showTooltip,
  colSpan = 10,
}: {
  colSpan: number;
  title?: string;
  children?: any;
  tooltipContent?: string;
  showTooltip?: boolean;
}) => (
  <div className={`flex flex-col gap-4 col-span-${colSpan}`}>
    <div className="flex gap-2 px-7 items-center">
      {title && <span className="font-interSemiBold text-5">{title}</span>}
      {showTooltip && (
        <>
          <div
            data-tooltip-id="information-icon"
            data-tooltip-content={tooltipContent}
            data-tooltip-place="top"
            className="cursor-pointer"
          >
            <InfoIcon fontSize="small" sx={{ color: "#76717A" }} />
          </div>
          <Tooltip style={{ maxWidth: "20rem" }} id="information-icon" />
        </>
      )}
    </div>
    <>{children || emptyValue} </>
  </div>
);

export const ReadonlyComponent = ({
  isReadMode,
  r,
  w,
}: {
  isReadMode: boolean;
  r?: any;
  w?: any;
}) => {
  return isReadMode ? r : w;
};

export const IconWithLabel = ({
  children,
  className = "",
}: {
  children: ReactNode;
  className?: string;
}) => {
  return (
    <div className={`flex gap-2 items-center ${className}`}>{children}</div>
  );
};

const noop = () => {};

export const DestinationProviderComponent = ({
  destination,
  SPID = noop,
  UsDomesticWire = noop,
  CryptoWallet = noop,
}: {
  [DestinationBankAccountClassName.SPID]?: (
    destination: IDestinationBankAccountMexican,
  ) => any;
  [DestinationBankAccountClassName.UsDomesticWire]?: (
    destination: IDestinationBankAccountUS,
  ) => any;
  [DestinationBankAccountClassName.CryptoWallet]?: (
    destination: IDestinationAccountWallet,
  ) => any;
  destination: IDestinationBankAccount;
}) => {
  if (!destination) return null;
  switch (destination.class_name) {
    case DestinationBankAccountClassName.SPID:
      return <>{SPID(destination as IDestinationBankAccountMexican)}</>;
    case DestinationBankAccountClassName.UsDomesticWire:
      return <>{UsDomesticWire(destination as IDestinationBankAccountUS)}</>;
    case DestinationBankAccountClassName.CryptoWallet:
      return <>{CryptoWallet(destination as IDestinationAccountWallet)}</>;
  }
};

export const LiquidityProviderComponent = ({
  order,
  BITSO = noop,
  RIO = noop,
  CALIZA = noop,
}: {
  [LiquidityProviders.BITSO]?: (order: IBitsoOrder) => any;
  [LiquidityProviders.RIO]?: (order: IRioOrder) => any;
  [LiquidityProviders.CALIZA]?: (order: ICalizaOrder) => any;
  order: ICcyConversionOrder;
}) => {
  if (!order) return null;
  switch (order.liquidity_provider) {
    case LiquidityProviders.BITSO:
      return <>{BITSO(order as IBitsoOrder)}</>;
    case LiquidityProviders.RIO:
      return <>{RIO(order as IRioOrder)}</>;
    case LiquidityProviders.CALIZA:
      return <>{CALIZA(order as ICalizaOrder)}</>;
  }
};

export const RioBankAccountProvider = ({
  bankAccount,
  MX = noop,
  US = noop,
}: {
  [RioSupportedDOCountry.US]?: (bankAccount: IRioCreateBankAccUs) => any;
  [RioSupportedDOCountry.MX]?: (bankAccount: IRioCreateBankAccNonUs) => any;
  bankAccount: any;
}) => {
  if (!bankAccount) return null;
  if (bankAccount?.country === "MX") {
    return <>{MX(bankAccount as IRioCreateBankAccNonUs)}</>;
  } else {
    return <>{US(bankAccount as IRioCreateBankAccUs)}</>;
  }
};

export const NewStepConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 14px)",
    right: "calc(50% + 14px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#9D9EA3",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#9D9EA3",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#9D9EA3",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

export const NewStepIconRoot = styled("div")<{
  ownerState: { active?: boolean };
}>(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#9D9EA3",
  display: "flex",
  height: 22,
  fontWeight: "bolder",
  alignItems: "center",
  ...(ownerState.active && {
    color: "#9D9EA3",
  }),
  "& .QontoStepIcon-completedIcon": {
    zIndex: 1,
    fontSize: 18,
  },
  "& .QontoStepIcon-circle": {
    width: 16,
    height: 16,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
  "& .QontoStepIcon-circle-completed": {
    width: 24,
    height: 24,
    borderRadius: "50%",
    backgroundColor: "#70a033",
    padding: "3px",
    border: "solid 1px #70a033",
    backgroundClip: "content-box",
  },
}));

export const GrayDivider = () => (
  <div className="bg-gray-light400 w-full h-full rounded-lg"></div>
);

export const CenterCircleLoader = () => (
  <div className="pb-8 pt-4 h-full">
    <div className="flex justify-center items-center h-full">
      <CircularProgress style={{ color: "black" }} />
    </div>
  </div>
);

export const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion
    elevation={0}
    square
    sx={{
      "&:before": {
        display: "none",
      },
    }}
    {...props}
  />
))(({ theme }) => ({
  background: "white",
  borderRadius: "8px",
  "&.Mui-disabled": {
    backgroundColor: "white",
  },
}));

export const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  background: "white",
  borderRadius: "12px",
  paddingLeft: "24px",
  paddingRight: "24px",
  paddingTop: "12px",
  paddingBottom: "12px",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
    border: "none",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

export const CompletedStep = () => (
  <div className="h-6 w-6 bg-green rounded-full flex">
    <Check className="text-white p-1"></Check>
  </div>
);

export const NextStep = () => (
  <div className="h-6 w-6 bg-gray rounded-full border-double border-white border-4"></div>
);

export const CurrentStep = () => (
  <div className="h-6 w-6 bg-blue rounded-full border-double border-white border-4"></div>
);

export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  paddingLeft: "40px",
  paddingRight: "40px",
  paddingBottom: "40px",
  paddingTop: "0px",
}));

export const AccordionStep = (step: {
  completed: boolean;
  label: string;
  disabled: boolean;
}) => {
  const body = () => {
    if (step.completed) {
      return <CompletedStep />;
    }
    if (step.disabled) {
      return (
        <div className="h-6 w-6 bg-gray-dark100 border border-gray-dark500 rounded-full"></div>
      );
    }
    return <CurrentStep />;
  };

  return (
    <div className="flex gap-4">
      {body()}
      <div>
        <span className="font-interSemiBold text-5">{step.label}</span>
      </div>
    </div>
  );
};

export const Grid = ({
  children,
}: {
  children: Array<{
    label: string | ReactNode;
    component: any;
    hidden?: boolean;
  }>;
}) => {
  const length = children.filter((c) => !c.hidden)?.length;

  return (
    <div className={`grid grid-cols-10 rounded-md`}>
      {children.map((c, index) =>
        c.hidden ? null : (
          <Fragment key={`${c.label}-${index}`}>
            <div
              className={`flex flex-col col-span-4 gap-4 py-4 px-0 sm:px-7 text-4  justify-center font-interSemiBold  ${
                length - 1 > index ? "border-b" : ""
              } border-gray-light100`}
            >
              {c.label}
            </div>
            <div
              className={`flex flex-col col-span-6 gap-4 text-4 p-3 justify-center px-0 sm:px-7 ${
                length - 1 > index ? "border-b" : ""
              } border-gray-light100`}
            >
              {c.component}
            </div>
          </Fragment>
        ),
      )}
    </div>
  );
};

export const AccordionItem = ({
  title,
  removeDivider,
  explanation,
  children,
}: {
  title: string;
  explanation?: string;
  removeDivider?: boolean;
  children: any;
}) => {
  return (
    <>
      <div className="flex gap-0 flex-col col-span-12 sm:col-span-5 justify-center">
        <span className="text-5 font-interSemiBold">{title}</span>
        <span className="text-3 font-interSemiBold text-newTheme-muted ">
          {explanation}
        </span>
      </div>
      <div className="col-span-12 sm:col-span-7 font-inter self-center">
        {children}
      </div>
      {removeDivider ? null : (
        <div className="col-span-12 py-5">
          <hr className="w-full border-newTheme-grayish"></hr>
        </div>
      )}
    </>
  );
};

export const LoadingComponent = ({
  isRefetching,
  children,
  fetchingLabel,
}: {
  isRefetching: boolean;
  fetchingLabel?: any;
  children: any;
}) => {
  if (isRefetching) return <span>{fetchingLabel || emptyValue}</span>;
  return children;
};

export const EmptyComponent = ({
  children,
  value,
}: {
  value: any;
  children: any;
}) => {
  if (value) return children;
  return null;
};

export const RowGap = ({ children }: { children: ReactNode }) => {
  return <div className="flex flex-row gap-2">{children}</div>;
};

export const RowItem = ({ left, right }: { left: string; right: any }) => {
  return (
    <Row>
      <div className="flex justify-between items-center gap-1">
        <span className="text-3">{left}:</span>
        <span className="text-3">{right ? right : emptyValue}</span>
      </div>
    </Row>
  );
};
