import React from "react";
import { FlagEnum, Locations, Roles } from "interfaces/enums";
import { Article, AccountBalance, HowToReg } from "@mui/icons-material";
import {
  AdminDashboardIcon,
  DashboardIcon,
  ExchangesIcon,
  ExecutionIcon,
  MMIcon,
  OrdersIcon,
} from "assets/icons/MenuIcons";

import { ISidebarMenuOptions } from "interfaces";

export const MenuOptions = [
  {
    icon: DashboardIcon,
    route: Locations["/dashboard"],
    showTo: [FlagEnum.institutional],
    requiresPorfolio: true,
  },
  {
    icon: ExecutionIcon,
    route: Locations["/place-order"],
    showTo: [FlagEnum.institutional],
    requiresPorfolio: true,
  },
  {
    icon: ExecutionIcon,
    route: Locations["/ccy-conversion/place-order-caliza"],
    showTo: [FlagEnum.ccy_conversion_client_caliza],
    // requiresPorfolio: true,
  },
  {
    icon: MMIcon,
    route: Locations["/ccy-conversion/mm"],
    showTo: [FlagEnum.bitso_odl_mm, FlagEnum.bitso_odl_treasury],
  },
  {
    icon: ExecutionIcon,
    route: Locations["/ccy-conversion/place-order"],
    showTo: [FlagEnum.bitso_odl_client],
  },
  {
    icon: ExecutionIcon,
    route: Locations["/ccy-conversion/place-order-rio"],
    showTo: [FlagEnum.ccy_conversion_client_rio],
  },
  {
    icon: ExecutionIcon,
    route: Locations["/ccy-conversion/place-order-rio-broker"],
    showTo: [
      FlagEnum.ccy_conversion_broker_rio,
      FlagEnum.ccy_conversion_guest_broker_rio,
    ],
  },
  {
    route: Locations["/ccy-conversion/order-caliza/:id"],
    showTo: [FlagEnum.ccy_conversion_client_caliza],
    hideOnNavBar: true,
    hideOnMobile: true,
  },
  {
    route: Locations["/ccy-conversion/order-rio/:id"],
    showTo: [
      FlagEnum.ccy_conversion_client_rio,
      FlagEnum.ccy_conversion_broker_rio,
    ],
    hideOnNavBar: true,
    hideOnMobile: true,
  },
  {
    icon: OrdersIcon,
    route: Locations["/orders"],
    showTo: [FlagEnum.institutional],
    requiresPorfolio: true,
  },
  {
    icon: OrdersIcon,
    route: Locations["/ccy-conversion/orders"],
    showTo: [
      FlagEnum.bitso_odl_client,
      FlagEnum.ccy_conversion_client_rio,
      FlagEnum.ccy_conversion_broker_rio,
      FlagEnum.ccy_conversion_client_caliza,
    ],
  },
  {
    route: Locations["/ccy-conversion/order/:id"],
    showTo: [FlagEnum.bitso_odl_client],
    hideOnNavBar: true,
    hideOnMobile: true,
  },
  {
    icon: (props: any) => <AccountBalance {...props} />,
    route: Locations["/ccy-conversion/destination-bank-accounts"],
    showTo: [FlagEnum.bitso_odl_client],
    hideOnMobile: true,
  },
  {
    icon: (props: any) => <AccountBalance {...props} />,
    route: Locations["/ccy-conversion/destination-bank-accounts-rio"],
    showTo: [
      FlagEnum.ccy_conversion_client_rio,
      FlagEnum.ccy_conversion_broker_rio,
    ],
    hideOnMobile: true,
  },
  {
    icon: ExchangesIcon,
    route: Locations["/exchanges"],
    showTo: [FlagEnum.institutional, FlagEnum.ccy_conversion_crypto_enabled],
  },
  {
    icon: (props: any) => <Article {...props} />,
    route: Locations["/documentation"],
    hideOnMobile: true,
    showTo: [FlagEnum.institutional],
    requiresPorfolio: true,
  },
  {
    icon: AdminDashboardIcon,
    route: Locations["/admin"],
    role: Roles.admin,
    showTo: [FlagEnum.institutional],
    hideOnMobile: true,
  },
  {
    icon: (props: any) => <HowToReg {...props} />,
    route: Locations["/send-onboarding"],
    showTo: [FlagEnum.send_onboarding],
    hideOnMobile: true,
  },
] as Array<ISidebarMenuOptions>;
