import React, { useState } from "react";
import { Link, Navigate } from "react-router-dom";

import { useAuth } from "hooks/useAuth";
import RidianLogo from "components/RidianLogo";
import { AuthenticateWithGoogleButton } from "components/AuthenticateWithGoogleButton";
import { useTranslation } from "react-i18next";
import { Button, Input, errorSnack } from "components/Shared";

import { useSnackbar } from "notistack";

import { Locations } from "interfaces/enums";
import SignUpLangSelector from "components/SignUpLangSelector";
import { VerifyEmail } from "components/VerifyEmail";
import { ISignUpForm } from "interfaces";
import { useForm } from "react-hook-form";
import Recaptcha from "components/Recaptcha";

const SignUp = () => {
  const { user, newSignInGoogle, newSignUpPassword } = useAuth();

  const [showVerify, setShowVerify] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { isValid },
  } = useForm<ISignUpForm>();

  register("recaptcha", { required: true });

  const submitSignUp = async (values: ISignUpForm) => {
    try {
      await newSignUpPassword({
        firstName: values.first_name,
        lastName: values.last_name,
        phone: "",
        email: values.email,
        password: values.password,
        recaptcha: values.recaptcha,
      });
      setShowVerify(true);
    } catch (err: any) {
      console.error(err);
      enqueueSnackbar(
        err.data.detail || t("An error occurred. Please notifiy Ridian."),
        errorSnack,
      );
    }
  };

  if (showVerify) {
    return <VerifyEmail />;
  }

  return (
    <>
      {user ? (
        <Navigate to={Locations["/"]} replace={true} />
      ) : (
        <div className="p-4 sm:px-8 sm:py-0 flex gap-6 flex-col">
          <div className="justify-end absolute end-10 top-6 hidden sm:flex">
            <SignUpLangSelector theme="light" />
          </div>
          <div className="bg-white h-full w-full">
            <div className="fixed p-8">
              <RidianLogo />
            </div>
            <div className="flex flex-col pt-[6rem] justify-center items-center w-full">
              <div className="flex flex-col gap-6">
                <p className="text-8 font-ridianCubic font-interSemiBold text-left">
                  {t("Sign Up")}
                </p>
                <p className="text-gray-dark300 font-interSemiBold">
                  {t("Institutional Ridian Sign Up")}
                </p>
                <AuthenticateWithGoogleButton
                  buttonText={t("Continue with Google")}
                  onClick={newSignInGoogle}
                />
                <div className="flex items-center gap-4">
                  <hr className="grow" />
                  {t("o")}
                  <hr className="grow" />
                </div>
                {/* Sign in Form */}
                <form onSubmit={handleSubmit(submitSignUp)}>
                  <div className="flex space-x-4">
                    <Input
                      type="first_name"
                      placeholder={t("First Name")}
                      bgColor="white"
                      className="border-b border-gray-dark200 text-5 hover:border-black focus:border-black hover:shadow-[inset_0_-1.3px_0_0_black] focus:shadow-[inset_0_-1.3px_0_0_black] placeholder-gray-dark100"
                      {...register("first_name", { required: true })}
                    />
                    <Input
                      type="last_name"
                      placeholder={t("Last Name")}
                      bgColor="white"
                      className="border-b border-gray-dark200 text-5 hover:border-black focus:border-black hover:shadow-[inset_0_-1.3px_0_0_black] focus:shadow-[inset_0_-1.3px_0_0_black] placeholder-gray-dark100"
                      {...register("last_name", { required: true })}
                    />
                  </div>
                  <div className="pt-6">
                    <Input
                      type="email"
                      placeholder={t("Email")}
                      bgColor="white"
                      className="border-b border-gray-dark200 text-5 hover:border-black focus:border-black hover:shadow-[inset_0_-1.3px_0_0_black] focus:shadow-[inset_0_-1.3px_0_0_black] placeholder-gray-dark100"
                      {...register("email", { required: true })}
                    />
                  </div>
                  <div className="pt-6">
                    <Input
                      type="password"
                      placeholder={t("Password")}
                      bgColor="white"
                      className="border-b border-gray-dark200 text-5 hover:border-black focus:border-black hover:shadow-[inset_0_-1.3px_0_0_black] focus:shadow-[inset_0_-1.3px_0_0_black] placeholder-gray-dark100"
                      {...register("password", { required: true })}
                    />
                  </div>
                  <div className="flex justify-center items-center pt-8">
                    <Recaptcha
                      onExpired={() =>
                        setValue("recaptcha", "", { shouldValidate: true })
                      }
                      onChange={(value: any) =>
                        setValue("recaptcha", value, { shouldValidate: true })
                      }
                    />
                  </div>
                  <div className="pt-8">
                    <Button
                      className="flex justify-center items-centertext-4 w-full py-3 gap-2 font-interSemiBold text-white bg-black hover:bg-gray-dark300 transition duration-200 ease-in-out rounded-md"
                      disabled={!isValid}
                      type="submit"
                    >
                      {t("Sign Up")}
                    </Button>
                  </div>
                </form>

                <div className="flex justify-center items-center space-x-1 text-4">
                  <p className="font-normal">{t("Already have an account?")}</p>
                  <Link to="/login">
                    <span className="font-interSemiBold">{t("Sign in")}</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SignUp;
