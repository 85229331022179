import React from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "hooks/useAuth";
import { useSnackbar } from "notistack";
import {
  useResendEmailMutation,
  useLazyVerifyUserQuery,
} from "api/institutional/verify";
import { useLocation, Navigate } from "react-router-dom";
import SignUpLangSelector from "../SignUpLangSelector";
import RidianLogo from "../RidianLogo";
import { Button, Input } from "components/Shared";
import { IVerifyEmailForm } from "interfaces";
import { useForm } from "react-hook-form";

export const VerifyEmail = () => {
  const { t } = useTranslation();

  const { enqueueSnackbar } = useSnackbar();

  const { firebaseUser, user, reload } = useAuth();

  const { search } = useLocation();

  const { register, watch, handleSubmit } = useForm<IVerifyEmailForm>();

  // Watches
  const code = watch("code");

  const [resend] = useResendEmailMutation();

  const [trigger, { isLoading }] = useLazyVerifyUserQuery();

  const handleVerify = async () => {
    const result = await trigger({
      code: code,
      token: firebaseUser?.accessToken,
    });
    if (result.data[0].success) {
      reload();
    }
  };

  const handleResend = async () => {
    await resend({ token: firebaseUser.stsTokenManager.accessToken });
    enqueueSnackbar(t("Resent Email"), {
      autoHideDuration: 5000,
      variant: "success",
      anchorOrigin: {
        vertical: "top",
        horizontal: "center",
      },
    });
  };

  const cantSubmit = !code || isLoading;

  if (user && user.emailVerified) return <Navigate replace to="/" />;

  return (
    <div className="p-4 sm:px-8 sm:py-0 flex gap-6 flex-col">
      <div className="justify-end absolute end-10 top-6 hidden sm:flex">
        <SignUpLangSelector theme="light" />
      </div>
      <div className="bg-white h-full w-full">
        <div className="fixed p-8">
          <RidianLogo />
        </div>
        <div className="flex flex-col pt-[8rem] justify-center items-center w-full">
          <div className="flex flex-col gap-6">
            <p className="text-8 font-ridianCubic font-interSemiBold text-left">
              {t("Verify Email")}
            </p>
            <p>
              {t("We have sent the verification code to {{email}}", {
                email: search?.split("=").pop(),
              })}
            </p>
            {/* Sign in Form */}
            <form onSubmit={handleSubmit(handleVerify)}>
              <div className="">
                <Input
                  type="text"
                  placeholder={t("Code")}
                  bgColor="white"
                  className="border-b border-gray-dark200 text-5 hover:border-black focus:border-black hover:shadow-[inset_0_-1.3px_0_0_black] focus:shadow-[inset_0_-1.3px_0_0_black] placeholder-gray-dark100"
                  {...register("code", { required: true })}
                />
              </div>

              {/* TODO: Add Forgot password link */}
              <div className="pt-6">
                <p
                  onClick={handleResend}
                  className="flex justify-end underline text-black font-interSemiBold cursor-pointer"
                >
                  {t("Resend Email")}
                </p>
              </div>
              <div className="pt-6">
                <Button
                  className="flex justify-center items-center text-4 w-full md:px-32 py-3 gap-2 font-interSemiBold text-white bg-black hover:bg-gray-dark300 transition duration-200 ease-in-out rounded-md"
                  type="submit"
                  disabled={cantSubmit}
                >
                  {isLoading ? t("Verifying Email..") : t("Verify Email")}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmail;
