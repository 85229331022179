import { getPath } from "api/customFetchBase";
import { removeUploading, setUploading } from "app/slices/globalSlice";
import { errorSnack } from "components/Shared";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { v4 } from "uuid";

export const useUploader = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const upload = async (url: string, files: [File], category?: string) => {
    const key = v4();
    const formData = new FormData();
    const file = files[0];
    formData.append("file", file, file.name);
    try {
      if (category) {
        dispatch(setUploading({ category }));
      }
      setIsLoading(true);
      setError("");
      enqueueSnackbar(
        t("Uploading {{name}}", {
          name: file.name,
        }),
        { key, persist: true },
      );
      const response = await fetch(`${getPath("institutional")}${url}`, {
        method: "POST",
        body: formData,
        redirect: "follow",
      });

      const r = await response.json();
      return r;
    } catch (e: any) {
      setError(e.message);
      enqueueSnackbar(
        t("Error uploading file {{file}}", { file: file.name }),
        errorSnack,
      );
    } finally {
      setIsLoading(false);
      closeSnackbar(key);
      if (category) {
        dispatch(removeUploading({ category }));
      }
    }
  };

  return { upload, isLoading, error };
};
