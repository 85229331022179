import React from "react";
import ContentLoader from "react-content-loader";

export const ButtonLoader = ({
  items = 3,
  height = 150,
  width = 200,
  backgroundColor = "#F3F3F5",
  foregroundColor = "#ECECEC",
}) => {
  const itemHeight = height / items - 10;
  return (
    <ContentLoader
      viewBox={`0 0 ${width} ${height}`}
      backgroundColor={backgroundColor}
      foregroundColor={foregroundColor}
      height={height}
      width={width}
    >
      {Array.from({ length: items }).map((_, index) => (
        <rect
          key={index}
          x={12}
          y={index * (itemHeight + 10) + 24}
          height={itemHeight}
          width={width - 20}
          rx="2"
          ry="2"
        />
      ))}
    </ContentLoader>
  );
};
