import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import UploadFile from "@mui/icons-material/CloudUploadOutlined";
import { Downloading } from "@mui/icons-material";

export function DropZoneComponent({
  label,
  doDrop,
  disable,
  hidden,
  category,
}: {
  hidden?: boolean;
  label?: string;
  category: string;
  disable?: boolean;
  doDrop: (files: any, category: string) => void;
}) {
  const onDrop = (acceptedFiles: any) => {
    doDrop(acceptedFiles, category);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    disabled: disable,
    multiple: false,
  });

  const { t } = useTranslation();

  return (
    <div
      hidden={hidden}
      {...getRootProps({
        className: `dropzone cursor-pointer w-full ${
          disable ? "cursor-not-allowed" : ""
        }`,
      })}
    >
      <section className="container bg-[#F9FAFB] border-dashed border p-2 text-center flex items-center justify-center rounded-md border-[#d4d4d4] w-full shadow-md">
        <input {...getInputProps()} />
        <div className="flex gap-2 items-center">
          {disable ? (
            <Downloading
              className="text-[#9A9999] rotate-180"
              fontSize={"small"}
            />
          ) : (
            <UploadFile className="text-[#9A9999]" fontSize={"small"} />
          )}
          <span className="text-[#9A9999] text-2 font-semibold py-8">
            {label ?? t("Cargar documento")}
          </span>
        </div>
      </section>
    </div>
  );
}

export default DropZoneComponent;
