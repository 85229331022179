import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import SvgGlobe from "assets/icons/Globe";
import SvgChevronLeft from "assets/icons/ChevronLeft";
import useClickAway from "hooks/useClickAway";

const SignUpLangSelector = ({ theme = "dark" }) => {
  const { i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const ref = useRef();
  const options = ["es", "en"];
  useClickAway(ref, () => setOpen(false));

  return (
    <div className={`relative dark`} ref={ref}>
      <div
        className="py-1 px-2 rounded flex items-center gap-2 cursor-pointer bg-gray-light400 hover:bg-gray-dark400 text-black"
        onClick={() => setOpen(!open)}
      >
        <div className="gap-1 flex items-center">
          <SvgGlobe color="black" />
          <small className="font-interSemiBold uppercase">
            {i18n.language.split("-").pop()}
          </small>
        </div>
        <SvgChevronLeft color="black" className="-rotate-90" />
      </div>
      {open && (
        <div
          className="absolute right-0 z-10 mt-2 origin-top-right rounded-md w-[100%] min-[1000px] bg-gray-light400"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabIndex="-1"
        >
          {options?.map((option, index) => (
            <div
              onClick={() => {
                setOpen(false);
                i18n.changeLanguage(option);
              }}
              key={index}
              className="cursor-pointer block px-4 py-2 text-3 font-interSemiBold uppercase rounded-md hover:bg-gray-dark400"
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SignUpLangSelector;
