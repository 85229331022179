import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import {
  Button,
  Input,
  errorSnack,
  selectstyles,
  selecttheme,
} from "components/Shared";
import { CreateDestinationBankAccountSnack } from "components/Shared/Snacks";
import { enqueueSnackbar } from "notistack";
import { Tooltip } from "react-tooltip";
import { IRioCreateBankAccUs } from "interfaces/rio";
import { useCreateBankAccountUSMutation } from "api/institutional/rio";
import {
  RioSupportedFiat,
  RioSupportedCountry,
  RioSupportedDOCountry,
} from "interfaces/enums";
import { valueLabelMapper } from "utils";
import { getBankOptions } from "utils/getBankOptions";
import CreatableSelect from "react-select/creatable";

export const USAccount = ({
  handleClose,
  subAccountId,
  country,
  accountName,
}: {
  handleClose: () => void;
  subAccountId: string;
  country: RioSupportedDOCountry;
  accountName: string;
}) => {
  const bankNameOptions = getBankOptions(RioSupportedCountry.US);

  const { t } = useTranslation();

  const submitAddBankAccount = async (values: IRioCreateBankAccUs) => {
    try {
      // Define parameters
      await addUsBankAccount({
        ...values,
        sub_account_id: subAccountId,
        country: RioSupportedCountry.US,
        account_name: accountName,
        fiat: RioSupportedFiat.USD,
      }).unwrap();

      // Reset Fields
      reset();

      enqueueSnackbar(<CreateDestinationBankAccountSnack />);

      handleClose();
    } catch (error: any) {
      enqueueSnackbar(
        error || t("An error occurred. Please notifiy Ridian."),
        errorSnack,
      );
    }
  };
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { isValid, errors },
  } = useForm<IRioCreateBankAccUs>({
    mode: "all",
    resetOptions: {
      keepIsValid: false,
    },
    defaultValues: {
      country: RioSupportedCountry.US,
      fiat: RioSupportedFiat.USD, // USD for SPID Transfers
    },
  });

  const [addUsBankAccount, { isLoading: isAddingBankAccountNonUs }] =
    useCreateBankAccountUSMutation();
  return (
    <form
      onSubmit={handleSubmit(submitAddBankAccount)}
      className="gap-5 flex flex-col"
    >
      {/* Bank Name */}
      <div>
        <p className=" text-4 font-interSemiBold">{t("Bank Name")}:</p>
        <CreatableSelect
          theme={selecttheme}
          styles={selectstyles}
          options={valueLabelMapper(bankNameOptions)} //[{label: , value: }]
          onChange={(e: any) => {
            setValue("bank_name", e.value);
          }}
          formatCreateLabel={(prop) => `${t("Bank")}: ${prop}`}
          placeholder={t("Select an option")}
        />
      </div>

      {/* Number */}
      <div>
        <p className="text-4 font-interSemiBold">{t("Account Number")}</p>
        <Input
          type="text"
          placeholder={t("Enter the account number")}
          bgColor="gray-light102"
          className="shadow-sm"
          errors={errors}
          {...register("account_number", {
            required: true,
            onBlur: () =>
              setValue("account_number", getValues("account_number")?.trim()),
          })}
        />
      </div>

      {/* Number */}
      <div>
        <p className="text-4 font-interSemiBold">{t("Routing Number")}</p>
        <Input
          type="text"
          placeholder={t("Enter the routing number")}
          bgColor="gray-light102"
          className="shadow-sm"
          errors={errors}
          {...register("routing_number", {
            required: true,
            onBlur: () =>
              setValue("routing_number", getValues("routing_number")?.trim()),
          })}
        />
      </div>

      {/* Number */}

      <div>
        <p className="text-4 font-interSemiBold">{t("Street 1")}</p>
        <Input
          type="text"
          placeholder={t("Enter the street 1")}
          bgColor="gray-light102"
          className="shadow-sm"
          errors={errors}
          {...register("street_1", {
            required: true,
            onBlur: () => setValue("street_1", getValues("street_1")?.trim()),
          })}
        />
      </div>

      <div>
        <p className="text-4 font-interSemiBold">{t("Street 2")}</p>
        <Input
          type="text"
          placeholder={t("Enter the street 1")}
          bgColor="gray-light102"
          className="shadow-sm"
          errors={errors}
          {...register("street_2", {
            required: true,
            onBlur: () => setValue("street_2", getValues("street_2")?.trim()),
          })}
        />
      </div>

      <div className="flex gap-4">
        <div className="flex-1">
          <p className="text-4 font-interSemiBold">{t("City")}</p>
          <Input
            type="text"
            placeholder={t("Enter the city")}
            bgColor="gray-light102"
            className="shadow-sm"
            errors={errors}
            {...register("city", {
              required: true,
              onBlur: () => setValue("city", getValues("city")?.trim()),
            })}
          />
        </div>

        <div className="flex-1">
          <p className="text-4 font-interSemiBold">{t("State")}</p>
          <Input
            type="text"
            placeholder={t("Enter the state")}
            bgColor="gray-light102"
            className="shadow-sm"
            errors={errors}
            {...register("state", {
              required: true,
              onBlur: () => setValue("state", getValues("state")?.trim()),
            })}
          />
        </div>
      </div>

      <div>
        <p className="text-4 font-interSemiBold">{t("Postal Code")}</p>
        <Input
          type="text"
          placeholder={t("Enter the postal code")}
          bgColor="gray-light102"
          className="shadow-sm"
          errors={errors}
          {...register("postal_code", {
            required: true,
            onBlur: () =>
              setValue("postal_code", getValues("postal_code")?.trim()),
          })}
        />
      </div>

      <div>
        <Button
          className="bg-blue hover:bg-blue-dark100 rounded-full text-white text-4 py-2 px-6 items-center"
          type="submit"
          disabled={isAddingBankAccountNonUs || !isValid || !accountName}
        >
          {isAddingBankAccountNonUs
            ? t("Adding Destination Account")
            : t("Add Destination Account")}
        </Button>
        <Tooltip style={{ maxWidth: "20rem" }} id="validation-message" />
        {/* </div> */}
      </div>
    </form>
  );
};
