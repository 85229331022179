import { ReactComponent as RLogo } from "assets/svg/R.svg";
import {
  ExitToApp,
  Person,
  Language,
  Article,
  AccountBalance,
} from "@mui/icons-material";
import { Close } from "@mui/icons-material";
import { useAuth } from "hooks/useAuth";
import { useGetUserQuery } from "api/institutional/user";
import { MobileLangSelector } from "../LangSelector";
import { AdminDashboardIcon, ExchangesIcon } from "assets/icons/MenuIcons";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { FlagEnum, Locations, exchangeOptionsAsId } from "interfaces/enums";

export const MobileMenuPage = ({ handleClose }: { handleClose: any }) => {
  const { logout } = useAuth();

  const { data: user } = useGetUserQuery({});

  const isAdminUser = user?.is_admin;

  const isClientUser = user?.flags.includes(FlagEnum.bitso_odl_client);

  const isInstitutionalUser = user?.flags.includes(FlagEnum.institutional);

  const { t } = useTranslation();

  const navigate = useNavigate();

  const goToAdmin = () => {
    handleClose();
    navigate(Locations["/admin"]);
  };

  const handleLogout = () => {
    handleClose();
    logout();
  };

  return (
    <div className="h-[100%] bg-white w-[100%] fixed top-0 start-0 z-10 p-5">
      <div className="flex flex-col justify-around h-full">
        <div className="flex flex-col h-full">
          <div>
            <div className="flex flex-row justify-between items-center">
              <div className="flex">
                <RLogo />
              </div>
              <div onClick={handleClose} className="p-2 cursor-pointer">
                <Close />
              </div>
            </div>
          </div>
          <div className="pt-10 flex flex-col gap-10 items-start flex-grow">
            <div className="flex gap-3 align-middle justify-center">
              <Person />
              <span className="text-6">{user?.email}</span>
            </div>

            {(isAdminUser || isInstitutionalUser) &&
              user?.portfolio?.broker_id && (
                <div className="flex gap-3 align-middle justify-center">
                  <ExchangesIcon color="black" />
                  <span className="text-6">
                    {exchangeOptionsAsId[user.portfolio.broker_id]}
                  </span>
                </div>
              )}

            {isClientUser && (
              <Link
                to={Locations["/ccy-conversion/destination-bank-accounts"]}
                className="flex gap-3 align-middle justify-center"
              >
                <AccountBalance style={{ color: "black" }} />
                <span className="text-6">{t("Destination Bank Accounts")}</span>
              </Link>
            )}

            {(isAdminUser || isInstitutionalUser) && (
              <Link
                to={Locations["/documentation"]}
                className="flex gap-3 align-middle justify-center"
              >
                <Article />
                <span className="text-6">{t("Documentation")}</span>
              </Link>
            )}

            {isAdminUser && (
              <button
                onClick={goToAdmin}
                className="cursor-pointer flex gap-3 align-middle underline w-full "
              >
                <AdminDashboardIcon color="black" />
                <span className="text-6">{t("Admin")}</span>
              </button>
            )}

            <div className="flex items-center w-full">
              <Language />
              <MobileLangSelector />
            </div>
          </div>
        </div>
        <div>
          <button
            onClick={handleLogout}
            className="hover:cursor-pointer flex gap-3 align-middle w-full "
          >
            <ExitToApp />
            <span className="text-6">{t("Logout")}</span>
          </button>
        </div>
      </div>
    </div>
  );
};
