/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    loading: true,
    isLoading: false,
    token: "",
    error: "",
    firebaseUser: null,
  },
  reducers: {
    refreshToken: (state, action) => {
      state.token = action.payload.token;
    },
    setUser: (state, action) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
      state.loading = false;
    },
    setFirebaseUser: (state, action) => {
      state.firebaseUser = action.payload.firebaseUser;
      state.loading = false;
    },
    setError: (state, action) => {
      state.error = action.payload.error;
    },
    setLoading: (state, action) => {
      state.loading = action.payload.loading;
    },
    setReady: (state) => {
      state.loading = false;
    },
    setState: (state, action) => {
      state.isLoading = action.payload.isLoading;
    },
    clearState: (state) => {
      state.error = "";
      state.firebaseUser = null;
      state.isLoading = false;
      state.loading = false;
      state.token = "";
      state.user = null;
    },
  },
});

export const {
  setError,
  setFirebaseUser,
  setLoading,
  setReady,
  refreshToken,
  setState,
  setUser,
  clearState,
} = authSlice.actions;
