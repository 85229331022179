import React from "react";
import encryptText from "utils/encryptText";
import { Backdrop } from "@mui/material";

import { closeModal } from "app/slices/modalSlice";

import Modal from "../modal";
import { usePostConnectToExchangeMutation } from "api/institutional/exchange";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { Button, CircularProgress, Input, errorSnack } from "components/Shared";
import { IRidianExchange } from "interfaces";
import { enqueueSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import { FlagEnum } from "interfaces/enums";
import { useGetUserQuery } from "api/institutional/user";

interface FormData {
  apiKey: string;
  apiSecret: string;
}

export const ConnectExchangeModal = ({ data }: { data: IRidianExchange }) => {
  const { t } = useTranslation();

  const [connectToExchangeFn, { isLoading, error }] =
    usePostConnectToExchangeMutation();

  const { data: user } = useGetUserQuery({});

  const isCryptoEnabled = user?.flags.includes(
    FlagEnum.ccy_conversion_crypto_enabled,
  );

  const handleClose = () => {
    dispatch(closeModal());
  };

  const {
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm<FormData>();

  const dispatch = useDispatch();

  const doSubmit = async ({ apiKey, apiSecret }: FormData) => {
    try {
      // Encrypt Api Keys
      const encryptedKey = encryptText(apiKey);
      const encryptedSecret = encryptText(apiSecret);

      const parameters = {
        brokerId: data.id,
        encryptedKey: encryptedKey,
        encryptedSecret: encryptedSecret,
        subAccount: null,
      };

      // Edit exchanges slice with connected echanges
      const resp = await connectToExchangeFn(parameters).unwrap();

      if (!resp) {
        throw new Error("apiError");
      }

      handleClose();
    } catch (error) {
      enqueueSnackbar(t("There's an error in the API key"), errorSnack);
    }
  };

  const errorSwitch = (
    <div className="text-red text-3">
      {(() => {
        switch (error) {
          case "apiError":
            return (
              <p>
                {t(
                  "There was a network error. Please try again later or contact Ridian for support",
                )}
                .
              </p>
            );
          default:
            return (
              <p>
                {t(
                  "An error occured, please try again later or contact Ridian for support",
                )}
                .
              </p>
            );
        }
      })()}
    </div>
  );

  if (isLoading) {
    return (
      <Backdrop open={isLoading}>
        <CircularProgress style={{ color: "black" }} />
      </Backdrop>
    );
  }

  return (
    <Modal>
      <form onSubmit={handleSubmit(doSubmit)}>
        <Modal.Header handleClose={handleClose}>
          <span className="md:text-7 text-6 font-interSemiBold text-black">
            {t("Connect to")} {data.name}
          </span>
        </Modal.Header>
        <Modal.Body>
          <div>
            <p className="mb-3 text-4 font-unica77LL">{t("API Key")}:</p>
            <Input
              type="password"
              placeholder={t("Enter your API key")}
              bgColor="gray-light102"
              className="shadow-sm border"
              {...register("apiKey", { required: true })}
            />
          </div>
          <div>
            <p className="mb-3 text-4 font-unica77LL">{t("API Secret")}:</p>
            <Input
              type="password"
              placeholder={t("Enter your API secret")}
              bgColor="gray-light102"
              className="shadow-sm border"
              {...register("apiSecret", { required: true })}
            />
          </div>
          {isCryptoEnabled && (
            <div>
              <span>
                {t("Note: Please make sure to add make withdrawal permission.")}
              </span>
            </div>
          )}
          {error && errorSwitch}
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="bg-blue hover:bg-blue-dark100 rounded-full text-white py-2 px-4"
            type="submit"
            disabled={isLoading || !isValid}
          >
            {t("Connect")}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
