import React from "react";
import { Outlet } from "react-router-dom";

import SideBar from "../components/SideBar";
import ModalsPage from "../components/Modals";
import TopBar from "../components/TopBar";
import BottomBar from "../components/BottomBar";

const App = () => {
  return (
    <>
      <TopBar />

      <SideBar />

      <div className="w-[100%] bg-gray-light100 pb-[70px] sm:pb-0 md:w-[calc(100%-212px)] md:ml-[212px] h-full">
        <Outlet />
      </div>

      <BottomBar />

      <ModalsPage />
    </>
  );
};

export default App;
