import { institutionalApi } from "./index";
import {
  IDestinationBankAccountMexicanRio,
  IGetCcyConversionOrdersRequest,
  IRequestRioCreateBankAccNonUs,
  IDestinationBankAccountUSRio,
  IRequestRioGetBankAccUs,
} from "interfaces";
import {
  IRioQuoteRequest,
  IRioQuoteResponse,
  IRioOrder,
  IRioCreateOrderRequest,
  IRioDepositDetailsRequest,
  IRioCreatePayoutRequest,
  IRioCreateBankAccNonUs,
  IRioOrderRequest,
  IRioSubAccount,
  IRioCreateBankAccUs,
} from "interfaces/rio";
import { removeUndefinedValues, transformErrorResponse } from "utils";

export const ccyConversionOrdersRio = institutionalApi.injectEndpoints({
  endpoints: (builder) => ({
    getRioQuoute: builder.mutation<IRioQuoteResponse, IRioQuoteRequest>({
      query: (params) => ({
        url: `/rio/quote?${new URLSearchParams(params as any).toString()}`,
        method: "GET",
      }),
      transformErrorResponse,
    }),
    getRioOrders: builder.query<
      Array<IRioOrder>,
      IGetCcyConversionOrdersRequest
    >({
      query: (params) => ({
        url: `/rio?${new URLSearchParams(params as any)}`,
        method: "GET",
      }),
      providesTags: ["RioOrders"],
      transformErrorResponse,
    }),
    getRioOrder: builder.query<IRioOrder, IRioOrderRequest>({
      query: (params) => ({
        url: `/rio/order/${params.order_id}`,
        method: "GET",
      }),
      providesTags: (result, error, arg) => [
        { type: "RioOrder", id: arg.order_id },
      ],
      transformErrorResponse,
    }),
    getOrderDepositDetails: builder.query<string, IRioDepositDetailsRequest>({
      query: (params) => ({
        url: `/rio/order/deposit/bank_details?order_id=${params.order_id}`,
        method: "GET",
      }),
      providesTags: (result, error, arg) => [
        { type: "RioDepositDetail", id: arg.order_id },
      ],
      transformErrorResponse,
    }),
    createRioPayout: builder.mutation<string, IRioCreatePayoutRequest>({
      query: (body) => ({
        url: `/rio/order/payout`,
        method: "POST",
        body,
      }),
      transformErrorResponse,
    }),
    createRioOrder: builder.mutation<IRioOrder, IRioCreateOrderRequest>({
      query: (body) => ({
        url: `/rio/order`,
        method: "POST",
        body,
      }),
      transformErrorResponse,
    }),
    // Get Non US Destination Bank Accounts
    getBankAccountsNonUS: builder.query<
      Array<IDestinationBankAccountMexicanRio>,
      IRequestRioCreateBankAccNonUs
    >({
      query: (params) => ({
        url: `/rio/bank_account/non_us?${new URLSearchParams(
          removeUndefinedValues(params) as any,
        )}`,
        method: "GET",
      }),
      providesTags: [{ type: "RioNonUsAccounts" }],
      transformErrorResponse,
    }),
    // Get US Destination Bank Accounts
    getBankAccountsUS: builder.query<
      Array<IDestinationBankAccountUSRio>,
      IRequestRioGetBankAccUs
    >({
      query: (params) => ({
        url: `/rio/bank_account/us?${new URLSearchParams(
          removeUndefinedValues(params) as any,
        )}`,
        method: "GET",
      }),
      providesTags: [{ type: "RioUsAccounts" }],
      transformErrorResponse,
    }),
    // Sub Accounts
    getRioSubAccounts: builder.query<Array<IRioSubAccount>, void>({
      query: (params) => ({
        url: `/rio/broker/sub_accounts`,
        method: "GET",
      }),
      providesTags: [{ type: "RioSubAccounts" }],
      transformErrorResponse,
    }),
    // Post Non US Destination Bank Account
    createBankAccountNonUS: builder.mutation<string, IRioCreateBankAccNonUs>({
      query: (body) => ({
        url: "/rio/bank_account/non_us",
        method: "POST",
        body,
      }),
      transformErrorResponse,
      invalidatesTags: [{ type: "RioNonUsAccounts" }],
    }),
    // Post US Destination Bank Account
    createBankAccountUS: builder.mutation<string, IRioCreateBankAccUs>({
      query: (body) => ({
        url: "/rio/bank_account/us",
        method: "POST",
        body,
      }),
      transformErrorResponse,
      invalidatesTags: [{ type: "RioUsAccounts" }],
    }),
    simulateDeposit: builder.mutation<string, { order_id: string }>({
      query: (body) => ({
        url: "/rio/order/deposit/confirm_deposit",
        method: "POST",
        body,
      }),
      transformErrorResponse,
    }),
  }),
});

export const {
  useGetBankAccountsUSQuery,
  useGetRioQuouteMutation,
  useGetRioOrdersQuery,
  useGetOrderDepositDetailsQuery,
  useCreateRioOrderMutation,
  useCreateRioPayoutMutation,
  useLazyGetOrderDepositDetailsQuery,
  useCreateBankAccountNonUSMutation,
  useCreateBankAccountUSMutation,
  useLazyGetRioOrderQuery,
  useGetRioOrderQuery,
  useGetBankAccountsNonUSQuery,
  usePrefetch,
  useSimulateDepositMutation,
  useGetRioSubAccountsQuery,
} = ccyConversionOrdersRio;
