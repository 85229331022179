import DropZoneComponent from "components/Shared/Dropzone";
import { FileItem } from "./FileItem";
import { FileStructure } from "interfaces";
import { useSelector } from "react-redux";
import { GlobalState } from "app/slices/globalSlice";

export const UploadOrShow = ({
  multiUpload,
  files,
  category,
  doDrop,
  handleDelete,
  label,
}: {
  category: string;
  multiUpload: boolean;
  files?: Array<FileStructure>;
  handleDelete?: any;
  doDrop: any;
  label: string;
  isUploading: boolean;
}) => {
  const { uploading } = useSelector<any, GlobalState>((state) => state.global);
  const uploadComponent = () => {
    if (!multiUpload && files && files.length > 0) return null;
    return (
      <DropZoneComponent
        disable={!multiUpload && uploading.includes(category)}
        doDrop={doDrop}
        category={category}
        label={label}
      />
    );
  };

  const itemComponent = () => {
    return files?.map(({ category: cat, filename }) => (
      <FileItem
        category={cat}
        filename={filename}
        handleDelete={handleDelete}
      />
    ));
  };
  return (
    <>
      {itemComponent()}
      {uploadComponent()}
    </>
  );
};
