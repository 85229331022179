import { IOnBoarding } from "interfaces";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { addDocument, GlobalState, setIdType } from "app/slices/globalSlice";

import { IDTypes } from "interfaces/enums";
import { useForm } from "react-hook-form";

import { UploadByType } from "./UploadByType";
import { useUploader } from "hooks/useUpload";

export const ParticipantId = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { watch, setValue } = useForm<IOnBoarding>();

  const { onboarding } = useSelector<any, GlobalState>((state) => state.global);

  const id_type = watch("id_type") || onboarding.id_type;

  const { upload, isLoading: isUploading } = useUploader();

  const doDrop = async (files: [File], category: string) => {
    const filename = await upload(
      `/kyb/document/${onboarding.id}`,
      files,
      category,
    );

    dispatch(
      addDocument({
        filename,
        category,
      }),
    );
  };

  const handleSetType = (_id_type: IDTypes) => {
    setValue("id_type", _id_type);

    dispatch(setIdType({ id_type: _id_type }));
  };

  return (
    <>
      <span className="font-semibold text-3">
        {t("Identificación del Representante Legal")}
      </span>

      <span className="text-3 text-[#737373]">
        {t("Agrega una copia simple como imagen o PDF")}
      </span>

      <UploadByType
        isUploading={isUploading}
        doDrop={doDrop}
        idType={id_type}
        passport={onboarding.document_id}
        document_ine_anverso={onboarding.document_ine_anverso}
        document_ine_reverso={onboarding.document_ine_reverso}
        handleSendType={handleSetType}
      />
    </>
  );
};
