export const triggerSound = () => {
  const media = new Audio("/sounds/bell.mp3");

  var promise = media.play();

  if (promise !== undefined) {
    promise
      .then((_) => {})
      .catch((error) => {
        console.error("error", error);
      });
  }
};
