import React from "react";
import { useNavigate } from "react-router-dom";
import SolidBackground from "../SolidBackground";
import { Locations, Titles } from "interfaces/enums";
import { useTranslation } from "react-i18next";

export const NavSidebarButton = ({
  icon,
  selected,
  to,
  isBottomBar,
}: {
  isBottomBar?: boolean;
  icon: any;
  selected: boolean;
  to: Locations;
  handleClose: any;
}) => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const handleRedirect = () => {
    navigate(to);
  };

  const element = () => (
    <div className="flex items-center gap-4 px-2">
      {icon({
        color: selected ? "white" : "#505050",
        style: { fill: selected ? "white" : "#505050" },
      })}
      <span
        className={`text-4 ${selected ? "text-white" : "text-gray-dark500"}`}
        data-testid={`main-title-span-${isBottomBar ? "bottom" : "header"}`}
      >
        {t(Titles[to])}
      </span>
    </div>
  );

  if (selected) {
    return (
      <SolidBackground
        className={
          isBottomBar
            ? "flex content-between text-black bg-black p-1 rounded-md"
            : "rounded-md justify-start overflow-hidden md:py-3 text-white px-2 bg-black"
        }
        element={element()}
      />
    );
  }
  return (
    <div
      onClick={handleRedirect}
      className={
        isBottomBar
          ? "flex content-between cursor-pointer"
          : "rounded-md md:py-3 px-2 cursor-pointer hover:bg-gray-light100"
      }
    >
      {element()}
    </div>
  );
};

export default NavSidebarButton;
