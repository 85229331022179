import { combineReducers, configureStore } from "@reduxjs/toolkit";

import { persistReducer, persistStore } from "redux-persist";

import { institutionalApi } from "../api/institutional";
import { bitsoApi } from "../api/bitso";

import { userApi } from "../api/institutional/user";
import { exchangeApi } from "../api/institutional/exchange";
import { ordersApi } from "../api/institutional/orders";
import { adminApi } from "../api/institutional/admin";
import { dashboardApi } from "../api/institutional/dashboard";
import { assetsApi } from "../api/institutional/assets";
import { verifyUserApi } from "../api/institutional/verify";

import { authSlice } from "./slices/authSlice";
import { webSocketSlice } from "./slices/websocketSlice";
import { modalSlice } from "./slices/modalSlice";
import { globalSlice } from "./slices/globalSlice";
import { presetsSlice } from "./slices/presetsSlice";
import storage from "redux-persist/lib/storage";
import { ccyConversionOrders } from "../api/institutional/bitso_odl";
import { destinationAccountsApi } from "../api/institutional/bitso_destination_accounts";
import { ccyConversionOrdersRio } from "../api/institutional/rio";
import { ccyConversionOrdersCaliza } from "../api/institutional/caliza";
import { onBoardingApi } from "api/institutional/onboarding";

const persistConfig = {
  key: "presets",
  storage,
};

const rootReducer = combineReducers({
  [institutionalApi.reducerPath]: institutionalApi.reducer,
  [bitsoApi.reducerPath]: bitsoApi.reducer,
  auth: authSlice.reducer,
  modal: modalSlice.reducer,
  webSocket: webSocketSlice.reducer,
  global: globalSlice.reducer,
  presets: persistReducer(persistConfig, presetsSlice.reducer),
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      thunk: {
        extraArgument: {},
      },
    }).concat([
      institutionalApi.middleware,
      userApi.middleware,
      exchangeApi.middleware,
      assetsApi.middleware,
      ordersApi.middleware,
      dashboardApi.middleware,
      adminApi.middleware,
      bitsoApi.middleware,
      verifyUserApi.middleware,
      ccyConversionOrders.middleware,
      destinationAccountsApi.middleware,
      ccyConversionOrdersRio.middleware,
      ccyConversionOrdersRio.middleware,
      ccyConversionOrdersCaliza.middleware,
      onBoardingApi.middleware,
    ]),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof rootReducer>;
