import { initializeApp } from "firebase/app";
import { getPerformance } from "firebase/performance";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfigs = {
  production: {
    apiKey: "AIzaSyC6ivZnO1rOBgDlKZgRdB4FWfzJzncOg7c",
    authDomain: "ridian-97524.firebaseapp.com",
    projectId: "ridian-97524",
    storageBucket: "ridian-97524.appspot.com",
    messagingSenderId: "193751933898",
    appId: "1:193751933898:web:15eccb6ac25a9ead3d2533",
    measurementId: "G-9XVJXST89M",
  },
  test: {
    apiKey: "AIzaSyA1wOCHEliVF03s82GH_kgSmCRxX9uSWkc",
    authDomain: "ridian-staging.firebaseapp.com",
    projectId: "ridian-staging",
    storageBucket: "ridian-staging.appspot.com",
    messagingSenderId: "368537018408",
    appId: "1:368537018408:web:ee1ad2ac01c3efc5fa551d",
  },
  development: {
    apiKey: "AIzaSyA1wOCHEliVF03s82GH_kgSmCRxX9uSWkc",
    authDomain: "ridian-staging.firebaseapp.com",
    projectId: "ridian-staging",
    storageBucket: "ridian-staging.appspot.com",
    messagingSenderId: "368537018408",
    appId: "1:368537018408:web:ee1ad2ac01c3efc5fa551d",
  },
};

export const firebaseConfig = firebaseConfigs[process.env.REACT_APP_ENV];
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export const db = getFirestore(app);
export const perf = getPerformance(app);

export default auth;
