import { useFormContext } from "react-hook-form";
import { Input, Select } from "components/Shared";

import { useTranslation } from "react-i18next";
import { valueLabelMapper } from "utils";
import { IDestinationAccountWallet } from "interfaces";

export const WalletAccountForm = () => {
  const {
    formState: { errors },
    register,
    setValue,
    getValues,
  } = useFormContext<IDestinationAccountWallet>();

  const { t } = useTranslation();

  const networkOptions = ["Tron"];

  const networkOptionConstraints = {
    Ethereum: {
      pattern: {
        message: t(
          "Wallet address must start with 0x, have no special characters, and be 42 characters long.",
        ),
        value: /^0x[a-fA-F0-9]{40}$/,
      },
    },
    Tron: {
      pattern: {
        message: t(
          "Wallet address must start with T, have no special characters, and be 32 characters long.",
        ),
        value: /^T[a-zA-Z0-9]{33}$/,
      },
    },
  };

  return (
    <>
      {/* Recipient Name */}
      <div>
        <p className="mb-3 text-4 font-interSemiBold">{t("Alias")}:</p>
        <Input
          type="text"
          placeholder={t("Enter the alias of the recipient")}
          className="shadow-sm "
          bgColor="gray-light102"
          errors={errors}
          {...register("alias", {
            required: {
              message: t("Alias must not be empty"),
              value: true,
            },
            onBlur: () => setValue("alias", getValues("alias")?.trim()),
          })}
        />
      </div>
      {/* Bank Name */}
      <div>
        <p className="mb-3 text-4 font-interSemiBold">{t("Network")}:</p>
        <Select
          options={valueLabelMapper(networkOptions)} //[{label: , value: }]
          onChange={(e: any) => {
            setValue("network", e.value);
          }}
          placeholder={t("Select an option")}
        />
      </div>
      <div>
        {/* Number */}
        <div>
          <p className="mb-3 text-4 font-interSemiBold">Wallet Address</p>
          <Input
            type="text"
            placeholder={t("Enter the recipient's wallet address")}
            bgColor="gray-light102"
            className="shadow-sm "
            errors={errors}
            {...register("wallet_address", {
              required: {
                message: t("Wallet address must not be empty"),
                value: true,
              },
              pattern: networkOptionConstraints["Tron"].pattern,
              onBlur: () =>
                setValue("wallet_address", getValues("wallet_address")?.trim()),
            })}
          />
        </div>
      </div>
    </>
  );
};
