import BigNumber from "bignumber.js";

export const nanToZero = (value) =>
  Number.isNaN(value) ||
  BigNumber(value).isNaN() ||
  !BigNumber(value).isFinite() ||
  value === "NaN"
    ? 0
    : value;

export const isNaN = (value) => Number.isNaN(Number(value));

export const formatNumber = (n) => Intl.NumberFormat("en-US").format(n);

export const isNumber = (int) => typeof int === "number";

export const formatCash = (n) => {
  if (n < 1e3) return n;
  if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
  if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
  if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
  if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
};

export const symbolPrecisions = (currency) => {
  switch (currency) {
    case "USDT":
    case "USDC":
    case "ARS":
    case "DAI":
    case "TUSD":
    case "BRL":
      return 3;
    case "ETH":
    case "CHF":
    case "JPY":
    case "USD":
    case "MXN":
    case "GBP":
    case "HKD":
    case "SGD":
    case "EUR":
      return 2;
    case "KRW":
      return 0;
    default:
      return 8;
  }
};

export const exchangeRateSymbolPrecisions = (currency) => {
  switch (currency) {
    case "USD":
    case "MXN":
    case "GBP":
    case "HKD":
    case "SGD":
    case "EUR":
      return 5;
    case "JPY":
      return 2;
    case "COP":
    case "KRW":
      return 1;
    default:
      return 5;
  }
};
