import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { IOnBoarding, IOnboardingDraft } from "interfaces";
import { useTranslation } from "react-i18next";
import { Completed } from "./Completed";
import { DynamicForm } from "./DynamicForm";
import { useDispatch } from "react-redux";
import { addDocument } from "app/slices/globalSlice";

import { FormProvider, useForm } from "react-hook-form";

import { useUpdateOnboardingRegulatedMutation } from "api/institutional/onboarding";
import { useUploader } from "hooks/useUpload";
import { useEffect, useRef } from "react";
import { enqueueSnackbar } from "notistack";
import { errorSnack } from "components/Shared";
import { ParticipantId } from "./ParticipantId";
import { IDTypes } from "interfaces/enums";
import { useMergeFiles } from "./useMergeFiles";
import { UploadOrShow } from "./UploadOrShow";

export const Regulated = ({ onboarding }: { onboarding: IOnBoarding }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const methods = useForm<IOnboardingDraft>({
    defaultValues: {
      corporate_shareholders: [{ name: "", email: "", phone: "" }],
      id_type: IDTypes.INE,
    },
    mode: "all",
  });

  const doDrop = async (files: [File], category: string) => {
    const filename = await upload(`/kyb/document/${onboarding.id}`, files);
    dispatch(addDocument({ filename, category }));
  };

  const { upload, error: uploadError, isLoading } = useUploader();

  const [mutate] = useUpdateOnboardingRegulatedMutation();

  const { getFiles } = useMergeFiles();

  const handleSend = async () => {
    const corporate_shareholders = methods.getValues("corporate_shareholders");

    mutate({
      id: onboarding.id,
      company_name: onboarding.company_name.trim(),
      email: onboarding.email.trim(),
      company_category: onboarding.company_category.trim(),
      corporate_shareholders,
      files: getFiles(corporate_shareholders),
    });
  };

  useEffect(() => {
    if (uploadError) {
      enqueueSnackbar(t("Error uploading KYB"), errorSnack);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadError]);

  const idcompleted = () => {
    if (
      onboarding.id_type === IDTypes.passport &&
      onboarding?.document_id?.length
    ) {
      return true;
    }
    if (
      onboarding.id_type === IDTypes.INE &&
      onboarding?.document_ine_anverso?.length &&
      onboarding?.document_ine_reverso?.length
    ) {
      return true;
    }
    return false;
  };

  const accordionRef = useRef<HTMLDivElement>(null);

  return (
    <FormProvider {...methods}>
      <div>
        <span className="text-3 pb-6 text-[#767373]">
          {t(
            "Completa los campos presentados a continuación. La revisión de la información puede tomar hasta 48 horas",
          )}
          .
        </span>
      </div>

      <div>
        <Accordion defaultExpanded>
          <AccordionSummary id="panel1-header" expandIcon={<ExpandMoreIcon />}>
            <div className="flex gap-2 items-center">
              <Completed
                completed={!!onboarding?.poder_representante_legal?.length}
              />
              <span className="font-semibold text-3 self-center">
                {t("Poder del Representante Legal")}
              </span>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="px-8 py-0 flex flex-col gap-4">
              <span className="text-3 text-[#737373]">
                {t("Agrega una copia simple como imagen o PDF")}
              </span>
              <UploadOrShow
                multiUpload={false}
                category={"poder_representante_legal"}
                doDrop={doDrop}
                files={onboarding?.poder_representante_legal}
                label={
                  onboarding?.poder_representante_legal?.length
                    ? t("Otros documentos")
                    : t("Poder Representante Legal")
                }
              />
            </div>
          </AccordionDetails>
        </Accordion>

        <ParticipantId
          onboarding={onboarding}
          handleIsComplete={() => {
            if (accordionRef.current?.ariaExpanded === "false")
              accordionRef.current?.click();
          }}
        />

        <Accordion>
          <AccordionSummary
            id="panel1-header"
            expandIcon={<ExpandMoreIcon />}
            ref={accordionRef}
          >
            <div className="flex gap-2 items-center">
              <Completed completed={methods.formState.isValid} />
              <span className="font-semibold text-3 self-center">
                {t("Datos de contacto")}
              </span>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="px-8 py-0 flex flex-col gap-4">
              <DynamicForm />
            </div>
          </AccordionDetails>
        </Accordion>
      </div>

      <Button
        variant="contained"
        disabled={
          !methods.formState.isValid ||
          isLoading ||
          !onboarding?.poder_representante_legal?.length ||
          !idcompleted()
        }
        onClick={handleSend}
      >
        {t("Enviar")}
      </Button>
    </FormProvider>
  );
};
