import * as React from "react";

const SvgChevronLeft = ({ color, className }) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0499 12L16.2812 5.76861L15.2206 4.70795L7.92856 12L15.2206 19.292L16.2812 18.2313L10.0499 12Z"
      fill={color}
    />
  </svg>
);

export default SvgChevronLeft;
