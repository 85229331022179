/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { Modals } from "interfaces/enums";

export const modalSlice = createSlice({
  name: "modal",
  initialState: {
    preventBackdrop: false,
    modalName: "" as Modals | "",
    data: {},
  },
  reducers: {
    openModal: (state, action) => {
      state.modalName = action.payload.modalName;
      state.data = action.payload.data;
      state.preventBackdrop = !!action.payload.preventBackdrop;
    },
    closeModal: (state) => {
      state.modalName = "";
      state.data = {};
    },
  },
});

export const { openModal, closeModal } = modalSlice.actions;

export type ModalState = ReturnType<typeof modalSlice.reducer>;
