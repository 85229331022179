import { institutionalApi } from "./index";
import {
  IDestAccountsRequest,
  IDestinationAccountWallet,
  IDestinationBankAccountAll,
  IDestinationBankAccountUS,
  IDestinationBankAccountMexican,
} from "interfaces";

import { transformErrorResponse } from "utils";

export const destinationAccountsApi = institutionalApi.injectEndpoints({
  endpoints: (builder) => ({
    getDestinationAccounts: builder.query<
      Array<IDestinationBankAccountAll>,
      IDestAccountsRequest
    >({
      query: (params) => ({
        url: `/bitso_odl/destination_accounts?${new URLSearchParams(
          params as any,
        )}`,
        method: "GET",
      }),
      providesTags: ["DestinationAccounts"],
    }),
    getDestinationAccount: builder.query<
      IDestinationBankAccountAll,
      { accountId: string }
    >({
      query: (params) => ({
        url: `/bitso_odl/destination_accounts/account/${params.accountId}`,
        method: "GET",
      }),
      providesTags: (result, error, arg) => [
        { type: "DestinationAccount", id: arg.accountId },
      ],
    }),
    postDestinationAccount: builder.mutation<
      string,
      IDestinationBankAccountMexican
    >({
      query: (body) => ({
        url: `/bitso_odl/destination_accounts/SPID`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["DestinationAccounts"],
      transformErrorResponse,
    }),
    postDestinationAccountUsBankAccount: builder.mutation<
      string,
      IDestinationBankAccountUS
    >({
      query: (body) => ({
        url: `/bitso_odl/destination_accounts/us_domestic_wire`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["DestinationAccounts"],
      transformErrorResponse,
    }),
    postDestinationAccountWallet: builder.mutation<
      string,
      IDestinationAccountWallet
    >({
      query: (body) => ({
        url: `/bitso_odl/destination_accounts/crypto_wallet`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["DestinationAccounts"],
      transformErrorResponse,
    }),
  }),
});

export const {
  useGetDestinationAccountQuery,
  useGetDestinationAccountsQuery,
  usePostDestinationAccountMutation,
  usePostDestinationAccountUsBankAccountMutation,
  usePostDestinationAccountWalletMutation,
} = destinationAccountsApi;
