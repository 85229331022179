import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { closeModal } from "app/slices/modalSlice";
import Modal from "../modal";
import { useTranslation } from "react-i18next";
import { Button, CurrencyLabel, errorSnack } from "components/Shared";
import CurrencyInput from "react-currency-input-field";
import { useForm } from "react-hook-form";
import { useEditOrderMutation } from "api/institutional/orders";
import BigNumber from "bignumber.js";
import { IOrder } from "interfaces";
import { useSnackbar } from "notistack";
import { EditedOrderSnack } from "components/Shared/Snacks";
import { Sides } from "interfaces/enums";
import { useGetPortfolioPositionsQuery } from "api/institutional/dashboard";
import { useGetTickQuery } from "api/institutional/exchange";
import { Tooltip } from "react-tooltip";
import { useGetUserQuery } from "api/institutional/user";

export const EditOrderModal = ({ data: order }: { data: IOrder }) => {
  const { t } = useTranslation();

  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();

  // APIs
  const { data: user } = useGetUserQuery({});

  const { data: portfolioPositions } = useGetPortfolioPositionsQuery({
    portfolioId: user?.portfolio.id!,
  });

  const { data: exchangeTicks } = useGetTickQuery({
    base_ccy: order?.base_ccy,
    quote_ccy: order?.quote_ccy,
  });

  const [mutate, { isLoading }] = useEditOrderMutation();

  const orderBook = useSelector(
    (state: any) => state.webSocket.connections["/exchange_data"].message,
  );

  const handleClose = () => {
    dispatch(closeModal());
  };

  const getDefaultValue = () => {
    if (
      orderBook?.base_ccy !== order.base_ccy ||
      orderBook?.quote_ccy !== order.quote_ccy
    ) {
      //We are not connected to the pair book, so we need to return the original price
      return order.price;
    }
    if (order.side === Sides.SELL) {
      return orderBook?.bestBid;
    }
    if (order.side === Sides.BUY) {
      return orderBook?.bestAsk;
    }
  };

  // Form
  const {
    handleSubmit,
    setValue,
    watch,
    formState: { isValid },
  } = useForm<{ price: BigNumber }>({
    mode: "all",
    defaultValues: { price: getDefaultValue() },
  });

  const price = watch("price");

  const [isValidQty, setIsValidQty] = useState(order.side === Sides.BUY);

  useEffect(() => {
    if (order.side === Sides.BUY) {
      const missingQty = BigNumber(order.qty).minus(
        BigNumber(order.qty_filled || 0),
      );

      const realBalance = missingQty
        .multipliedBy(order.price)
        .plus(portfolioPositions?.available[orderBook?.quote_ccy] || 0);

      const requiredBalance = BigNumber(price).multipliedBy(missingQty);

      setIsValidQty(requiredBalance.isLessThanOrEqualTo(realBalance));
    } else {
      setIsValidQty(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portfolioPositions, price]);

  const doSubmit = async (values: { price: BigNumber }) => {
    try {
      const result = await mutate({ price: values.price, order_id: order.id });
      if ("error" in result) {
        throw result.error;
      }
      enqueueSnackbar(
        <EditedOrderSnack order={order} newPrice={values.price} />,
      );
      handleClose();
    } catch (e: any) {
      enqueueSnackbar(
        t(e?.data?.detail, "An error occurred. Please notify Ridian."),
        errorSnack,
      );
    }
  };

  return (
    <Modal>
      <form onSubmit={handleSubmit(doSubmit)} noValidate>
        <Modal.Header handleClose={handleClose}>
          <span className="md:text-7 text-6 text-black font-interSemiBold">
            {t("Modify Order")}
          </span>
        </Modal.Header>
        <Modal.Body>
          <div>
            <p className="mb-3 text-4">
              <span className="font-interSemiBold">{t("Original Price")}:</span>{" "}
              <CurrencyLabel symbol={order.quote_ccy} showSymbol>
                {order.price}
              </CurrencyLabel>
            </p>
            <p className="mb-3 text-4 font-interSemiBold">{t("New Price")}</p>
            <CurrencyInput
              disabled={isLoading}
              className="bg-white shadow-sm border p-2 text-4 font-bold w-full disabled:text-gray-dark100"
              placeholder={exchangeTicks?.price_precision_ph ?? "0.000"}
              decimalsLimit={exchangeTicks?.price_precision_dp}
              allowDecimals={!!exchangeTicks?.price_precision_dp}
              defaultValue={getDefaultValue()}
              onValueChange={(value: any) => {
                setValue("price", value, { shouldValidate: true });
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            data-tooltip-id="validation-message"
            data-tooltip-place="top"
            data-tooltip-content={isValidQty ? "" : t("Insufficient funds")}
            className="bg-blue -mt-3 rounded-full text-white hover:bg-blue-dark100 py-2 px-6 text-4"
            type="submit"
            disabled={!isValid || isLoading || !isValidQty}
          >
            {t("Submit")}
          </Button>
        </Modal.Footer>
        <Tooltip style={{ maxWidth: "20rem" }} id="validation-message" />
      </form>
    </Modal>
  );
};

export default EditOrderModal;
