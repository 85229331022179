/* eslint-disable no-param-reassign */
import React, { useEffect, createContext } from "react";
import {
  GoogleAuthProvider,
  signInWithPopup,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
  sendEmailVerification,
  setPersistence,
  onIdTokenChanged,
  browserLocalPersistence,
  sendPasswordResetEmail,
} from "firebase/auth";

import { getPath } from "../api/customFetchBase";
import { useCreateUserMutation } from "../api/institutional/user";
import { useResendEmailMutation } from "../api/institutional/verify"; // TODO: Add when BE endpoint is available

import { useDispatch, useSelector } from "react-redux";
import {
  clearState,
  refreshToken,
  setError,
  setFirebaseUser,
  setState,
  setUser,
} from "../app/slices/authSlice";
import { clearWebsocketState } from "../app/slices/websocketSlice";

import { institutionalApi } from "../api/institutional";
import auth from "../firebase/firebase";
import { reset } from "../app/slices/globalSlice";
import { useTranslation } from "react-i18next";
import encryptText from "../utils/encryptText";

// AuthContext allows us to access auth data in all parts of our application without needed to pass this information as props from parent to children components.
export const AuthContext = createContext();

const signin = async (email, password) => {
  try {
    await setPersistence(auth, browserLocalPersistence);
  } catch (e) {
    console.error("error setting auth persistance: ", e);
  }
  return signInWithEmailAndPassword(auth, email, password);
};

const verifyEmail = sendEmailVerification;
const resetPassword = (email) => sendPasswordResetEmail(auth, email);

const signInWithGoogle = async () => {
  const googleProvider = new GoogleAuthProvider();
  googleProvider.setCustomParameters({
    prompt: "select_account",
  });
  return signInWithPopup(auth, googleProvider);
};

// Provider that provides a value prop that holds the data we want to share across components
export const AuthProvider = ({ children }) => {
  const [createUser] = useCreateUserMutation();
  const [resend] = useResendEmailMutation();
  const state = useSelector((s) => s.auth);
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const logout = () => {
    dispatch(institutionalApi.util.resetApiState());
    dispatch(clearState());
    dispatch(clearWebsocketState());
    dispatch(reset());
    signOut(auth);
  };

  const newSignInGoogle = async () => {
    try {
      dispatch(setState({ isLoading: true }));
      await signInWithGoogle();
    } catch (e) {
      dispatch(setError({ error: "Internal Server Error" }));
    } finally {
      dispatch(setState({ isLoading: false }));
    }
  };

  const newSignInPassword = async (email, password) => {
    try {
      dispatch(setState({ isLoading: true }));
      await signin(email, password);
    } catch (e) {
      dispatch(setError({ error: t("signin_error_default") }));
    } finally {
      dispatch(setState({ isLoading: false }));
    }
  };

  const newSignUpPassword = async ({
    email,
    password,
    phone,
    firstName,
    lastName,
    recaptcha,
    flags,
  }) => {
    try {
      dispatch(setState({ isLoading: true }));
      const url = new URL(window.location.href);
      const params = url.searchParams;
      const referralCode = params.get("ref");
      const newUser = await createUser({
        email,
        firstName,
        lastName,
        phone,
        recaptcha,
        password: encryptText(password),
        flags,
        referralCode,
      });
      await signin(email, password);
      if (newUser.error) throw newUser.error;
    } catch (e) {
      if (e?.message === "Firebase: Error (auth/invalid-login-credentials).") {
        throw e;
      }
      try {
        const { user } = await signin(email, password);
        await resend({ token: await user.getIdToken() });
      } catch {
        dispatch(setError({ error: t("sign_up_error") }));
      }
    } finally {
      dispatch(setState({ isLoading: false }));
    }
  };

  useEffect(() => {
    if (state.user) {
      const timeout = setInterval(() => {
        const isExpired = state.user?.stsTokenManager?.isExpired;
        const expiredTime = state.user?.stsTokenManager?.expirationTime;
        const currentTime = Date.now();
        const minutesUntilExpired = Math.floor(
          (expiredTime - currentTime) / 1000 / 60,
        );
        if (isExpired) {
          window.location.replace("/login?tokenExpired=true");
        }
        if (minutesUntilExpired < 5) {
          state.user?.getIdToken(true);
        }
      }, 60000);

      return () => {
        clearInterval(timeout);
      };
    }
  }, [state.user]);

  // Function that allows a user to sign in with google without having an account
  useEffect(() => {
    onIdTokenChanged(auth, async (authenticatedUser) => {
      const auth = await authenticatedUser?.getIdTokenResult();
      if (auth?.token) {
        dispatch(refreshToken({ token: auth.token }));
      }
    });

    const unsubscribe = onAuthStateChanged(auth, async (authenticatedUser) => {
      if (!authenticatedUser) {
        dispatch(setUser({ user: null, token: null }));
      }

      if (authenticatedUser && authenticatedUser.emailVerified) {
        const { signInProvider, token } =
          await authenticatedUser.getIdTokenResult();

        try {
          if (
            (window.location.pathname === "/login" ||
              window.location.pathname === "/sign-up") &&
            signInProvider === "google.com"
          ) {
            const dbUserP = await fetch(`${getPath("institutional")}/user`, {
              headers: {
                authorization: token,
                "Content-Type": "application/json",
              },
            });

            const dbUser = await dbUserP.json();
            const url = new URL(window.location.href);
            const params = url.searchParams;
            const referralCode = params.get("ref");

            if (!dbUser?.email) {
              await createUser({
                token,
                email: authenticatedUser.email,
                firstName: authenticatedUser.displayName?.split(" ")[0],
                lastName: authenticatedUser.displayName?.split(" ")[1],
                flags: undefined,
                referralCode,
              });
            }
          }
        } catch (e) {
          console.error(e);
        }
        dispatch(setUser({ user: authenticatedUser, token }));
      } else {
        dispatch(setFirebaseUser({ firebaseUser: authenticatedUser }));
      }
    });
    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const reload = async () => {
    await state.firebaseUser.reload();
    if (state?.firebaseUser?.emailVerified) {
      dispatch(
        setUser({
          user: state.firebaseUser,
          token: state.firebaseUser.accessToken,
        }),
      );
    }
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        reload,
        signInWithGoogle,
        logout,
        verifyEmail,
        resetPassword,
        newSignInGoogle,
        newSignInPassword,
        newSignUpPassword,
      }}
    >
      {!state?.loading && children}
    </AuthContext.Provider>
  );
};
