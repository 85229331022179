import { IOnBoarding, IOnboardingDraft } from "interfaces";
import { institutionalApi } from "./index";
import { transformErrorResponse } from "../../utils";

export const onBoardingApi = institutionalApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllOnboardings: builder.query<Array<IOnBoarding>, {}>({
      query: () => ({
        url: `/kyb/incomplete`,
      }),
      providesTags: ["Onboardings"],
      transformErrorResponse,
    }),

    getOnboardingDocument: builder.query<
      string,
      { id: string; filename: string }
    >({
      query: (params) => ({
        url: `/kyb/document/${params.id}?filename=${params.filename}`,
      }),
      transformErrorResponse,
    }),
    getOnboarding: builder.query<IOnBoarding, { id: string }>({
      query: (params) => ({
        url: `/kyb?kyb_id=${params.id}`,
      }),
      providesTags: ["Onboarding"],
      transformErrorResponse,
    }),
    updateOnboardingUnRegulated: builder.mutation<
      IOnBoarding,
      IOnboardingDraft
    >({
      query: (params) => ({
        url: `/kyb/unregulated/${params.id}`,
        method: "POST",
        body: params,
      }),
      invalidatesTags: ["Onboarding"],
      transformErrorResponse,
    }),
    updateOnboardingRegulated: builder.mutation<IOnBoarding, IOnboardingDraft>({
      query: (params) => ({
        url: `/kyb/regulated/${params.id}`,
        method: "POST",
        body: params,
      }),
      invalidatesTags: ["Onboarding"],
      transformErrorResponse,
    }),
    createOnboarding: builder.mutation<IOnBoarding, IOnboardingDraft>({
      query: (params) => ({
        url: `/kyb/kyb_email?email=${params.email}`,
        method: "POST",
      }),
      invalidatesTags: ["Onboardings"],
      transformErrorResponse,
    }),
    changeOnboardingStatus: builder.mutation<IOnBoarding, IOnboardingDraft>({
      query: (body) => ({
        url: `/kyb/status/${body.id}?status=${body.status}`,
        method: "POST",
      }),
      invalidatesTags: ["Onboardings"],
      transformErrorResponse,
    }),
  }),
});

export const {
  useGetOnboardingQuery,
  useGetAllOnboardingsQuery,
  useChangeOnboardingStatusMutation,
  useCreateOnboardingMutation,
  useLazyGetOnboardingDocumentQuery,
  useUpdateOnboardingRegulatedMutation,
  useUpdateOnboardingUnRegulatedMutation,
} = onBoardingApi;
