import { Link } from "react-router-dom";

import { useAuth } from "hooks/useAuth";

import { NavSidebarIconMenu } from "../NavSidebarIconMenu";
import LangSelector from "../LangSelector";

import { ReactComponent as LogoutIcon } from "assets/svg/logout-icon.svg";
import { ReactComponent as RLogo } from "assets/svg/R.svg";
import { Locations } from "interfaces/enums";
import { useTranslation } from "react-i18next";

const SideBar = () => {
  const { logout } = useAuth();

  const { t } = useTranslation();

  return (
    <div className="hidden md:flex md:flex-col fixed align-top md:w-[212px] h-full bg-white gap-4 p-4 shadow-md">
      <div className="flex">
        <Link to={Locations["/"]} className="">
          <RLogo />
        </Link>
      </div>

      <div className="flex-1 items-center overflow-auto">
        {/* NavSideBarIconMenu */}
        <NavSidebarIconMenu />
      </div>

      <div className="flex items-center justify-between">
        <span className="text-4">{t("Language")}</span>
        <LangSelector />
      </div>

      <div className="border-b w-[100%] border-gray-light200"></div>

      <div className="flex w-full">
        <button
          onClick={() => logout()}
          className="flex justify-between items-center text-black cursor-pointer w-full hover:bg-gray-light100 py-2"
        >
          <span className="text-4">{t("Logout")}</span>
          <LogoutIcon />
        </button>
      </div>
    </div>
  );
};

export default SideBar;
