// Endpoints for getTradablePairs
import { institutionalApi } from "./index";

export const adminApi = institutionalApi.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: () => ({
        url: `/admin/users`,
        method: "GET",
      }),
      providesTags: ["UsersQuery"],
    }),
    getVolume: builder.query({
      query: (params) => ({
        url: `/admin/volume?user_id=${params.userId}`,
        method: "GET",
      }),
      providesTags: ["VolumeQuery"],
    }),
    getVolumeByCurrencyPair: builder.query({
      query: (params) => ({
        url: `/admin/volume/pairs?user_id=${params.userId}`,
        method: "GET",
      }),
      providesTags: ["VolumeByCurrencyPairQuery"],
    }),
    getTradeErrors: builder.query({
      query: (params) => ({
        url: `/admin/trade_errors?user_id=${params.userId}`,
        method: "GET",
      }),
      providesTags: ["TradeErrorsQuery"],
    }),
    getLiveOrders: builder.query({
      query: (params) => ({
        url: `/admin/live`,
        method: "GET",
      }),
      providesTags: ["LiveOrdersQuery"],
    }),
  }),
});

export const {
  useGetUsersQuery,
  useGetVolumeQuery,
  useGetVolumeByCurrencyPairQuery,
  useGetTradeErrorsQuery,
  useGetLiveOrdersQuery,
} = adminApi;
