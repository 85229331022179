import { React, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { recaptchaSitesKey } from "variables/global";

const Recaptcha = ({ onChange, onExpired }) => {
  const recaptchaRef = useRef();
  return (
    <ReCAPTCHA
      ref={recaptchaRef}
      sitekey={recaptchaSitesKey}
      onChange={onChange}
      onExpired={onExpired}
    />
  );
};

export default Recaptcha;
