import { JSEncrypt } from "jsencrypt";

const PUBLIC_KEY = `-----BEGIN PUBLIC KEY----- 
MIGeMA0GCSqGSIb3DQEBAQUAA4GMADCBiAKBgFp3FBrXg5lJxmJNe41xEvzD4o+m
hDbI4dWf7P6JejJQv3e8+7n/A+NgM53T1iNW2Nt92DQJypP89TIPTwesZK2DAeJD
7NHJUCnb23CdF1QtcMu3yQJl6eHiAbGplFrt95n2byOMLBQ4i2MU+1cmkVNBzZnV
uxJ2ktZkAquuB37FAgMBAAE=
-----END PUBLIC KEY-----`;

const encryptText = (text) => {
  const encrypt = new JSEncrypt();
  encrypt.setPublicKey(PUBLIC_KEY);

  return encrypt.encrypt(text) || "ENCRYPTION FAILED";
};

export default encryptText;
