import { institutionalApi } from "./index";
import {
  IChangeCcyConversionStateRequest,
  ICreateCcyConversionOrderRequest,
  IError,
  IGetCcyConversionOrderRequest,
  IGetCcyConversionOrdersRequest,
  ITransferCCYOrderRequest,
  IClaimTransferCCYOrderRequest,
  IBitsoOrder,
} from "interfaces";
import { useDispatch, useSelector } from "react-redux";
import {
  FlagEnum,
  Locations,
  CcyConversionOrderStatus,
} from "interfaces/enums";
import { enqueueSnackbar } from "notistack";
import { QuoteExpiredSnack } from "components/Shared/Snacks";
import { useTranslation } from "react-i18next";
import { useGetUserQuery } from "./user";
import { useNavigate } from "react-router-dom";
import { GlobalState } from "app/slices/globalSlice";
import { triggerSound } from "utils/triggerSound";

const transformErrorResponse = (error: any | IError) => {
  return error?.data?.detail! || "An error occurred. Please notifiy Ridian.";
};

export const useExpireQuote = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { data: user } = useGetUserQuery({});

  const navigate = useNavigate();

  const isClientUser = user?.flags.includes(FlagEnum.bitso_odl_client);

  const { ccyConversionOrdersQuery } = useSelector<any, GlobalState>(
    (state) => state.global,
  );

  function expireQuote(order_id: string) {
    try {
      dispatch<any>(
        ccyConversionOrders.util.updateQueryData(
          "getCcyConversionOrder",
          { order_id },
          (draft) => {
            if (draft.status === CcyConversionOrderStatus.QUOTE_GENERATED) {
              draft.status = CcyConversionOrderStatus.QUOTE_EXPIRED;
              dispatch<any>(
                ccyConversionOrders.util.updateQueryData(
                  "getCcyConversionOrders",
                  ccyConversionOrdersQuery,
                  (draft) => {
                    const index = draft.findIndex((o) => o.id === order_id);
                    if (index > -1) {
                      draft[index].status =
                        CcyConversionOrderStatus.QUOTE_EXPIRED;
                    }
                  },
                ),
              );
              if (isClientUser) {
                enqueueSnackbar(<QuoteExpiredSnack />, {
                  action: (
                    <div className="flex justify-end ml-2">
                      <button
                        className="bg-white text-black rounded-full py-2 px-4"
                        onClick={() => {
                          navigate(
                            Locations["/ccy-conversion/order/:id"].replace(
                              ":id",
                              order_id,
                            ),
                          );
                        }}
                      >
                        {t("View order")}
                      </button>
                    </div>
                  ),
                  autoHideDuration: 5000,
                });
                triggerSound();
              }
            }
          },
        ),
      );
    } catch (e) {
      console.error(e);
    }
  }

  return { expireQuote };
};

export const ccyConversionOrders = institutionalApi.injectEndpoints({
  endpoints: (builder) => ({
    updateCcyConversionOrder: builder.mutation<
      string,
      IChangeCcyConversionStateRequest
    >({
      query: (body) => ({
        url: "/bitso_odl/state_change",
        method: "POST",
        body,
      }),
      // Refetch after mutation to make sure everything is synced
      invalidatesTags: (result, error, arg) => [
        { type: "CcyConversionOrder", id: arg.order_id },
        { type: "CcyConversionOrders" },
      ],
      // Optimistic Update Logic
      // async onQueryStarted(
      //   { order_id, ...patch },
      //   { dispatch, queryFulfilled, getState },
      // ) {
      //   // Patch order in state of getCcyConversionOrder

      //   // Patch settlement date when it is returned in the the form of "yyyy-mm-dd" instead of a timestamp
      //   if (patch.settlement_date) {
      //     patch.settlement_date = (new Date(patch.settlement_date).getTime() /
      //       1000) as any;
      //   }

      //   const patchResult = dispatch(
      //     ccyConversionOrders.util.updateQueryData(
      //       "getCcyConversionOrder",
      //       { order_id },
      //       (draft) => {
      //         Object.assign(draft, patch);
      //       },
      //     ),
      //   );

      //   const { global } = getState() as RootState;

      //   // Patch list of orders in getCcyConversionOrders by updating corresponding order
      //   const ordersPatchResult = dispatch(
      //     ccyConversionOrders.util.updateQueryData(
      //       "getCcyConversionOrders",
      //       global.ccyConversionOrdersQuery,
      //       (draft) => {
      //         const index = draft.findIndex((o) => o.id === order_id);
      //         if (index > -1) {
      //           draft[index] = Object.assign(draft[index], patch);
      //         }
      //       },
      //     ),
      //   );

      //   // Waits for actual update
      //   try {
      //     await queryFulfilled;
      //   } catch {
      //     // If there is an error, undo change made by optimistic update
      //     patchResult.undo();
      //     ordersPatchResult.undo();

      //     dispatch(
      //       ccyConversionOrders.util.invalidateTags([
      //         { type: "CcyConversionOrder", id: order_id },
      //       ]),
      //     );

      //     dispatch(
      //       ccyConversionOrders.util.invalidateTags([
      //         { type: "CcyConversionOrders" },
      //       ]),
      //     );
      //   }
      // },
      transformErrorResponse,
    }),
    getCcyConversionOrders: builder.query<
      Array<IBitsoOrder>,
      IGetCcyConversionOrdersRequest
    >({
      query: (params) => ({
        url: `/bitso_odl?${new URLSearchParams(params as any)}`,
        method: "GET",
      }),

      // queryFn: async (params, { getState }, _extraOptions, fetchWithBQ) => {
      //   const state = getState() as RootState;
      //   const user = state.institutional.queries["getUser({})"]?.data as any;

      //   const response = { data: [] } as { data: Array<ICcyConversionOrder> };

      //   const isRioUser = user?.flags.includes(
      //     FlagEnum.ccy_conversion_client_rio,
      //   );
      //   const isClientUser = [
      //     FlagEnum.ccy_conversion_mm,
      //     FlagEnum.ccy_conversion_client,
      //     FlagEnum.ccy_conversion_mm,
      //   ].some((f) => user?.flags.includes(f));

      //   if (isRioUser) {
      //     const result = (await fetchWithBQ(
      //       `/rio?${new URLSearchParams(params as any)}`,
      //     )) as { data: Array<IRioOrder> };

      //     response.data = result.data;
      //   }
      //   if (isClientUser) {
      //     const result = (await fetchWithBQ(
      //       `/ccy_conversion?${new URLSearchParams(params as any)}`,
      //     )) as { data: Array<IBitsoOrder> };

      //     response.data = [...response.data, ...result.data];
      //   }

      //   return response;
      // },
      providesTags: ["CcyConversionOrders"],
      // transformErrorResponse,
    }),
    getCcyConversionOrder: builder.query<
      IBitsoOrder,
      IGetCcyConversionOrderRequest
    >({
      query: (params) => ({
        url: `/bitso_odl/order/${params.order_id}`,
        method: "GET",
      }),
      providesTags: (result, error, arg) => [
        { type: "CcyConversionOrder", id: arg.order_id },
      ],
      transformErrorResponse,
    }),

    createCcyConversionOrder: builder.mutation<
      string,
      ICreateCcyConversionOrderRequest
    >({
      query: (body) => ({ url: "/bitso_odl", method: "POST", body }),
      invalidatesTags: ["CcyConversionOrders"],
      transformErrorResponse,
    }),
    transferCcyConversionOrder: builder.mutation<
      string,
      ITransferCCYOrderRequest
    >({
      query: (body) => ({
        url: "/bitso_odl/transfer_sent",
        method: "POST",
        body,
      }),
      invalidatesTags: (result, error, arg) => [
        "CcyConversionOrders",
        { type: "CcyConversionOrder", id: arg.order_id },
      ],
      transformErrorResponse,
    }),
    claimTransferCcyConversionOrder: builder.mutation<
      string,
      IClaimTransferCCYOrderRequest
    >({
      query: (body) => ({
        url: "/bitso_odl/transfer_claim",
        method: "POST",
        body,
      }),
      invalidatesTags: (result, error, arg) => [
        "CcyConversionOrders",
        { type: "CcyConversionOrder", id: arg.order_id },
      ],
      transformErrorResponse,
    }),
  }),
});

export const {
  useTransferCcyConversionOrderMutation,
  useClaimTransferCcyConversionOrderMutation,
  useCreateCcyConversionOrderMutation,
  useGetCcyConversionOrdersQuery,
  useUpdateCcyConversionOrderMutation,
  useLazyGetCcyConversionOrdersQuery,
  useLazyGetCcyConversionOrderQuery,
  useGetCcyConversionOrderQuery,
  usePrefetch,
} = ccyConversionOrders;
