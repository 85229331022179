import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { IOnBoarding } from "interfaces";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { addDocument, setIdType } from "app/slices/globalSlice";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { IDTypes } from "interfaces/enums";
import { useForm } from "react-hook-form";
import { Completed } from "./Completed";
import { UploadByType } from "./UploadByType";
import { useEffect, useRef } from "react";
import { useUploader } from "hooks/useUpload";

export const ParticipantId = ({
  onboarding,
  handleIsComplete,
}: {
  onboarding: IOnBoarding;
  handleIsComplete: any;
}) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { watch, setValue } = useForm<IOnBoarding>();

  const id_type = watch("id_type") || IDTypes.INE;

  const { upload } = useUploader();

  const doDrop = async (files: [File], category: string) => {
    const filename = await upload(`/kyb/document/${onboarding.id}`, files);

    dispatch(
      addDocument({
        filename,
        category,
      }),
    );
  };

  const handleSetType = (_id_type: IDTypes) => {
    setValue("id_type", _id_type);

    dispatch(setIdType({ id_type: _id_type }));
  };

  const isComplete = () =>
    !!(id_type === IDTypes.passport && onboarding?.document_id?.length) ||
    !!(
      id_type === IDTypes.INE &&
      onboarding?.document_ine_anverso?.length &&
      onboarding?.document_ine_reverso?.length
    );

  useEffect(() => {
    if (isComplete()) {
      handleIsComplete();
      selfRef.current?.click();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    id_type,
    onboarding?.document_id,
    onboarding.document_ine_anverso,
    onboarding.document_ine_reverso,
  ]);

  const selfRef = useRef<HTMLDivElement>(null);

  return (
    <Accordion>
      <AccordionSummary
        id="panel1-header"
        expandIcon={<ExpandMoreIcon />}
        ref={selfRef}
      >
        <div className="flex gap-2 items-center">
          <Completed completed={isComplete()} />
          <span className="font-semibold text-3 self-center">
            {t("Identificación del Representante Legal")}
          </span>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className="px-8 py-0 flex flex-col gap-8">
          <span className="text-3 text-[#737373]">
            {t("Agrega una copia simple como imagen o PDF")}
          </span>

          <UploadByType
            doDrop={doDrop}
            idType={id_type}
            passport={onboarding.document_id}
            document_ine_anverso={onboarding.document_ine_anverso}
            document_ine_reverso={onboarding.document_ine_reverso}
            handleSendType={handleSetType}
          />
        </div>
      </AccordionDetails>
    </Accordion>
  );
};
