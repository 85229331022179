import React, { useState } from "react";
import { NavSidebarIconMenu } from "../NavSidebarIconMenu";

export const BottomBar = () => {
  const [open, setOpen] = useState(false);
  const handleToggle = () => setOpen(!open);

  return (
    <div className="bg-white h-[71px] w-full flex sm:hidden items-center fixed bottom-0 border-t border-gray-light100">
      <NavSidebarIconMenu isBottomBar handleClose={handleToggle} />
    </div>
  );
};
export default BottomBar;
