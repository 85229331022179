import { RioSupportedCountry } from "../interfaces/enums";

export const getBankOptions = (country: RioSupportedCountry) => {
  switch (country) {
    case "MX":
      return [
        "ABC Capital",
        "Actinver",
        "Afirme",
        "Banamex",
        "BanBajio",
        "Banco Azteca",
        "Banco Inbursa",
        "Banco Invex",
        "Banco Multiva",
        "Bancrea",
        "Banregio",
        "Banorte",
        "BASE",
        "BBVA Bancomer",
        "GBM",
        "HSBC",
        "Intercam Banco",
        "Masari",
        "Mifiel",
        "Nafin",
        "Nvio",
        "Santander Serfin",
        "Scotiabank Inverlat",
        "STP",
        "Value",
        "Ve por Más",
        "Vector",
      ];
    case "US":
      return [
        "JPMorgan Chase",
        "Bank of America",
        "Citigroup",
        "Wells Fargo",
        "IBC",
        "Goldman Sachs",
        "Morgan Stanley",
        "U.S. Bancorp",
        "PNC Financial Services",
        "Truist Financial",
        "TD Bank, N.A.",
        "Capital One",
        "Charles Schwab Corporation",
        "The Bank of New York Mellon",
        "State Street Corporation",
        "BMO USA",
        "American Express",
        "HSBC Bank USA",
        "Citizens Financial Group",
        "First Citizens BancShares",
        "USAA",
        "M&T Bank",
        "Fifth Third Bank",
        "Barclays",
        "UBS",
        "Huntington Bancshares",
        "Ally Financial",
        "KeyCorp",
        "Ameriprise",
        "RBC Bank",
        "Santander Bank",
        "Northern Trust",
        "Regions Financial Corporation",
        "Discover Financial",
        "Synchrony Financial",
        "Deutsche Bank",
        "New York Community Bank",
        "First Horizon National Corporation",
        "Raymond James Financial",
        "Comerica",
        "Western Alliance Bancorporation",
        "Webster Bank",
        "Mizuho Financial Group",
        "Popular, Inc.",
        "East West Bank",
        "CIBC Bank USA",
        "BNP Paribas",
        "John Deere",
        "Valley Bank",
        "Synovus",
        "Wintrust Financial",
        "Columbia Bank",
        "BOK Financial Corporation",
        "Cullen/Frost Bankers, Inc.",
        "Old National Bank",
        "Pinnacle Financial Partners",
        "FNB Corporation",
        "UMB Financial Corporation",
        "South State Bank",
        "Associated Banc-Corp",
        "Prosperity Bancshares",
        "Stifel",
        "EverBank",
        "Midland",
        "Banc of California",
        "Hancock Whitney",
        "BankUnited",
        "Sumitomo Mitsui Banking Corporation",
        "SoFi",
        "First National of Nebraska",
        "Commerce Bancshares",
        "First Interstate BancSystem",
        "WaFd Bank",
        "United Bank (West Virginia)",
        "Texas Capital Bank",
        "Glacier Bancorp",
        "FirstBank Holding Co",
        "Fulton Financial Corporation",
        "Simmons Bank",
        "United Community Bank",
        "Arvest Bank",
        "BCI Financial Group",
        "Ameris Bancorp",
        "First Hawaiian Bank",
        "Bank of Hawaii",
        "Cathay Bank",
        "Credit Suisse[a]",
        "Home BancShares",
        "Beal Bank",
        "Axos Financial",
        "Atlantic Union Bank",
        "Customers Bancorp",
        "Eastern Bank",
        "WSFS Bank",
        "Pinnacle Bank",
        "Independent Bank",
        "HTLF Bank / Heartland Financial",
        "Central Bancompany, Inc.",
        "First BanCorp",
        "Independent Bank Group, Inc.",
        "Pacific Premier Bancorp",
      ];
    default:
      return [];
  }
};
