import { enqueueSnackbar } from "notistack";
import { useEffect } from "react";
import { errorSnack } from "../components/Shared";
import { useTranslation } from "react-i18next";

export const useErrorListener = ({ error }: { error: any }) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (error) {
      enqueueSnackbar(t((error?.detail || error) as string), errorSnack);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);
};

export default useErrorListener;
