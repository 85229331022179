import { ReactComponent as GoogleLogo } from "assets/svg/google-logo.svg";

export const AuthenticateWithGoogleButton = ({
  onClick,
  disabled = false,
  buttonText,
}) => (
  <button
    className="flex justify-center items-center text-4 px-20 md:px-28 py-3 gap-2 font-interSemiBold transition duration-200 ease-in-out text-black bg-gray-light400 hover:text-white hover:bg-black rounded-md"
    onClick={onClick}
    disabled={disabled}
  >
    <GoogleLogo />
    {buttonText}
  </button>
);
