import React from "react";
import { NavSidebarButton } from "../NavSideBarButton";
import { useLocation } from "react-router-dom";
import { useGetUserQuery } from "api/institutional/user";
import { Locations, Roles } from "interfaces/enums";
import { MenuOptions } from "./MenuOptions";
import { ISidebarMenuOptions } from "interfaces";
import { ButtonLoader } from "./loaders/button.loader";

const MenuItem = ({
  option,
  isBottomBar,
  handleClose,
}: {
  isBottomBar?: boolean;
  handleClose: any;
  option: ISidebarMenuOptions;
}) => {
  const pathname = useLocation().pathname as Locations;

  const { data: user } = useGetUserQuery({});

  /** Hide admin menus */
  if (option.role && option.role === Roles.admin && !user?.is_admin) {
    return null;
  }

  /** Hide mobile menus */
  if (option.hideOnMobile && isBottomBar) {
    return null;
  }

  /** Hide based on flags menus */
  if (!user?.flags.some((flag) => option.showTo.includes(flag))) {
    return null;
  }

  /** Hide nav bar menus */
  if (option.hideOnNavBar) {
    return null;
  }

  return (
    <NavSidebarButton
      selected={pathname === option.route}
      isBottomBar={isBottomBar}
      icon={option.icon}
      to={option.route}
      handleClose={handleClose}
    />
  );
};

export const NavSidebarIconMenu = ({
  isBottomBar,
  handleClose,
}: {
  isBottomBar?: boolean;
  handleClose: any;
}) => {
  const { data: user } = useGetUserQuery({});

  if (!user && !isBottomBar) return <ButtonLoader />;

  return (
    <div
      className={
        isBottomBar
          ? "flex flex-row justify-around w-full"
          : "flex gap-6 flex-col pt-6"
      }
    >
      {MenuOptions.map((option, key: number) => (
        <MenuItem
          handleClose={handleClose}
          option={option}
          isBottomBar={isBottomBar}
          key={key}
        />
      ))}
    </div>
  );
};
