import * as React from "react";

const SvgGlobe = ({ color = "white" }) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.33325 8.00016C1.33325 4.31787 4.31763 1.3335 7.99992 1.3335C11.6822 1.3335 14.6666 4.31787 14.6666 8.00016C14.6666 11.6825 11.6822 14.6668 7.99992 14.6668C4.31763 14.6668 1.33325 11.6825 1.33325 8.00016ZM7.99992 13.4168C10.9921 13.4168 13.4166 10.9924 13.4166 8.00016C13.4166 5.00797 10.9921 2.5835 7.99992 2.5835C5.00773 2.5835 2.58325 5.00797 2.58325 8.00016C2.58325 10.9924 5.00773 13.4168 7.99992 13.4168ZM12.0208 7.98193L11.5807 6.66162C11.4426 6.24495 11.6536 5.78923 12.0624 5.62516L13.4166 5.0835L13.8333 8.8335L12.6093 8.52881C12.3333 8.4585 12.1119 8.25276 12.0208 7.98193ZM3.07986 6.78662L2.58325 5.91683L2.99992 4.66683L5.08325 2.5835L7.79159 1.75016L8.33325 2.6986C8.50773 3.00433 8.47127 3.38688 8.24211 3.65433L7.83325 4.13297C7.56763 4.44026 7.56502 4.896 7.82544 5.2085L8.17961 5.63037C8.32023 5.79964 8.33325 6.04183 8.21085 6.22673C8.08585 6.41162 7.85149 6.49235 7.64054 6.42204L7.01294 6.22152C6.85149 6.15902 6.65356 6.17464 6.48169 6.26057L6.32544 6.3361C6.09106 6.4559 5.97909 6.72673 6.06242 6.97673C6.10149 7.09912 6.18742 7.20329 6.2994 7.26579L7.19783 7.78142C7.45044 7.92464 7.7369 7.97673 8.00252 7.97673H9.14835C9.47909 7.97673 9.7994 8.13297 10.0338 8.36735L10.1327 8.46631C10.3671 8.70069 10.4999 9.021 10.4999 9.35173V9.56526C10.4999 10.0158 10.2603 10.4299 9.86971 10.6512L9.63013 10.7892C9.25252 11.0054 8.97387 11.3361 8.85409 11.7788L8.81502 11.9194C8.69783 12.3309 8.37232 12.6564 7.96085 12.7736C7.56242 12.8882 7.14315 12.5887 7.14315 12.1746V11.383C7.14315 11.0965 7.00513 10.8361 6.72649 10.7085C6.49471 10.5809 6.30982 10.3205 6.30982 10.034V8.99495C6.30982 8.52881 6.02596 8.11995 5.55461 7.99235L4.07023 7.56266C3.6507 7.44287 3.29575 7.16423 3.07986 6.78662Z"
      fill={color}
    />
  </svg>
);

export default SvgGlobe;
