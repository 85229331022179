import React from "react";
import { Navigate } from "react-router-dom";

import { Backdrop } from "@mui/material";
import { CircularProgress } from "components/Shared";
import { useAuth } from "hooks/useAuth";
import { Locations } from "interfaces/enums";

const RequireAuth = ({
  children,
  redirectTo,
}: {
  children: any;
  redirectTo: Locations;
}) => {
  const { user, loading } = useAuth();

  if (loading) {
    return (
      <Backdrop
        open={true}
        style={{ backgroundColor: "black" }}
        data-testid="loading"
      >
        <CircularProgress style={{ color: "black" }} />
      </Backdrop>
    );
  }

  return user ? children : <Navigate to={redirectTo} />;
};

export default RequireAuth;
