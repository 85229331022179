const DEBUG = false;

export const hostsInstitutional = {
  development: "http://localhost:8000",
  test: "https://green---algo-trading-api-rttuh5f3wq-rj.a.run.app",
  production: "https://green---algo-trading-api-vgpf7zp3da-rj.a.run.app",
};

export const hostsWebSocket = {
  development: "wss://localhost:8000",
  test: "wss://green---algo-trading-api-rttuh5f3wq-rj.a.run.app",
  production: "wss://green---algo-trading-api-vgpf7zp3da-rj.a.run.app",
};

const loginContent = [
  {
    name: "sign-in",
    msg: ["signin_title", "signin_use_credentials"],
  },
  {
    name: "sign-up",
    msg: ["sign_up_create_account", "sign_up_today"],
  },
  {
    name: "recover",
    msg: ["forgot_password_label", "forgot_password_use_credentials"],
  },
];

const timeFrames = [
  { label: "dashboard_period_1_day", value: "1_day", default: true },
  { label: "dashboard_period_1_week", value: "1_week" },
  { label: "dashboard_period_1_month", value: "1_month" },
  { label: "dashboard_period_3_months", value: "3_months" },
  { label: "dashboard_period_1_year", value: "1_year" },
  { label: "dashboard_period_all", value: "all" },
];

const defaultDate = new Date(Date.now());
export const defaultDataPoints = [
  {
    key: new Date(new Date().setDate(defaultDate.getDate() - 1)),
    value: 0,
  },
  {
    key: defaultDate,
    value: 0,
  },
];

const recaptchaSitesKey = "6Lca3K8iAAAAAHuDv8TJoCBCR4MJs8CZVBt7hIYm";

export { DEBUG, loginContent, timeFrames, recaptchaSitesKey };
