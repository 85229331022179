import { closeModal } from "app/slices/modalSlice";

import Modal from "../modal";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { Input } from "components/Shared";
import { useDispatch } from "react-redux";
import { useGetRioSubAccountsQuery } from "api/institutional/rio";
import { RioSupportedDOCountry } from "interfaces/enums";
import { Select } from "components/Shared";
import { useGetUserQuery } from "api/institutional/user";
import { ChangeEvent, useState } from "react";
import { USAccount } from "./UsAccountForm";
import { MexicanAccount } from "./MexicanAccountForm";

export const AddBankAccountModalRio = ({ data }: { data: {} }) => {
  const { t } = useTranslation();

  // APIs

  const handleClose = () => {
    dispatch(closeModal());
  };

  const dispatch = useDispatch();

  const { data: user } = useGetUserQuery({});

  const { data: subAccounts = [] } = useGetRioSubAccountsQuery(undefined, {
    skip: !user?.isRioBroker,
  });

  const {
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<{ account_name: string }>({
    defaultValues: {
      account_name: "",
    },
  });

  const accountName = watch("account_name");

  const [subAccountId, setSubAccountId] = useState("");
  const [country, setCountry] = useState(RioSupportedDOCountry.MX);

  return (
    <Modal>
      <Modal.Header handleClose={handleClose}>
        <span className="md:text-7 text-6 font-interSemiBold text-black">
          {t("Add New Destination Bank Account")}
        </span>
      </Modal.Header>
      <Modal.Body>
        {user?.isRioBroker && (
          <div>
            <p className=" text-4 font-interSemiBold">{t("Client")}:</p>
            <Select
              isClearable
              options={subAccounts.map((s) => ({
                label: s.client_name,
                value: s.sub_account_id,
              }))}
              onChange={(e: any) => {
                setSubAccountId(e?.value);
              }}
              placeholder={t("Select an option")}
            />
          </div>
        )}

        {/* Recipient Name */}
        <div>
          <p className=" text-4 font-interSemiBold">{t("Recipient's Name")}:</p>
          <Input
            type="text"
            placeholder={t("Enter the full name of the recipient")}
            className="shadow-sm"
            bgColor="gray-light102"
            errors={errors}
            value={getValues("account_name")}
            onBlur={() =>
              setValue("account_name", getValues("account_name").trim())
            }
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setValue("account_name", e.target.value)
            }
          />
        </div>

        {/* Transfer Type */}
        <div>
          <p className=" text-4 font-interSemiBold">{t("Country")}:</p>
          <Select
            defaultValue={[
              { label: "Mexico", value: RioSupportedDOCountry.MX },
            ]}
            options={[
              { label: "Mexico", value: RioSupportedDOCountry.MX },
              { label: "US", value: RioSupportedDOCountry.US },
            ]}
            onChange={(e: any) => {
              setCountry(e.value);
            }}
            placeholder={t("Select an option")}
          />
        </div>

        {country === RioSupportedDOCountry.US ? (
          <USAccount
            handleClose={handleClose}
            subAccountId={subAccountId}
            country={country}
            accountName={accountName}
          />
        ) : (
          <MexicanAccount
            handleClose={handleClose}
            subAccountId={subAccountId}
            country={country}
            accountName={accountName}
          />
        )}
      </Modal.Body>
    </Modal>
  );
};

export default AddBankAccountModalRio;
