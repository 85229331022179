import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "@emotion/styled";
import { Dialog } from "@mui/material";
import { closeModal } from "app/slices/modalSlice";
import { noop } from "lodash";
import { ConnectExchangeModal } from "./ConnectExchangeModal";
import { DisconnectExchangeModal } from "./DisconnectExchangeModal";
import { EditOrderModal } from "./EditOrderModal";
import { Modals } from "interfaces/enums";
import AddBankAccountModal from "./AddBankAccountModal";
import ViewBankAccountModal from "./ViewBankAccountModal";
import ConfirmQuoteBrokerRioModal from "./ConfirmQuoteBrokerRioModal";
import RequestQuoteModal from "./RequestQuoteModal";
import AddBankAccountModalRio from "./AddBankAccountModalRio";
import SendKyb from "./SendKyb";

const StyledDialog = styled(Dialog)`
  & .MuiDialog-paper {
    background-color: white !important;
    color: black !important;
    border-radius: 8px;
    border: none;
    @media (max-width: 640px) { 
      bottom: 0;
      position: absolute;
      width: 100%;
      margin: 0;
      border-radius: 8px 8px 0 0;
    }
  }
}`;

export const ModalsPage = () => {
  const { modalName, preventBackdrop, data } = useSelector(
    (state) => state.modal,
  );
  const dispatch = useDispatch();
  const handleClose = () => dispatch(closeModal());

  return (
    <StyledDialog
      onClose={preventBackdrop ? noop : handleClose}
      open={!!modalName}
    >
      {
        {
          [Modals.ConnectExchangeModal]: <ConnectExchangeModal data={data} />,
          [Modals.DisconnectExchangeModal]: (
            <DisconnectExchangeModal data={data} />
          ),
          [Modals.EditOrderModal]: <EditOrderModal data={data} />,
          [Modals.AddBankAccountModal]: <AddBankAccountModal data={data} />,
          [Modals.AddBankAccountModalRio]: (
            <AddBankAccountModalRio data={data} />
          ),
          [Modals.ViewBankAccountModal]: <ViewBankAccountModal data={data} />,
          [Modals.ConfirmQuoteBrokerRioModal]: (
            <ConfirmQuoteBrokerRioModal data={data} />
          ),
          [Modals.RequestQuoteModal]: <RequestQuoteModal data={data} />,
          [Modals.SendKyb]: <SendKyb data={data} />,
        }[modalName]
      }
    </StyledDialog>
  );
};

export default ModalsPage;
