import { useTranslation } from "react-i18next";
import { Add } from "@mui/icons-material";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { IOnboardingDraft } from "interfaces";
import classNames from "classnames";
import { Button, Divider, FormControl, TextField } from "@mui/material";
import PhoneInput from "react-phone-number-input";
import flags from "react-phone-number-input/flags";
import { IDTypes } from "interfaces/enums";
import { useTrimmer } from "utils";

export function DynamicForm() {
  const { t } = useTranslation();

  const {
    register,
    control,
    formState: { errors },
  } = useFormContext<IOnboardingDraft>();

  const { fields, append, remove } = useFieldArray({
    name: "corporate_shareholders",
    control,
  });

  const { onBlur } = useTrimmer();
  return (
    <div className="flex flex-col gap-4">
      {fields.map((field, index) => {
        return (
          <div
            key={field.id}
            className="flex flex-col gap-4 text-3 w-full pt-2"
          >
            <div className="flex justify-between items-center">
              <span className="font-semibold">
                {t("Contacto")} {index + 1}
              </span>
              <Button
                onClick={() => remove(index)}
                disabled={index === 0}
                size="small"
              >
                {t("Eliminar")}
              </Button>
            </div>
            <FormControl fullWidth>
              <TextField
                label={t("Nombre completo")}
                {...register(`corporate_shareholders.${index}.name` as const, {
                  required: true,
                  onBlur,
                })}
                className={classNames(
                  // "bg-[#F9FAFB] w-full p-2 rounded-md",
                  errors?.corporate_shareholders?.[index]?.name && "error",
                )}
              />
            </FormControl>
            <FormControl fullWidth>
              <TextField
                type="email"
                label={t("Email")}
                {...register(`corporate_shareholders.${index}.email` as const, {
                  required: true,
                  onBlur,
                })}
                className={classNames(
                  // "bg-[#F9FAFB] w-full p-2 rounded-md",
                  errors?.corporate_shareholders?.[index]?.email && "error",
                )}
              />
            </FormControl>

            <Controller
              name={`corporate_shareholders.${index}.phone`}
              control={control}
              rules={{ required: true }}
              render={({ field }) => {
                return (
                  <FormControl fullWidth>
                    <PhoneInput
                      international
                      flags={flags}
                      defaultCountry={"MX"}
                      limitMaxLength={true}
                      placeholder="Enter phone number"
                      {...field}
                      className={classNames(
                        // "bg-[#F9FAFB] w-full p-2 rounded-md",
                        "h-[56px] border rounded-md px-4 text-4 border-[#ccc]",
                        errors?.corporate_shareholders?.[index]?.phone &&
                          "error",
                      )}
                    />
                  </FormControl>
                );
              }}
            />
            <Divider />
          </div>
        );
      })}

      <Button
        type="button"
        onClick={() =>
          append({
            name: "",
            email: "",
            phone: "",
            cif: [],
            document_id: [],
            id_type: IDTypes.INE,
          })
        }
        size="small"
        variant="outlined"
        startIcon={<Add></Add>}
      >
        {t("Agregar contacto")}
      </Button>
    </div>
  );
}
