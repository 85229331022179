import { Link } from "react-router-dom";

import { ReactComponent as RLogo } from "assets/svg/R.svg";
import { Menu } from "@mui/icons-material";

import { useState } from "react";

import { GetTitle } from "components/Shared";
import { MobileMenuPage } from "../MobileMenuPage";
import { Locations } from "interfaces/enums";

export const TopBar = () => {
  const [open, setOpen] = useState(false);
  const handleToggle = () => setOpen(!open);

  return (
    <div className="bg-white h-14 w-full absolute flex sm:hidden items-center py-4 shadow-sm z-10">
      <div className="flex justify-between px-5 items-center w-full">
        <div className="flex items-center gap-2">
          <Link
            to={Locations["/"]}
            className="cursor-pointer bg-white py-2 px-2 rounded-md"
          >
            <RLogo />
          </Link>
          <span className="text-6 font-interSemiBold text-black">
            {<GetTitle />}
          </span>
        </div>
        <div>
          <div onClick={handleToggle} className="cursor-pointer">
            <Menu style={{ color: "black" }} />
          </div>
        </div>
        {open && <MobileMenuPage handleClose={handleToggle} />}
      </div>
    </div>
  );
};
export default TopBar;
