import { institutionalApi } from "./index";

export const assetsApi = institutionalApi.injectEndpoints({
  endpoints: (builder) => ({
    getAssets: builder.query({
      query: (params) => ({
        url: `/assets?include_navs=false${
          params?.symbols?.length ? `&symbols=${params.symbols}` : ""
        }`,
      }),
      providesTags: ["AssetsQuery"],
    }),
  }),
});

export const { useGetAssetsQuery } = assetsApi;
